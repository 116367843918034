import React, { ReactElement } from 'react';
import cn from 'classnames';
import { createPopper } from '@popperjs/core';
import classNames from 'classnames';
export interface PopOverProps {
  children: JSX.Element;
  className?: string;
  content: ReactElement;
  position?: 'bottom' | 'top';
}

/**
 * Buttons are used to initialize an action. Button labels express what action will occur when the user interacts with it.
 */
export const PopOver: React.FC<PopOverProps> = ({
  children,
  className,
  content,
  position = 'bottom',
}) => {
  const [popoverShow, setPopoverShow] = React.useState(false);
  const btnRef: any = React.createRef();
  const popoverRef: any = React.createRef();
  const openPopover = () => {
    createPopper(btnRef.current, popoverRef.current, {
      placement: position,
    });
    setPopoverShow(true);
  };
  const closePopover = () => {
    setPopoverShow(false);
  };

  const popOverClass = cn('m-auto', {
    'focus:outline-none ': true,
  });

  return (
    <div
      className={className}
      onMouseEnter={() => openPopover()}
      onMouseLeave={() => closePopover()}>
      <div className="flex">
        <button
          className={popOverClass}
          type={'button'}
          onClick={() => {
            popoverShow ? closePopover() : openPopover();
          }}
          ref={btnRef}>
          {children}
        </button>
      </div>

      <div
        className={classNames(
          (popoverShow ? '' : 'hidden ') +
            ' font-normal  top-4 z-50 mr-3 mt-2 block max-w-xs break-words rounded-lg text-left text-sm leading-normal no-underline',
        )}
        ref={popoverRef}
        style={{ marginTop: 5 }}>
        <div>
          <div
            className={classNames(
              'mt-4 rounded-md border-0 bg-gray-100 p-3 text-paragraph',
              position === 'top' && 'relative mt-0 bottom-4',
            )}>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};
