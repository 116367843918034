import React, { useState, useEffect } from 'react';
import localMeta from './meta.json';

const publishedDiffThanCurrent = (published: string, current: string) =>
  published !== current;

const withClearCache = (Component) => {
  const ClearCacheComponent = (props) => {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const publishedVersion = meta.raw;
          const currentVersion = localMeta.raw;

          const shouldForceRefresh = publishedDiffThanCurrent(
            publishedVersion,
            currentVersion,
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  };

  return ClearCacheComponent;
};

export default withClearCache;
