import React from "react";
import cn from 'classnames';
import { SpinnerSize } from "./helpers";
import { Base } from "./components/Base";

export interface ISpinnerProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Element role.
   */
  role?: string;
  /**
   * Spinner size, must be one of the following keys:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  size?: keyof typeof SpinnerSize;
}

export const Spinner: React.FC<ISpinnerProps> = (props): JSX.Element => {
  const { className, role = "status", size = "md" } = props;

  return (
    <Base className={cn(SpinnerSize[size], className)} role={role} />
  );
}
