import React, { HTMLProps } from 'react';
import {
  Button,
  GoogleIcon,
  IconButton,
  LinkedinIcon,
  MicrosoftIcon,
  Stack,
} from '@design-system';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface IAuthButtonGroupProps
  extends Omit<HTMLProps<HTMLDivElement>, 'onClick'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * If `true`, the buttons will have a disable state.
   */
  isDisabled?: boolean;
  /**
   * On click handler that will trigger the auth0 authentication.
   * @param {string} conn - authentication connection method.
   */
  onClick: (conn: 'google-oauth2' | 'linkedin' | 'windowslive') => void;
  /**
   * Element role.
   */
  role?: string;
}

export const AuthButtonGroup: React.FC<IAuthButtonGroupProps> = (
  props,
): JSX.Element => {
  const { t } = useTranslation();
  const {
    className,
    isDisabled = false,
    onClick,
    role = 'group',
    ...rest
  } = props;

  return (
    <Stack className={cn('items-center', className)} role={role} {...rest}>
      <Button
        className="mb-4 text-black"
        color="#E9E9E9"
        isDisabled={isDisabled}
        leftIcon={<GoogleIcon height={22} width={21} />}
        onClick={() => onClick('google-oauth2')}
        size="sm"
        variant="solid">
        {t(translations.COMMON.GOOGLE_LOGIN)}
      </Button>

      <div>
        <IconButton
          color="#E9E9E9"
          icon={<MicrosoftIcon />}
          isDisabled={isDisabled}
          onClick={() => onClick('windowslive')}
          variant="solid"
        />

        <IconButton
          className="ml-5"
          color="#E9E9E9"
          icon={<LinkedinIcon />}
          isDisabled={isDisabled}
          onClick={() => onClick('linkedin')}
          variant="solid"
        />
      </div>
    </Stack>
  );
};
