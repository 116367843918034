import { WLContext } from '@contexts';
import React, { useEffect, useMemo } from 'react';
import { useSession } from 'store/session';
import { useFetchSettings, useFetchWL, useUpdateWL } from './hooks';
import { useWLReducer } from './reducers';
import fp from 'lodash/fp';
import { isInvitationURL } from './helpers';

export const WLProvider: React.FC = ({ children }): JSX.Element => {
  const { user } = useSession();

  const [state, dispatch] = useWLReducer();

  const [fetchWL, softFetchWL] = useFetchWL(dispatch);

  const [fetchSettings, fetchUserSettings] = useFetchSettings(dispatch);

  const [updateWL] = useUpdateWL(dispatch);

  const context = useMemo(
    () => ({ fetchWL, fetchSettings, fetchUserSettings, softFetchWL, updateWL, ...state }),
    [state],
  );

  useEffect(() => {
    if (!isInvitationURL(window)) fp.compose(fetchWL, fp.get('id'))(user);
    fetchSettings(user?.id);
  }, [user?.id]);

  return <WLContext.Provider value={context}>{children}</WLContext.Provider>;
};
