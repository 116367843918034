import { clomp } from 'clomp';
import React from 'react';

import { IPropsOf } from 'types.d';

const Base = clomp.tbody`
  bg-white
  border-none
  divide-gray-200
  divide-y
  text-sm
`;

export type ITbodyProps = IPropsOf<'tbody'>;

export const Tbody: React.FC<ITbodyProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return <Base {...rest}>{children}</Base>;
};
