import { translations } from 'translations';
import * as Yup from 'yup';

export interface IConfirmAccountFormFields {
  /**
   * Six digit validation code.
   */
  code: string;
}

export const ConfirmAccountFormValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required(translations.ERRORS.REQUIRED_VERIFICATION_CODE)
    .matches(/^[0-9]{6}$/, translations.ERRORS.VERIFICATION_CODE_MAX_DIGITS_ERROR),
});

export const DEFAULT_VALUES = {
  code: '',
};
