// TODO: remove unused.
import create from 'zustand';
import { tokenName, tokenV3Name, userName } from 'config/constants';
import { configurePersist } from 'zustand-persist';
import { translations } from 'translations';
import { IUserTeam } from '@cincel/client';

const { persist, purge } = configurePersist({
  storage: localStorage, // use `AsyncStorage` in react native
  rootKey: 'root', // optional, default value is `root`
});

interface SessionStore {
  user: any;
  isLoggedIn: any;
  token: any;
  tokenV3: any;
  defaultOrganization: any;
  invitations: any;
  currentOrganization: any;
  organizations: any;
  organizationImages: any;
  currentPlan: any;
  accountLimits: any;
  statsOpened: any;
  language: any;
  teams: any;
  team: any;
  orgsToTeamsMap: any;
  setUser: any;
  setIsLoggedIn: any;
  setToken: any;
  setTokenV3: any;
  setCurrentOrganization: any;
  setOrganizations: any;
  setOrganizationImages: any;
  setCurrentPlan: any;
  setAccountLimits: any;
  setInvitations: any;
  setStatsOpened: any;
  getOrganization: any;
  updateOrganization: any;
  setLanguage: any;
  setTeams: any;
  clean: any;
  showBasicCertificates: any;
  credits: any;
  setShowBasicCertificates: (value: boolean) => void;
  setTeam: (team: IUserTeam | null) => void;
  setOrgsToTeamsMap: (v: any) => void;
  setCredits: (v: any) => void;
}

export const useSession = create<SessionStore>(
  persist(
    {
      key: 'cincelSession-data', // unique name
      denylist: ['organizationImages', 'organizations'],
    },
    (set, get) => ({
      user: JSON.parse(localStorage.getItem(userName) as any),
      isLoggedIn: Boolean(localStorage.getItem(tokenName)),
      token: localStorage.getItem(tokenName),
      tokenV3: localStorage.getItem(tokenV3Name),
      defaultOrganization: {
        name: translations.ORGANIZATIONS.MY_ORGANIZATION,
        imagotype: '/img/defaultOrg.png',
        owner: false,
        active: true,
        id: 0,
        translation: true,
      },
      invitations: [],
      currentOrganization: {},
      organizations: [],
      currentPlan: {},
      accountLimits: {},
      statsOpened: true,
      organizationImages: [],
      teams: [],
      team: null,
      orgsToTeamsMap: {},
      language:
        ((window.navigator as any).userLanguage ||
          window.navigator.language) === 'en-US'
          ? 'en'
          : (window.navigator as any).userLanguage || window.navigator.language,
      showBasicCertificates: false,
      credits: null,
      setShowBasicCertificates: (showBasicCertificates) => {
        return set(() => ({
          showBasicCertificates,
        }));
      },
      setUser: (user) => {
        return set(() => ({
          user: {
            ...user,
            organizations: undefined,
          },
        }));
      },
      setIsLoggedIn: (isLoggedIn) => {
        return set(() => ({
          isLoggedIn,
        }));
      },
      setToken: (token) => {
        return set(() => ({
          token,
        }));
      },
      setTokenV3: (tokenV3) => {
        return set(() => ({ tokenV3 }));
      },
      setCurrentOrganization: (currentOrganization) => {
        return set(() => ({
          currentOrganization,
        }));
      },
      setOrganizations: (organizations) => {
        const newOraganizations = organizations.map((org) => ({
          ...org,
          imagotype: null,
        }));
        return set(() => ({
          organizations: newOraganizations,
        }));
      },
      setOrganizationImages: (organizationImages) => {
        return set(() => ({
          organizationImages,
        }));
      },
      setCurrentPlan: (currentPlan) => {
        const newPlan = { ...currentPlan };

        return set(() => ({
          currentPlan: {
            ...newPlan,
            name: 'Start',
          },
        }));
      },
      setAccountLimits: (accountLimits) => {
        return set(() => ({
          accountLimits,
        }));
      },
      setInvitations: (invitations) => {
        return set(() => ({
          invitations,
        }));
      },
      setStatsOpened: () => {
        const stat = (get() as any).statsOpened;
        return set(() => ({
          statsOpened: !stat,
        }));
      },
      getOrganization: (id) => {
        const organizations = (get() as any).organizations;
        const organization = organizations.find((org) => org.id === id);

        return organization;
      },
      updateOrganization: (id, data) => {
        const organizations = (get() as any).organizations;
        const organizationIndex = organizations.findIndex(
          (org) => org.id === parseInt(id, 10),
        );
        organizations[organizationIndex] = { ...data };
        return set(() => ({
          organizations,
        }));
      },
      setLanguage: (language) => {
        return set(() => ({
          language,
        }));
      },
      setTeams: (teams) => {
        return set(() => ({
          teams,
        }));
      },
      setTeam: (team) => {
        return set(() => ({ team }));
      },
      setCredits: (credits) => {
        return set(() => ({ credits }));
      },
      setOrgsToTeamsMap: (orgsToTeamsMap) => {
        return set(() => ({ orgsToTeamsMap }));
      },
      clean: () =>
        set({
          user: null,
          isLoggedIn: false,
          token: null,
          tokenV3: null,
          defaultOrganization: {
            name: translations.ORGANIZATIONS.MY_ORGANIZATION,
            imagotype: '/img/defaultOrg.png',
            owner: false,
            active: true,
            id: 0,
            translation: true,
          },
          invitations: [],
          currentOrganization: {},
          organizations: [],
          currentPlan: {},
          accountLimits: {},
          statsOpened: true,
          language: 'es',
          team: null,
          orgsToTeamsMap: {},
          credits: null,
        }),
    }),
  ),
);
