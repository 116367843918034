import fp from 'lodash/fp';

/**
 * Default supported formats for file handling.
 * @type {string[]}
 */
export const DEFAULT_SUPPORTED_FORMATS = ['image/png'];

/**
 * The default size of a file is 512KB represented in bytes.
 * @type {number}
 */
const DEFAULT_SIZE = 512000;

/**
 * Validates if the format of the selected file matches one of the supported
 * formats.
 * @param {string} value - selected file format.
 * @param {string[]} f - application supported formats.
 * @returns {boolean}
 */
export const isValidFileFormat = (v: string, f = DEFAULT_SUPPORTED_FORMATS) =>
  fp.includes(v)(f);

/**
 * Validates if the selected file size doesn't exceeds the allowed size.
 * @param {number} size - selected file size.
 * @param {number} FILE_SIZE - allowed value.
 * @returns {boolean}
 */
export const isValidFileSize = fp.curry(
  (size: number, FILE_SIZE = DEFAULT_SIZE) => size <= FILE_SIZE,
);
