import React, { HTMLProps } from "react";
import { InputField } from "@design-system/inputs";
import { useForm } from "react-hook-form";
import { Form } from "./components/Form";
import { translations } from 'translations';
import { useTranslation } from "react-i18next";
import { Button } from "@design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DEFAULT_VALUES,
  IResetPasswordFormFields,
  ResetPasswordFormValidationSchema
} from "./helpers";

export interface IResetPasswordFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Form default values.
   */
  defaultValues?: IResetPasswordFormFields;
  /**
   * On submit action handler.
   */
  onSubmit: (v: IResetPasswordFormFields) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = (
  props
): JSX.Element => {
  const { className, defaultValues = DEFAULT_VALUES, onSubmit, primaryColor, ...rest } = props;

  const {
    control,
    formState,
    handleSubmit
  } = useForm<IResetPasswordFormFields>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: yupResolver(ResetPasswordFormValidationSchema),
  });

  const { t } = useTranslation();

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        className="mb-6 w-full md:w-[300px]"
        control={control}
        name="email"
        placeholder={t(translations.INPUTS.EMAIL)}
        size="md"
        primaryColor={primaryColor}
        type="email"
      />

      <Button
        className="w-full md:w-[250px]"
        color={primaryColor}
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        size="sm"
        type="submit">
        {t(translations.COMMON.CONTINUE)}
      </Button>
    </Form>
  )
}
