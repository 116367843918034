import { translations } from 'translations';
import * as Yup from 'yup';

export const SignerFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(translations.ERRORS.FORM_NAME_REQUIRED)
    .max(50, translations.ERRORS.FORM_NAME_MAX_50),
  email: Yup.string()
    .email(translations.ERRORS.VALID_EMAIL)
    .required(translations.ERRORS.REQUIRED_EMAIL)
    .min(6, translations.ERRORS.VALID_EMAIL_CHARACTERS),
});
