import * as Sentry from '@sentry/react';
const env = process.env.NODE_ENV;
const isReviewApp = process.env.REACT_APP_IS_REVIEW;
const isProduction = env === 'production' && !isReviewApp;

export const useSentry = () => {
  const addBreadcrumb = (category: string, message: string) => {
    if (isProduction) {
      Sentry.addBreadcrumb({
        category,
        message,
        level: Sentry.Severity.Info,
      });
    }
  };

  const captureException = (error: Error, extra = {}) => {
    if (isProduction) {
      Sentry.captureException(error, {
        extra,
      });
    }
  };

  return { addBreadcrumb, captureException };
};
