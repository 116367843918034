import { clomp } from 'clomp';

export const Base = clomp.div`
  bg-white
  border-2
  border-gray-100
  cursor-pointer
  duration-500
  flex
  flex-col
  h-full
  px-6
  py-4
  rounded-2xl
  transition-all
  w-full
`;
