/**
 * Enum for input sizes.
 * @readonly
 * @enum {string}
 */
export enum InputSize {
  xs = 'h-[32px] text-xs',
  sm = 'h-[40px] text-sm',
  md = 'h-[48px] text-md',
  lg = 'h-[56px] text-lg',
  xl = 'h-[64px] text-xl',
}
