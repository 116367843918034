import React from 'react';
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@design-system';
import { PaginationItem, ItemContainer, Base } from './components/Base';
import { usePagination, DOTS } from 'hooks/usePagination';
import { SELECT_OPTIONS } from './helpers';
import cn from 'classnames';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { Select } from '@design-system/inputs/BaseInputs';

export interface IPaginationProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Represents the current active page. (using 1-based index,
   * not 0-based index).
   */
  currentPage: number;
  /**
   * If `true` will show the total count. `True as default`.
   */
  isCountActive?: boolean;
  /**
   * If `true` will show the page size select. `True as default`.
   */
  isSelectorActive?: boolean;
  /**
   * Callback function invoked with the updated page value when the page is
   * changed.
   */
  onPageChange: (page: number) => void;
  /**
   * Callback function invoked with the updated page s value when the page size
   * is changed.
   */
  onSizeChange: (pageSize: number) => void;
  /**
   * Represents the maximum data that is visible in a single page.
   */
  pageSize: number;
  /**
   * Custom White Label color.
   */
  primaryColor: string;
  /**
   * Represents the min number of page buttons to be shown on each side of the
   * current page button. Defaults to 1.
   */
  siblingCount?: number;
  /**
   * Total count of elements
   */
  totalCount: number;
}

export const Pagination: React.FC<IPaginationProps> = (
  props,
): JSX.Element | null => {
  const { t } = useTranslation();
  const {
    className,
    currentPage,
    onPageChange,
    onSizeChange,
    pageSize,
    siblingCount = 0,
    totalCount,
    isCountActive = true,
    isSelectorActive = true,
    primaryColor,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (fp.isNil(paginationRange)) return null;

  const lastPage = paginationRange[paginationRange.length - 1];

  const onPrevious = () => {
    if (currentPage === 1) return null;
    onPageChange(currentPage - 1);
  };

  const onNext = () => {
    if (currentPage === lastPage) return null;
    onPageChange(currentPage + 1);
  };

  const onSelectPage = (page) => {
    onPageChange(page);
  };

  return (
    <Base className={cn(className)}>
      <div className="flex flex-1 justify-between md:justify-start">
        {isCountActive && (
          <ItemContainer className="md:mr-8">
            <p className="text-sm">
              <strong>{totalCount}</strong> {t(translations.PAGINATION.RESULTS)}
            </p>
          </ItemContainer>
        )}

        {isSelectorActive && (
          <ItemContainer>
            <p className="mr-2 text-sm">{t(translations.PAGINATION.SHOW)}</p>
            <Select
              className="w-16"
              menuPlacement="top"
              onChange={(value) => onSizeChange(value)}
              options={SELECT_OPTIONS(t)}
              primaryColor={primaryColor}
              size="xs"
              value={pageSize}
            />
          </ItemContainer>
        )}
      </div>

      <div className="flex flex-1 justify-center md:justify-end">
        <ItemContainer>
          <PaginationItem onClick={onPrevious}>
            <ChevronLeftIcon color="#A5A5A5" height={12} width={6} />
          </PaginationItem>
          {paginationRange.map((pageNumber, index) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return (
                <PaginationItem key={index}>
                  <p>...</p>
                </PaginationItem>
              );
            }

            // Render our Page Pills
            if (pageNumber === currentPage) {
              return (
                <Button
                  color={primaryColor}
                  className="px-0 text-md"
                  key={index}
                  size="xs"
                  variant="outline">
                  {pageNumber}
                </Button>
              );
            }

            return (
              <PaginationItem
                key={index}
                onClick={() => onSelectPage(pageNumber)}>
                <p>{pageNumber}</p>
              </PaginationItem>
            );
          })}

          <PaginationItem onClick={onNext}>
            <ChevronRightIcon color="#A5A5A5" height={12} width={6} />
          </PaginationItem>
        </ItemContainer>
      </div>
    </Base>
  );
};
