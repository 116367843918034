import { ReactNode } from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  ButtonGroup,
  Spinner,
} from '@chakra-ui/react';

export type Sizes = 'narrow' | 'wide';

/**
 * Props for the EmptyState component.
 */
interface EmptyStateProps {
  /**
   * The header text for the empty state.
   */
  header: string;

  /**
   * Optional description text for the empty state.
   */
  description?: string;

  /**
   * The size of the empty state.
   */
  size?: Sizes;

  /**
   * URL of the image to be displayed.
   */
  imageUrl?: string;

  /**
   * Maximum width of the image.
   */
  maxImageWidth?: number;

  /**
   * Maximum height of the image.
   */
  maxImageHeight?: number;

  /**
   * Primary action element to be displayed.
   */
  primaryAction?: ReactNode;

  /**
   * Secondary action element to be displayed.
   */
  secondaryAction?: ReactNode;

  /**
   * Tertiary action element to be displayed.
   */
  tertiaryAction?: ReactNode;

  /**
   * Flag indicating whether the component is in loading state.
   */
  isLoading?: boolean;

  /**
   * Width of the image.
   */
  imageWidth?: number;

  /**
   * Height of the image.
   */
  imageHeight?: number;
}

export function EmptyState(props: EmptyStateProps) {
  const {
    imageUrl,
    primaryAction,
    secondaryAction,
    isLoading,
    header,
    description,
    tertiaryAction,
  } = props;
  const styles = getStyles(props);

  const actionsContainer =
    primaryAction || secondaryAction || isLoading ? (
      <Box {...styles.actions}>
        <ButtonGroup>
          {primaryAction}
          {secondaryAction}
        </ButtonGroup>
        <Box {...styles.spinner}>{isLoading && <Spinner />}</Box>
      </Box>
    ) : null;

  return (
    <Box {...styles.container}>
      {imageUrl && (
        <Image alt="" role="presentation" src={imageUrl} {...styles.image} />
      )}
      <Heading {...styles.header}>{header}</Heading>
      {description && <Text {...styles.description}>{description}</Text>}
      {actionsContainer}
      {tertiaryAction}
    </Box>
  );
}

function getStyles({
  maxImageWidth = 160,
  maxImageHeight = 160,
  imageWidth,
  imageHeight,
  size = 'wide',
}: EmptyStateProps) {
  const gridSize = 8;
  const verticalMarginSize = gridSize * 6;

  const columnWidth = gridSize * 8;
  const gutter = gridSize * 2;

  const wideContainerWidth = columnWidth * 6 + gutter * 5;
  const narrowContainerWidth = columnWidth * 4 + gutter * 3;

  return {
    container: {
      mx: 'auto',
      my: verticalMarginSize,
      textAlign: 'center',
      maxW: size === 'narrow' ? narrowContainerWidth : wideContainerWidth,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pl: `${5 * gridSize}px`,
      mb: `${gridSize}px`,
    },
    spinner: {
      ml: `${2 * gridSize}px`,
      width: `${3 * gridSize}px`,
    },
    image: {
      display: 'block',
      maxW: maxImageWidth,
      maxH: maxImageHeight,
      width: imageWidth || 'auto',
      height: imageHeight || 'auto',
      margin: `0 auto ${gridSize * 3}px`,
    },
    header: {
      size: 'md',
      mb: `${gridSize * 2}px`,
      mt: 0,
    },
    description: {
      mt: 0,
      mb: `${gridSize * 3}px`,
    },
  };
}
