import { useState, useLayoutEffect } from 'react';
const getMobileDetect = (userAgent: string) => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i));

  const width = (window as any).innerWidth;
  const height = (window as any).innerHeight;
  const isMobile = (): boolean => width < 648;
  const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isOpera,
    isWindows,
    isSSR,
    width,
    height,
  };
};
export const useMobileDetect = () => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const [mobile, setMobile] = useState<any>(getMobileDetect(userAgent));

  useLayoutEffect(() => {
    function updateSize() {
      setMobile(getMobileDetect(userAgent));
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...mobile };
};
