import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { ERROR_TOAST_DEFAULTS, SUCCESS_TOAST_DEFAULTS } from '@helpers';

/**
 * Curry function that triggers an error or success toast depending on the
 * response status code and the `isError` flag.
 * @param {{ isError: boolean }} meta - function metadata.
 * @returns {(status: number) => void}
 */
export type IUseResponseMsgFn = (meta: {
  isError: boolean;
}) => (status: number) => void;

export const useResponseMsg = (): [IUseResponseMsgFn] => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean | null>(null);
  const [msg, setMsg] = useState<null | string>(null);

  const toast = useToast();

  const fn = useCallback(
    (meta = { isError: null }) =>
      (status: number) => {
        setIsError(meta?.isError);

        if (status === 200) {
          setMsg(t(translations.SUCCESS.WHITELABEL_200));
        }
        if (status === 401) {
          setMsg(t(translations.ERRORS.WHITELABEL_401));
        }
        if (status === 402) {
          setMsg(t(translations.ERRORS.WHITELABEL_402));
        }
        if (status === 404) {
          setMsg(t(translations.ERRORS.WHITELABEL_404));
        }
        if (status === 413) {
          setMsg(t(translations.ERRORS.WHITELABEL_413));
        }
        if (status === 415) {
          setMsg(t(translations.ERRORS.WHITELABEL_415));
        }
        if (status === 500) {
          setMsg(t(translations.ERRORS.WHITELABEL_500));
        }
      },
    [],
  );

  useEffect(() => {
    if (!fp.isNil(msg) && isError) {
      toast({ ...ERROR_TOAST_DEFAULTS, title: msg });
      setIsError(null);
      setMsg(null);
    }
    if (!fp.isNil(msg) && !isError) {
      toast({ ...SUCCESS_TOAST_DEFAULTS, title: msg });
      setMsg(null);
    }
  }, [isError, msg, toast]);

  return [fn];
};
