/** @jsx jsx */
import React from 'react';
import { Control, useController, UseFormRegisterReturn } from 'react-hook-form';
import { FormErrorMessage, FormHelperText, Stack } from '@design-system';
import { CustomInput } from './components/CustomInput';
import { Label } from './components/Label';
import {
  getDynamicCSS,
  getInputPropsCSS,
  getLabelPropsCSS,
  InputFontSize,
  InputSize,
} from './helpers';
import cn from 'classnames';
import fp from 'lodash/fp';

import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export interface IInputFieldProps {
  /**
   * Specify what if any permission the user agent has to provide automated
   * assistance in filling out form field values.
   */
  autoComplete?: 'on' | 'off';
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * RHF `control` object provided by invoking useForm. This prop is optional
   * when using FormProvider.
   */
  control?: Control<any, any>;
  /**
   * Input default value.
   */
  defaultValue?: string;
  /**
   * Input helper text that will be displayed below the input.
   */
  helper?: string;
  /**
   * Custom CSS tailwind styles for the input tag.
   */
  inputClassName?: string;
  /**
   * If `true`, this field will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, this field will be required by the form.
   */
  isRequired?: boolean;
  /**
   * If `true`, the input will have rounded borders.
   */
  isRounded?: boolean;
  /**
   * Input label, can be a raw text or an i18n key.
   */
  label?: string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Input name that RHF will use to identify the input on the HTML code.
   */
  name: string;
  /**
   * Input placeholder, can be a raw text or an i18n key.
   */
  placeholder?: string;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Input size, it will adjust the label font size if the input has one.
   */
  size: keyof typeof InputSize;
  /**
   * Input type, this component is designed for the following types:
   * "email" | "number" | "password" | "text".
   */
  type: 'email' | 'number' | 'password' | 'text';
  /**
   * Custom registration for dynamic fields
   */
  registration?: Partial<UseFormRegisterReturn>;
}

export const InputField: React.FC<IInputFieldProps> = (props): JSX.Element => {
  const {
    autoComplete = 'on',
    className,
    control,
    defaultValue,
    helper,
    inputClassName,
    isDisabled = false,
    isRequired = false,
    isRounded = true,
    label,
    labelClassName,
    name,
    placeholder,
    primaryColor,
    size = 'sm',
    type = 'text',
    registration = {},
  } = props;

  const { t } = useTranslation();
  const { field, fieldState } = useController({ defaultValue, control, name });

  return (
    <Stack className={cn(className)}>
      {!fp.isNil(label) && !isRequired ? (
        <Label
          className={cn(InputFontSize[size], labelClassName)}
          htmlFor={name}>
          {label}
        </Label>
      ) : null}

      <div className="relative">
        <CustomInput
          autoComplete={autoComplete}
          className={cn(
            getInputPropsCSS(isDisabled, isRequired, isRounded),
            InputSize[size],
            inputClassName,
          )}
          css={getDynamicCSS(primaryColor, isDisabled)}
          disabled={isDisabled}
          placeholder={isRequired ? label : placeholder}
          required={isRequired}
          type={type}
          {...field}
          {...registration}
        />

        {isRequired ? (
          <Label.Required
            className={cn(
              getLabelPropsCSS(isRequired),
              InputFontSize[size],
              labelClassName,
            )}
            htmlFor={name}>
            {label}
          </Label.Required>
        ) : null}
      </div>

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {t(fieldState?.error?.message as string)}
      </FormErrorMessage>
    </Stack>
  );
};
