import React, { HTMLProps } from 'react';
import { useForm } from 'react-hook-form';
import { InputField, SelectField } from '@design-system/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewFormTemplateValidationSchema } from './helpers';
import { Form } from './components/Form';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { Button } from '@design-system';
import { ISelectAtomicOption } from "@design-system/inputs/BaseInputs";

export type NewFormTemplateFields = {
  name: string;
  description: string;
  helperText: string;
};

export interface INewFormTemplateProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * List of folders
   */
  folders?: ISelectAtomicOption[];
  /**
   * On submit action handler.
   */
  onSubmit: (v: NewFormTemplateFields) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Initial values
   */
  initialValues: any;
  /**
   * If `true` will render edit mode
   */
  isEditMode?: boolean;
  /**
   * If `true` will set loading state
   */
  isLoading?: boolean;
}

export const NewFormTemplate: React.FC<INewFormTemplateProps> = (props): JSX.Element => {
  const { className, primaryColor, onSubmit, initialValues, isEditMode = false, isLoading = false, ...rest } = props;
  const { t } = useTranslation();
  const { formState, control, handleSubmit } = useForm<NewFormTemplateFields>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(NewFormTemplateValidationSchema),
  });

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        label={t(translations.INPUTS.NEW_FORM)}
        className="mb-4 w-full"
        control={control}
        name="name"
        size="sm"
        primaryColor={primaryColor}
        type="text"
        isRequired
      />
      <InputField
        label={t(translations.COMMON.DESCRIPTION)}
        className="mb-4 w-full"
        control={control}
        name="description"
        size="sm"
        primaryColor={primaryColor}
        type="text"
        isRequired
      />
      <InputField
        className="mb-4 w-full"
        control={control}
        name="helperText"
        placeholder={t(translations.INPUTS.HELPER_TEXT)}
        size="sm"
        primaryColor={primaryColor}
        type="text"
      />

      <div>
        <Button
          color={primaryColor}
          className="max-w mb-2 mr-8 px-8"
          isDisabled={formState?.isSubmitting || !formState?.isValid}
          type="submit"
          isLoading={formState?.isSubmitting || isLoading}
          size="sm">
          {!isEditMode ? t(translations.COMMON.CREATE) : t(translations.COMMON.EDIT)}
        </Button>
      </div>
    </Form>
  );
};
