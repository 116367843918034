import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Container } from './components/Container';
import { use100vh } from 'react-div-100vh';
import { Footer } from './components/Footer';

import { useSession } from 'store/session';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { ROUTES } from './helpers';
import { nanoid } from 'nanoid';
import { WLContext } from '@contexts';
import { Logo } from '@design-system';


const AuthPages: React.FC = (): JSX.Element | null => {
  const { isFetching, logo } = useContext(WLContext);

  const { isLoggedIn } = useSession();

  const { t } = useTranslation();

  const height = use100vh();

  if (isLoggedIn) return <Redirect to="/" />;

  if (isFetching) return null;

  return (
    <Container style={{ minHeight: `${height}px` }}>
      <main>
        <Logo height="42px" logo={logo} width="224px" />

        <Switch>
          {ROUTES.map(({ element: Element, path }) => (
            <Route key={nanoid()} path={path}>
              <Element />
            </Route>
          ))}
        </Switch>
      </main>

      <Footer>{t(translations.COMMON.CINCEL_TITLE)}</Footer>
    </Container>
  );
};

export default AuthPages;
