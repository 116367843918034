import React, { HTMLProps } from "react";
import { InputField } from "@design-system/inputs";
import { useForm } from "react-hook-form";
import { Form } from "./components/Form";
import { useTranslation } from "react-i18next";
import { Button } from "@design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DEFAULT_VALUES,
  IConfirmAccountFormFields,
  ConfirmAccountFormValidationSchema
} from "./helpers";
import { translations } from 'translations';

export interface IConfirmAccountFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Form default values.
   */
  defaultValues?: IConfirmAccountFormFields;
  /**
   * On submit action handler.
   */
  onSubmit: (v: IConfirmAccountFormFields) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const ConfirmAccountForm: React.FC<IConfirmAccountFormProps> = (
  props
): JSX.Element => {
  const {
    className,
    defaultValues = DEFAULT_VALUES,
    onSubmit,
    primaryColor,
    ...rest
  } = props;

  const {
    control,
    formState,
    handleSubmit
  } = useForm<IConfirmAccountFormFields>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: yupResolver(ConfirmAccountFormValidationSchema),
  });

  const { t } = useTranslation();

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        className="mb-6 w-full md:w-[300px]"
        control={control}
        name="code"
        placeholder={t(translations.INPUTS.VERIFICATION_CODE_LABEL)}
        size="md"
        primaryColor={primaryColor}
        type="text"
      />

      <Button
        className="w-full md:w-[250px]"
        color={primaryColor}
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        size="sm"
        type="submit">
        {t(translations.COMMON.VALIDATE)}
      </Button>
    </Form>
  )
}
