import React from 'react';
import cn from 'classnames';

export interface IContentProps {
  /**
   * If `true`, it will add a margin left of `8px` to the button content.
   */
  hasLI: boolean;
  /**
   * If `true`, it will add a margin right of `8px` to the button content.
   */
  hasRI: boolean;
  /**
   * If `true`, it will add an opacity of 0 to the button content in order to
   * hide it and render the spinner.
   */
  isLoading: boolean;
}

export const Content: React.FC<IContentProps> = (props): JSX.Element => {
  const { children, hasLI, hasRI, isLoading } = props;

  return (
    <span className={
      cn({ "ml-2": hasLI, "mr-2": hasRI, "opacity-0": isLoading })}
    >
      {children}
    </span>
  )
}
