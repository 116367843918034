export enum DocumentTransEnum {
  partially_signed = 'common.partiallySigned',
  signed = 'common.signed',
  started = 'common.started',
  unsigned = 'common.unsigned',
}

export enum DocumentStatusEnum {
  partially_signed = 'partially_signed',
  signed = 'signed',
  started = 'started',
  unsigned = 'unsigned',
}
