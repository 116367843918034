import React, { HTMLProps } from 'react';
import cn from 'classnames';

export type ILinkProps = HTMLProps<HTMLAnchorElement>;

export const Link: React.FC<ILinkProps> = (props): JSX.Element => {
  const { children, className, target = "_black", ...rest } = props;
  return (
    <a className={cn("font-bold", className)} target={target} {...rest}>
      {children}
    </a>
  );
}
