import * as Yup from 'yup';
import { translations } from 'translations';

export interface ITextFieldFormValuesProps {
  /**
   * Field name of the form. This should be required.
   */
  name: string;
  /**
   * Default value for the text field
   */
  default_value: string;
  is_required: boolean;
  is_read_only: boolean;
}

export const TextFieldFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(translations.ERRORS.REQUIRED_NAME)
    .max(50, translations.ERRORS.MAX_LENGTH_50),
  default_value: Yup.string().max(255, translations.ERRORS.MAX_LENGTH_255),
  fieldFlags: Yup.array().of(Yup.string()),
  is_required: Yup.boolean(),
  is_read_only: Yup.boolean(),
});

export const DEFAULT_VALUES = {
  name: '',
  is_required: false,
  is_read_only: false,
};
