import React, { HTMLProps } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@design-system';
import { InputField } from '@design-system/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DEFAULT_VALUES,
  ISignupFormValuesProps,
  PRIVACY_POLICY_URL,
  SignupFormValidationSchema,
  TERMS_AND_CONDITIONS_URL,
} from './helpers';
import { Form } from './components/Form';
import { Link } from './components/Link';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface ISignupFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Form default values.
   */
  defaultValues?: ISignupFormValuesProps;
  /**
   * On submit action handler.
   */
  onSubmit: (v: ISignupFormValuesProps) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const SignupForm: React.FC<ISignupFormProps> = (props): JSX.Element => {
  const {
    className,
    defaultValues = DEFAULT_VALUES,
    onSubmit,
    primaryColor,
    ...rest
  } = props;

  const { control, formState, handleSubmit } = useForm<ISignupFormValuesProps>({
    defaultValues: DEFAULT_VALUES,
    mode: 'all',
    resolver: yupResolver(SignupFormValidationSchema),
  });

  const { t } = useTranslation();

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="email"
        placeholder={t(translations.INPUTS.EMAIL)}
        primaryColor={primaryColor}
        size="md"
        type="email"
      />

      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="name"
        placeholder={t(translations.INPUTS.NAME)}
        primaryColor={primaryColor}
        size="md"
        type="text"
      />

      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="password"
        placeholder={t(translations.INPUTS.PASSWORD)}
        primaryColor={primaryColor}
        size="md"
        type="password"
      />

      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="confirm_password"
        placeholder={t(translations.INPUTS.CONFIRM_PASSWORD)}
        primaryColor={primaryColor}
        size="md"
        type="password"
      />

      <p className="mb-5 w-[316px] text-center text-xs text-gray-300">
        <span>{t(translations.AUTH_PAGES.SIGNUP_CLICK)} </span>
        <Link href={TERMS_AND_CONDITIONS_URL}>
          {t(translations.COMMON.TERMS)}
        </Link>
        <span> {t(translations.COMMON.AND_THE)} </span>
        <Link href={PRIVACY_POLICY_URL}>
          {t(translations.COMMON.PRIVACY_POLICY)}
        </Link>
      </p>

      <Button
        className="w-full md:w-[250px]"
        color={primaryColor}
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        size="sm"
        type="submit">
        {t(translations.COMMON.SIGN_UP)}
      </Button>
    </Form>
  );
};
