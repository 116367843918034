import fp from 'lodash/fp';

export const getValue = fp.get('value');

export const isValueNil = fp.compose(fp.isNil, getValue);

export const isURL = fp.compose(fp.isString, getValue);

export const createImageURL = fp.compose(
  fp.curry((v: File | Blob) => URL.createObjectURL(v)),
  getValue,
);
