import { clomp } from 'clomp';
import React from 'react';

import { IPropsOf } from 'types.d';

const Base = clomp.tr`
  border-none

  even:bg-gray-25
`;

export type ITrProps = IPropsOf<'tr'>;

export const Tr: React.FC<ITrProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return <Base {...rest}>{children}</Base>;
};
