import React from "react";
import { ISVGProps } from "types";

export const FingerprintIcon: React.FC<ISVGProps> = (props) => (
  <svg
    height="1em"
    viewBox="0 0 128 128"
    xmlSpace="preserve"
    width="1em"
    x={0}
    xmlns="http://www.w3.org/2000/svg"
    y={0}
    {...props}
  >
    <style>
      {
        ".fingerprint-icon_svg__st0{fill:none;stroke:currentColor;stroke-color:red;stroke-width:5;stroke-linecap:round;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M80 51.5c-2.6-3.1-7.5-5.9-14.5-5.9-11.1 0-19.2 7.7-19.2 17.9 0 11.7 8.4 18.9 18.7 18.9 8.6 0 12.7-3.9 15.3-6.6l9.7 9.6c-5 4.8-11.4 10.4-24.4 10.4-18.3 0-35.7-12.3-35.5-32.3.2-14.8 12-31.3 35.3-31.3 13.8 0 21.7 5.6 25 9.1C90 41.7 80 51.5 80 51.5z"
      fill="currentColor"
    />
    <path
      d="M64 5c32.5 0 59 26.5 59 59s-26.5 59-59 59S5 96.5 5 64 31.5 5 64 5m0-5C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0z"
      fill="currentColor"
    />
    <path
      className="fingerprint-icon_svg__st0"
      d="M106.5 81.7c-6.5 17.8-23.6 29.7-42.6 29.7-18.8 0-35.9-11.8-42.5-29.4M21.5 46.2C28.1 28.6 45.2 16.8 64 16.8c18.9 0 36 11.9 42.6 29.7"
      fill="currentColor"
    />
  </svg>
);
