import { translations } from 'translations';
import * as Yup from 'yup';

export const LoginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(translations.ERRORS.VALID_EMAIL)
    .required(translations.ERRORS.REQUIRED_EMAIL)
    .min(6, translations.ERRORS.VALID_EMAIL_CHARACTERS),
  password: Yup.string()
    .required(translations.ERRORS.REQUIRED_PASSWORD)
    .min(6, translations.ERRORS.PASSWORD_LENGTH),
});
