import React, { useCallback, useMemo } from 'react';
import { useMobileDetect } from 'hooks/useIsMobile';
import { CreateIcon, ShareIcon, SignIcon, StepIcon } from '@design-system';
import cn from 'classnames';
import fp from 'lodash/fp';

export interface StepProps {
  primaryColor: string;
  className?: string;
  active: boolean;
  icon: string;
  label: string;
  done: boolean;
  onClick: (id: string) => void;
}

export const Step: React.FC<StepProps> = ({
  primaryColor,
  active = true,
  icon = 'crea',
  label = 'Crea',
  done = false,
  onClick,
}) => {
  const { isMobile: isMobileFn } = useMobileDetect();

  const handleOnClick = useCallback(() => {
    if (!fp.isNil(onClick)) onClick(icon);
  }, [icon, onClick]);

  const isMobile: boolean = useMemo(isMobileFn, [isMobileFn]);

  return (
    <div className={cn("relative", done ? 'cursor-pointer' : '')} onClick={handleOnClick}>
      <StepIcon
        color={done || active ? primaryColor : '#C4C4C4'}
        height={60}
        isMobile={isMobile}
        isDone={done}
        width={isMobile ? 80 : 194}
      />
      <div className={cn("absolute flex items-center top-1/2 transform -translate-y-1/2", isMobile ? 'left-1/2 -translate-x-1/2' : 'left-[40px]')}>
        {icon === 'crea' ? <CreateIcon color={active || done ? primaryColor : '#C4C4C4'} height={19} width={24} /> : null}
        {icon === 'comparte' ? <ShareIcon color={active || done ? primaryColor : '#C4C4C4'} height={18} width={24} /> : null}
        {icon === 'firma' ? <SignIcon color={active || done ? primaryColor : '#C4C4C4'} height={24} width={17} /> : null}
        <p
          className="hidden sm:block ml-2"
          style={{
            color: done || active ? primaryColor : '#C4C4C4',
            fontSize: '14px',
            fontWeight: active ? 700 : 400
          }}
        >
          {label}</p>
      </div>
    </div>
  );
};
