import React, { useEffect } from 'react';
import { useSession } from 'store/session';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

const GROWTHBOOK_URL = process.env.REACT_APP_GROWTHBOOK;

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
  onFeatureUsage: (_featureKey, _result) => {},
});

const Trackers = ({ children }) => {
  const { user, currentOrganization, isLoggedIn } = useSession();

  useEffect(() => {
    // Load feature definitions from API

    if (GROWTHBOOK_URL) {
      fetch(GROWTHBOOK_URL)
        .then((res) => res.json())
        .then((json) => {
          growthbook.setFeatures(json.features);
          if (user && currentOrganization) {
            growthbook.setAttributes({
              id: user.id,
              loggedIn: true,
              organizationId: (currentOrganization as any).id,
              email: user.email,
            });
          }
        });
    }

    if (isLoggedIn) {
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,

        // Add your own custom user variables here, ie:
        organizationId: (currentOrganization as any).id,
      });
      Sentry.configureScope((scope: any) => {
        scope.setUser({
          name: user.name,
          email: user.email,

          // Add your own custom user variables here, ie:
          organizationId: (currentOrganization as any).id,
        });
      });
      Sentry.setContext('user', {
        name: user.name,
        email: user.email,
        organizationId: (currentOrganization as any).id,
      });
    }

    if (!isLoggedIn && window.location.pathname === '/sign_invitation/') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const userToLog = {
        id: Date.now(),
        email: params.email,
        documentId: params.document_id,
        invitationToken: params.invitation_token,
      };

      growthbook.setAttributes({
        id: userToLog.id,
        loggedIn: false,
        organizationId: null,
        email: userToLog.email,
      });

      LogRocket.identify(params.email, {
        email: params.email,
        documentId: params.document_id,
        invitationToken: params.invitation_token,
      });

      Sentry.configureScope((scope: any) => {
        scope.setUser({
          email: params.email,
          documentId: params.document_id,
          invitationToken: params.invitation_token,
        });
      });
      Sentry.setContext('not_logged_user', {
        email: params.email,
        documentId: params.document_id,
        invitationToken: params.invitation_token,
      });
    }
  }, [user, currentOrganization, isLoggedIn]);
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default Trackers;
