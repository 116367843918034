import React, { Children, cloneElement, useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import Portal from './components/Portal';
import {
  arrowContainerClass,
  arrowClass,
  tooltipContainerClass,
  tooltipClass,
} from './styles';

export enum TooltipPlacement {
  topLeft = 'top-start',
  top = 'top',
  topRight = 'top-end',
  leftTop = 'left-start',
  left = 'left',
  leftBottom = 'left-end',
  rightTop = 'right-start',
  right = 'right',
  rightBottom = 'right-end',
  bottomLeft = 'bottom-start',
  bottom = 'bottom',
  bottomRight = 'bottom-end',
}

export enum TooltipColor {
  black = 'black',
}

export enum TooltipSize {
  sm = 'sm',
  md = 'md',
}

export interface TooltipProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Text to show within the Tooltip
   */
  text: string;

  /**
   * The color of the component. It supports those theme colors that makes sense for this component.
   */
  color?: string;

  /**
   * Changes the size of the Tooltip.
   */
  size?: string;

  /**
   * Position of the Tooltip.
   */
  placement?: TooltipPlacement;

  /**
   * Whether the Tooltip has an arrow or not.
   */
  hasArrow?: boolean;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  ariaLabel,
  text,
  color = TooltipColor.black,
  placement = TooltipPlacement.top,
  size = TooltipSize.md,
  hasArrow = true,
  className,
  children,
}) => {
  const [tooltipElement, setTooltipElement] = useState<HTMLSpanElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const refTriggerNode = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  /* Popper config */
  const { styles, attributes } = usePopper(
    refTriggerNode.current,
    tooltipElement,
    {
      placement,
      modifiers: [
        { name: 'offset', options: { offset: [0, 8] } },
        { name: 'arrow', options: { element: arrowElement } },
      ],
    },
  );

  const handleMouseOver = (): void => setOpen(true);
  const handleMouseOut = (): void => setOpen(false);

  let elements: any = Children.toArray(children); //[1]

  /* Append handle to the trigger component */
  elements = cloneElement(elements[0], {
    ref: refTriggerNode,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  });

  return (
    <>
      {elements}
      <Portal>
        <div
          aria-label={ariaLabel}
          className={tooltipContainerClass(className)}
          ref={setTooltipElement}
          style={{ ...styles.popper }}
          {...attributes.popper}>
          {open && (
            <span className={tooltipClass(size, color)}>
              {text}
              {hasArrow && (
                <div
                  id="arrow"
                  ref={setArrowElement}
                  style={styles.arrow}
                  data-popper-arrow
                  className={arrowContainerClass(placement)}>
                  <span className={arrowClass}></span>
                </div>
              )}
            </span>
          )}
        </div>
      </Portal>
    </>
  );
};

/**
 * [1]
 * References:
 * https://css-tricks.com/using-react-portals-to-render-children-outside-the-dom-hierarchy/
 * https://codepen.io/davidgilbertson/pen/ooXVyw
 */
