import { clomp } from 'clomp';

export const ImgContainer = clomp.div`
  hidden
  overflow-hidden

  md:block
  md:mr-7

  ${({ isDarkBg }) => (isDarkBg ? 'bg-gray-600' : '')}
  ${({ isRounded }) => (isRounded ? 'rounded-full' : 'rounded')}
`;
