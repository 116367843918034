import { clomp } from 'clomp';

export const Base = clomp.div`
  flex
  flex-col
  w-full

  md:flex-row
`;

export const PaginationItem = clomp.div`
  cursor-pointer
  flex
  h-8
  items-center
  justify-center
  rounded-full
  w-8
`;

export const ItemContainer = clomp.div`
  cursor-pointer
  flex
  h-8
  items-center
  justify-center
`;
