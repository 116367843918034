import { translations } from 'translations';

export const FIELD_FLAGS = [
  {
    name: translations.INPUTS.TEXT_FIELD_FORM_FIELD_FLAGS_READ_ONLY,
    val: 'is_read_only',
  },
  {
    name: translations.INPUTS.TEXT_FIELD_FORM_FIELD_FLAGS_REQUIRED,
    val: 'is_required',
  },
];
