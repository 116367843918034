import React from "react";
import { ISVGProps } from "types";

export const DotsIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 5 23"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={2.5} cy={2.5} fill="currentColor" r={2.5} />
    <circle cx={2.5} cy={11.5} fill="currentColor" r={2.5} />
    <circle cx={2.5} cy={20.5} fill="currentColor" r={2.5} />
  </svg>
);
