import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button } from '@design-system';
import { useOnClickOutside } from 'hooks/useClickOutside';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useQuery, useMutation } from 'react-query';
import { useSession } from 'store/session';
import { ReactComponent as HelpIcon } from './menu-ayuda.svg';
import { ReactComponent as Notifications } from './notifications.svg';
import { useClient } from 'hooks/useClient';
import {
  tokenV3Name,
  expirationName,
  userName,
  tokenName,
} from 'config/constants';
import { useToast } from '@chakra-ui/react';
import { getCurrentRole } from 'utils/getCurrentRole';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

const acceptInvitationMutation = (client, id) => {
  return client.acceptInvitation(id);
};

const rejectInvitationMutation = (client, id) => {
  return client.declineInvitation(id);
};

const renewLoginMutation = async (client: any) => {
  const tokenV1 = localStorage.getItem(tokenName);
  if (tokenV1) return client.renewLogin(tokenV1);

  return Promise.resolve(null);
};

export const UserDropdown = ({ handleProfile, handleLogout, primaryColor }) => {
  const { t } = useTranslation();
  const client = useClient({
    url: process.env.REACT_APP_API,
    token: localStorage.getItem(tokenV3Name) as string,
  });
  const [avatar, setAvatar] = useState();
  const [invitations, setInvitations] = useState([]);
  const toast = useToast();
  const {
    currentOrganization,
    defaultOrganization,
    setOrganizations,
    setOrganizationImages,
    setCurrentOrganization,
    setUser,
    setToken: setSessionToken,
    setIsLoggedIn,
    isLoggedIn,
    user,
  } = useSession();

  const { mutateAsync: acceptInvitation, isLoading: isLoadingAccept } =
    useMutation((id) => acceptInvitationMutation(client, id));
  const { mutateAsync: rejectInvitation, isLoading: isLoadingReject } =
    useMutation((id) => rejectInvitationMutation(client, id));
  const { mutateAsync: renewLogin } = useMutation(() =>
    renewLoginMutation(client),
  );

  const node = useRef(null);
  useOnClickOutside(node, () => {});

  useEffect(() => {
    const renewUser = async () => {
      const renewResponse: any = await renewLogin();

      if (renewResponse.user) {
        localStorage.setItem(tokenName, renewResponse.token);
        localStorage.setItem(expirationName, renewResponse.expiration);
        localStorage.setItem(userName, JSON.stringify(renewResponse.user));
        setSessionToken(renewResponse.token);
        setUser(renewResponse.user);
        setIsLoggedIn(true);
        const formatedOrganizations: any = [];
        const organizationImages: any = [];

        for (const i in renewResponse.user.organizations) {
          organizationImages.push({
            id: renewResponse.user.organizations[i].id,
            image: renewResponse.user.organizations[i].imagotype,
          });
          const customOrg = {
            ...renewResponse.user.organizations[i],
            imagotype: null,
            rol: getCurrentRole(
              renewResponse.user.organizations[i].permissions,
            ),
          };
          formatedOrganizations.push(customOrg);
        }
        const orgCincelCopy = {
          ...defaultOrganization,
          rol: false,
        };

        setOrganizations([orgCincelCopy, ...formatedOrganizations]);
        setOrganizationImages(organizationImages);
        setCurrentOrganization(currentOrganization);
      }
    };
    if (user) renewUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && (user as any).avatar_url) {
      setAvatar((user as any).avatar_url as any);
    }
  }, [user]);

  const handleAcceptInvitation = async (id) => {
    const acceptResponse: any = await acceptInvitation(id);
    if (!acceptResponse.ok && acceptResponse.errors) {
      const error = Object.keys(acceptResponse.errors)
        .map((key) => acceptResponse.errors[key][0])
        .join(',');

      toast({
        position: 'top-right',
        isClosable: true,
        title: error,
        status: 'error',
      });
    }

    if (acceptResponse.ok) {
      toast({
        position: 'top-right',
        isClosable: true,
        title: t(translations.USER_DROPDOWN.INVITATION_ACCEPTED),
        status: 'success',
      });
      const renewResponse: any = await renewLogin();

      if (renewResponse.user) {
        localStorage.setItem(tokenV3Name, renewResponse.token);
        localStorage.setItem(expirationName, renewResponse.expiration);
        localStorage.setItem(userName, JSON.stringify(renewResponse.user));
        setSessionToken(renewResponse.token);
        setUser(renewResponse.user);
        setIsLoggedIn(true);
        const formatedOrganizations: any = [];
        const organizationImages: any = [];
        for (const i in renewResponse.user.organizations) {
          organizationImages.push({
            id: renewResponse.user.organizations[i].id,
            image: renewResponse.user.organizations[i].imagotype,
          });

          const customOrg = {
            ...renewResponse.user.organizations[i],
            imagotype: null,
            rol: getCurrentRole(
              renewResponse.user.organizations[i].permissions,
            ),
          };
          formatedOrganizations.push(customOrg);
        }

        const orgCincelCopy = {
          ...defaultOrganization,
          rol: false,
        };

        setOrganizations([orgCincelCopy, ...formatedOrganizations]);
        setOrganizationImages(organizationImages);
        setCurrentOrganization(currentOrganization);
      }
    }
  };

  const handleRejectInvitation = async (id) => {
    const rejectResponse: any = await rejectInvitation(id);
    if (!rejectResponse.ok && rejectResponse.errors) {
      const error = Object.keys(rejectResponse.errors)
        .map((key) => rejectResponse.errors[key][0])
        .join(',');

      toast({
        position: 'top-right',
        isClosable: true,
        title: error,
        status: 'error',
      });
    }

    if (rejectResponse.ok) {
      toast({
        position: 'top-right',
        isClosable: true,
        title: t(translations.USER_DROPDOWN.INVITATION_REJECTED),
        status: 'success',
      });
    }
  };

  const handleOpenQuotePage = () => {
    window.open('https://www.cincel.digital/cotizar/', '_blank');
  };

  return (
    <>
      <div className="dropdown flex">
        <div className="mr-4 flex">
          {isLoggedIn && (
            <Menu>
              <MenuButton
                as={IconButton}
                onClick={() =>
                  window.open('https://ayuda.cincel.digital/', '_blank')
                }
                aria-label="Options"
                icon={<HelpIcon />}
                variant="transparent"
                fontSize={12}
                style={{ outline: 0, boxShadow: 'none 1important' }}
              />
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={
                  <>
                    {invitations.length > 0 && (
                      <span className="absolute inset-0 -mr-6 object-right-top">
                        <div className="font-semibold inline-flex items-center rounded-full border-2 border-white bg-red-300 px-1.5 py-0.5 text-[10px] leading-4 text-white">
                          {invitations.length}
                        </div>
                      </span>
                    )}
                    <Notifications style={{ width: 18 }} />
                  </>
                }
                variant="transparent"
                fontSize={15}
                style={{ outline: 0, boxShadow: 'none !important' }}
              />
              <MenuList>
                {invitations.length > 0 &&
                  invitations.map((invitation: any) => {
                    return (
                      <MenuItem key={invitation.id} className="flex flex-col">
                        <div className="flex flex-col">
                          <strong>{(invitation as any).sender.email}</strong>
                          <span>
                            {t(translations.USER_DROPDOWN.INVITATION_MESSAGE)}
                          </span>
                        </div>
                        <div className="mt-3 flex w-full">
                          <Button
                            className="mb-2 text-white"
                            isDisabled={false}
                            color={primaryColor}
                            isLoading={isLoadingAccept || isLoadingReject}
                            size="xs"
                            isFullWidth
                            onClick={() =>
                              handleAcceptInvitation(invitation.id)
                            }
                            variant="solid"
                          >
                            {t(translations.COMMON.ACCEPT)}
                          </Button>
                          <Button
                            className="mb-2 ml-2 text-white"
                            variant="solid"
                            color="#C4C4C4"
                            isFullWidth
                            isLoading={isLoadingAccept || isLoadingReject}
                            onClick={() =>
                              handleRejectInvitation(invitation.id)
                            }
                            size="xs"
                          >
                            {t(translations.COMMON.REJECT)}
                          </Button>
                        </div>
                      </MenuItem>
                    );
                  })}
                {invitations.length === 0 && (
                  <MenuItem>
                    {t(translations.USER_DROPDOWN.INVITATION_EMPTY)}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </div>
        <div className="mr-3 hidden flex-row flex-wrap items-center md:flex lg:ml-auto">
          <div className="relative flex w-full flex-wrap items-stretch">
            {isLoggedIn && (
              <>
                <Avatar
                  alt="User profile"
                  src={
                    typeof avatar !== 'undefined'
                      ? avatar
                      : '/img/user_placeholder.png'
                  }
                  className="mr-2"
                />
                <p className="relative top-1">
                  {(user as any)?.name?.split(' ')[0] || (user as any)?.name}
                </p>
              </>
            )}
            {!isLoggedIn && (
              <a className="my-auto mr-6 cursor-pointer text-sm" href="/login">
                {t(translations.COMMON.LOGIN)}
              </a>
            )}
            {!isLoggedIn && (
              <Button
                className="mr-auto px-8 text-white"
                size="xs"
                onClick={(e) => {
                  e?.preventDefault();
                  window.location.href = '/signup';
                }}
                variant="solid"
                color={primaryColor}
              >
                {t(translations.COMMON.REGISTER)}
              </Button>
            )}
          </div>
        </div>
        {isLoggedIn && (
          <div className="dropdown relative">
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                    variant="transparent"
                    fontSize={25}
                    style={{ outline: 0, boxShadow: 'none !important' }}
                  />
                  <MenuList>
                    <MenuItem onClick={handleProfile}>
                      {t(translations.COMMON.PROFILE)}
                    </MenuItem>
                    <MenuItem onClick={handleOpenQuotePage}>
                      {t(translations.COMMON.QUOTE)}
                    </MenuItem>
                    <MenuItem as={Link} to="/about">
                      {t(translations.COMMON.ABOUT)}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      {t(translations.COMMON.LOGOUT)}
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        )}
      </div>
    </>
  );
};
