import React from "react";
import { ISVGProps } from "types";

export const MicrosoftIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 17 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.961 7.219H0V0h7.961v7.219Z" fill="#F1511B" />
    <path d="M16.751 7.219H8.79V0h7.961v7.219Z" fill="#80CC28" />
    <path d="M7.96 15.192H0V7.974h7.96v7.218Z" fill="#00ADEF" />
    <path d="M16.751 15.192H8.79V7.974h7.961v7.218Z" fill="#FBBC09" />
  </svg>
);
