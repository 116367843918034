import { clomp } from 'clomp';

export const Heading = clomp.p`
  font-bold
  mb-3
  text-lg
`;

export const Paragraph = clomp.p`
  line-clamp-4
  text-sm
`;
