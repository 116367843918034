import React from 'react';
import { Image } from '@chakra-ui/react';
import { Button, Chip } from '@design-system';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { IPropsOf } from 'types';

import { Base } from './components/Base';
import { ButtonContainer } from './components/ButtonContainer';
import { CategoryGrid } from './components/CategoryGrid';
import { Heading, Paragraph } from './components/Typography';
import { translations } from 'translations';
import { useTranslation } from 'react-i18next';

export interface IRewardBannerProps extends IPropsOf<'div'> {
  /**
   * Reward category listing.
   */
  categories?: string[];
  /**
   * Card's logo image.
   */
  logo: string;
  /**
   * Action handler when user clicks on the get reward button.
   */
  onClick: () => void;
  /**
   * Custom white label color.
   */
  primaryColor: string;
  /**
   * Card's summary.
   */
  summary: string;
  /**
   * Card's title.
   */
  title: string;
}

export const RewardBanner: React.FC<IRewardBannerProps> = (
  props,
): JSX.Element => {
  const { categories, logo, onClick, primaryColor, summary, title, ...rest } =
    props;

  const { t } = useTranslation();

  return (
    <Base {...rest}>
      <Image className="flex-1 object-contain" height={120} width={120} src={logo} />

      <div className="flex-[2]">
        <Heading>{title}</Heading>

        <Paragraph className="h-[70px]">{summary}</Paragraph>

        <ButtonContainer>
          <Button
            className="lg:w-52 text-white w-28"
            color={primaryColor}
            onClick={onClick}
            size="sm"
            variant="solid"
          >
            {t(translations.COMMON.OBTAIN)}
          </Button>
        </ButtonContainer>

        {!fp.isEmpty(categories) || !fp.isNil(categories) ? (
          <CategoryGrid>
            {categories?.map((item) => (
              <Chip key={nanoid()} color="#444444" size="xxs">
                {item}
              </Chip>
            ))}
          </CategoryGrid>
        ) : null}
      </div>
    </Base>
  );
};
