import { clomp } from 'clomp';
import React from 'react';
import cn from 'classnames';
import { IPropsOf } from 'types.d';

const Base = clomp.td`
  px-6
  py-4
  whitespace-nowrap

  first-of-type:rounded-l-xl
  last-of-type:rounded-r-xl
`;

export interface ITdProps extends IPropsOf<'td'> {
  /**
   * Specifies the number of columns a header cell should span.
   */
  colspan?: number;
  /**
   * If `true`, it will align items to the right.
   */
  isNumeric?: boolean;
  /**
   * Specifies the number of rows a header cell should span.
   */
  rowspan?: number;
}

export const Td: React.FC<ITdProps> = (props): JSX.Element => {
  const { children, className, isNumeric = false, ...rest } = props;

  return (
    <Base className={cn(className, { 'text-right': isNumeric })} {...rest}>
      {children}
    </Base>
  );
};
