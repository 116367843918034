import { clomp } from 'clomp';

import { IPropsOf } from 'types.d';

export type IFormLabelProps = IPropsOf<'label'>;

export const FormLabel = clomp.label`
  font-medium
  text-gray-300
`;

FormLabel.Required = clomp.label`
  absolute
  block
  cursor-text
  select-none
  text-gray-300
  transform
`;
