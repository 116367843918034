import { clomp } from 'clomp';

export const Base = clomp.button`
  delay-250
  inline-flex
  items-center
  justify-center
  relative
  rounded-full
  text-white
  transition-all
`;
