import React, { HTMLProps } from 'react'
import { useForm } from 'react-hook-form';
import { Button } from '@design-system';
import { InputField } from '@design-system/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { LoginFormValidationSchema } from './helpers';
import { Form } from './components/Form';
import { IBaseAuthentication } from '@cincel/client';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface ILoginFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * On submit action handler.
   */
  onSubmit: (v: IBaseAuthentication) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const LoginForm: React.FC<ILoginFormProps> = (props): JSX.Element => {
  const { className, onSubmit, primaryColor, ...rest } = props;

  const { control, formState, handleSubmit } = useForm<IBaseAuthentication>({
    defaultValues: { email: '', password: '' },
    mode: 'all',
    resolver: yupResolver(LoginFormValidationSchema),
  });

  const { t } = useTranslation();

  return (
    <Form className={className} onSubmit={handleSubmit(onSubmit)} {...rest}>
      <InputField
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="email"
        placeholder={t(translations.INPUTS.EMAIL)}
        size="md"
        primaryColor={primaryColor}
        type="email"
      />

      <InputField
        className="mb-3 w-full md:w-[300px]"
        control={control}
        name="password"
        placeholder={t(translations.INPUTS.PASSWORD)}
        size="md"
        primaryColor={primaryColor}
        type="password"
      />

      <Link
        className="mb-4 text-center text-xs font-medium text-gray-300 hover:font-bold"
        to="/resetPassword"
      >
        {t(translations.AUTH_PAGES.LOGIN_FORGOT_PASSWORD)}
      </Link>

      <Button
        className="w-full md:w-[250px]"
        color={primaryColor}
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        size="sm"
        type="submit"
      >
        {t(translations.COMMON.LOGIN)}
      </Button>
    </Form>
  );
};
