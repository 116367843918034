import { css } from '@emotion/css';

/**
 * Enum for chip sizes.
 * @readonly
 * @enum {string}
 */
export enum ChipSize {
  xxs = 'py-1 text-[10px]',
  xs = 'py-1 text-xs',
  sm = 'py-1 text-sm',
  md = 'py-2 text-md',
  lg = 'py-2 text-lg',
  xl = 'py-2 text-xl',
}

/**
 * Calculates the border and text color.
 * @param {string} color - hex., chip's primary color.
 * @param {string} textColor - hex., alternative text color.
 * @returns {string} - custom css class.
 */
export const getDynamicCSS = (color: string, textColor?: string): string => css`
  border-color: ${color};
  color: ${textColor || color};
`;
