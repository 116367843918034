import React from 'react';
import { Flex, InfoIcon } from "@design-system";
import cn from 'classnames';
import fp from 'lodash/fp';

export interface IFormHelperTextProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
}

export const FormHelperText: React.FC<IFormHelperTextProps> = (
  props
): JSX.Element | null => {
  const { children, className } = props;

  if (fp.isEmpty(children) || fp.isNil(children)) return null;

  return (
    <Flex className={cn("items-center", className)}>
      <InfoIcon color="#C4C4C4" height={12} width={12} />
      <span className="font-medium ml-2 text-gray-200 text-xs">
        {children}
      </span>
    </Flex>
  );
}
