import * as Yup from 'yup';
import { ISignupPayload } from '@cincel/client';
import { translations } from 'translations';

export interface ISignupFormValuesProps extends ISignupPayload {
  /**
   * Confirm password field, the value of this field should match with the
   * password entered on the form.
   */
  confirm_password: string;
}

export const SignupFormValidationSchema = Yup.object().shape({
  confirm_password: Yup.string()
    .required(translations.ERRORS.CONFIRM_PASSWORD)
    .oneOf([Yup.ref('password')], translations.ERRORS.PASSWORDS_DONT_MATCH),
  email: Yup.string()
    .email(translations.ERRORS.VALID_EMAIL)
    .required(translations.ERRORS.REQUIRED_EMAIL)
    .min(6, translations.ERRORS.VALID_EMAIL_CHARACTERS),
  name: Yup.string().required(translations.ERRORS.REQUIRED_NAME),
  password: Yup.string()
    .required(translations.ERRORS.REQUIRED_PASSWORD)
    .min(6, translations.ERRORS.PASSWORD_LENGTH),
});

export const DEFAULT_VALUES = {
  confirm_password: '',
  email: '',
  name: '',
  organization_invitation_token: null,
  password: '',
  rfc: '',
};
