import { css } from '@emotion/css';

/**
 * Calculates the border color on hover events and determinates the
 * card's `min-height`.
 * @param {string} color - hex., theme color.
 * @returns {string} - custom css class.
 */
export const getDynamicCSS = (color: string): string => css`
  min-height: 320px;

  &:hover {
    border-color: ${color};
    transform: scale(1.01);
  }
`;
