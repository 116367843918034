import React, { useContext } from 'react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { DotsIcon } from '@design-system';
import { WLContext } from '@contexts';
import { useTranslation } from 'react-i18next';

export type MoreButtonProps = {
  className?: string;
  items: Array<{
    label: string;
    value: string | number;
  }>;
  onClick: any;
};
export const MoreButton: React.FC<MoreButtonProps> = ({
  className = '',
  items = [],
  onClick,
}) => {
  const { t } = useTranslation();
  const { color } = useContext(WLContext);

  const handleClick = (value) => {
    if (onClick) onClick(value);
  };

  return (
    <div className={className}>
      <Menu>
        <MenuButton>
          <div className="cursor-pointer">
            <DotsIcon color={color as string} height={20} width={4} />
          </div>
        </MenuButton>
        <MenuList backgroundColor="#E6e6e6">
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleClick((item as any).value)}>
                {t((item as any).label)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};
