import Color from 'color';

/**
 * Enum for button sizes.
 * @readonly
 * @enum {string}
 */
export enum ButtonSize {
  xs = 'min-h-[32px] min-w-[32px] px-4 text-xs',
  sm = 'min-h-[40px] min-w-[40px] px-4 text-sm',
  md = 'min-h-[48px] min-w-[48px] px-4 text-md',
  lg = 'min-h-[56px] min-w-[56px] px-4 text-lg',
  xl = 'min-h-[64px] min-w-[64px] px-4 text-xl',
}

/**
 * Enum for icon button sizes.
 * @readonly
 * @enum {string}
 */
export enum IconButtonSize {
  xs = 'h-6 w-6',
  sm = 'h-8 w-8',
  md = 'h-10 w-10',
  lg = 'h-12 w-12',
  xl = 'h-14 w-14',
}

/**
 * Enum for button and icon button variants.
 * @readonly
 * @enum {string}
 */
export enum ButtonVariant {
  primary = 'primary',
  solid = 'solid',
  outline = 'outline',
  ghost = 'ghost',
  link = 'link',
}

/**
 * Enum for button and icon button types.
 * @readonly
 * @enum {string}
 */
export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

/**
 * Calculates the styles that will be consumed by `classnames` based on the
 * `isDisabled` and `isFullWidth` properties.
 * @see {@link https://www.npmjs.com/package/classnames}.
 * @param {boolean} isDisabled - if `true`, the button will be disabled.
 * @param {boolean} isFullWidth - if `true`, the button will take up the full
 * width of its container.
 * @returns {{ [i: string]: boolean }} - classnames object.
 */
export const getPropsCSS = (
  isDisabled: boolean,
  isFullWidth = false,
): { [i: string]: boolean } => ({
  'cursor-not-allowed opacity-50 hover:scale-100': isDisabled,
  'w-full': isFullWidth,
});

/**
 * Calculates the primary button dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @returns {{ [i: string]: any }} - primary variant styles.
 */
export const getPrimaryCustomCSS = (color: string): { [i: string]: any } => ({
  background: 'black',
  boxShadow: `-5px 5px 0px ${color}`,
  color: 'white',

  '&:active': {
    color: 'white !important',
    boxShadow: `-5px 5px 0px ${color} !important`,
  },

  '&:hover': {
    color: Color('#FFF').darken(0.1).hex(),
    boxShadow: `-5px 5px 0px ${Color(color).darken(0.2).hex()}`,
  },
});

/**
 * Calculates the solid button dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @returns {{ [i: string]: any }} - solid variant styles.
 */
export const getSolidCustomCSS = (color: string): { [i: string]: any } => ({
  background: color,

  '&:active': {
    background: `${Color(color).darken(0.5).hex()} !important`,
  },

  '&:hover': {
    background: Color(color).darken(0.3).hex(),
  },
});

/**
 * Calculates the outline button dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @returns {{ [i: string]: any }} - outline variant styles.
 */
export const getOutlineCustomCSS = (color: string): { [i: string]: any } => ({
  borderColor: color,
  borderWidth: '2px',
  color,

  '&:active': {
    background: `${Color(color).alpha(0.2).hsl().string()} !important`,
  },

  '&:hover': {
    background: Color(color).alpha(0.1).hsl().string(),
  },
});

/**
 * Calculates the ghost button dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @returns {{ [i: string]: any }} - ghost variant styles.
 */
export const getGhostCustomCSS = (
  color: string,
  selected = false,
): { [i: string]: any } => ({
  ...(selected
    ? {
        background: color,
        color: 'white',
      }
    : {
        color,
      }),
  '&:active': {
    background: `${Color(color).darken(0.2).hex()} !important`,
  },

  '&:hover': {
    background: color,
    color: 'white',
  },
});

/**
 * Calculates the link button dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @returns {{ [i: string]: any }} - link variant styles.
 */
export const getLinkCustomCSS = (color: string): { [i: string]: any } => ({
  color,
  height: 'auto',
  minHeight: 'auto',
  minWidth: 'auto',
  padding: 0,
  width: 'auto',

  '&:active': {
    color: `${color} !important`,
  },

  '&:hover': {
    color: Color(color).darken(0.4).hex(),
  },
});

/**
 * Function that generates dynamic css that will be consumed by the `css` prop
 * in the `Button` component based on the `color` and `variant` paramenters.
 * @see {@link https://emotion.sh/docs/css-prop}
 * @param {string} color - hex white label color.
 * @param {string} variant - button variant.
 * @returns {{ [i: string]: any }} - dynamic styles.
 */
export const getCustomCSS = (
  color: string,
  variant: string,
  selected?: boolean,
): { [i: string]: any } => {
  if (variant === ButtonVariant.link) return getLinkCustomCSS(color);
  if (variant === ButtonVariant.ghost)
    return getGhostCustomCSS(color, selected);
  if (variant === ButtonVariant.outline) return getOutlineCustomCSS(color);
  if (variant === ButtonVariant.solid) return getSolidCustomCSS(color);
  return getPrimaryCustomCSS(color);
};
