import React from 'react';
import { Button } from '@design-system';
import { translations } from 'translations';
import { useTranslation } from 'react-i18next';

const Member = ({
  id,
  role,
  email,
  name,
  pending,
  handleDeleteInvitation,
  handleDeleteUser,
  primaryColor,
}) => {
  const { t } = useTranslation();

  const parseRole = (role) => {
    if (role === 3) return 'Regular';
    if (role === 2) return t(translations.COMMON.ADMIN);
  };

  return (
    <div>
      <div className="text-left text-paragraph font-bold text-gray-500"></div>
      <div className="flex flex-col">
        <div className="flex">
          <div className="flex">
            <div className="flex flex-col">
              <span>{name}</span>
              <span>{email}</span>
            </div>

            {role && (
              <div className="ml-auto flex">
                <div
                  className={
                    'relative  top-2 mr-2 h-2 w-2 rounded-full border bg-primary-300 text-paragraph '
                  }
                />
                <span>{parseRole(role)}</span>
              </div>
            )}
          </div>
        </div>
        <div>{pending ? 'Invitación pendiente' : ''}</div>
        {pending && (
          <Button
            className="mr-auto mt-1 px-8 text-white"
            size="xs"
            onClick={() => handleDeleteInvitation(id)}
            color={primaryColor}
            variant="solid">
            {t(translations.COMMON.DELETE)}
          </Button>
        )}
        {!pending && (
          <Button
            className="mr-auto mt-1 px-8 text-white"
            size="xs"
            onClick={() => handleDeleteUser(id)}
            color={primaryColor}
            variant="solid">
            {t(translations.COMMON.DELETE)}
          </Button>
        )}
      </div>
    </div>
  );
};

interface OrganizationMembersProps {
  onClick?: () => void;
  primaryColor: string;
  members: Array<any>;
  onClickNewMember: any;
  handleDeleteInvitation?: any;
  handleDeleteUser?: any;
}

export const OrganizationMembers: React.FC<OrganizationMembersProps> = ({
  members = [],
  primaryColor,
  onClickNewMember,
  handleDeleteInvitation,
  handleDeleteUser,
}) => {
  const handleNewMember = () => {
    onClickNewMember();
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="shadow-lg relative mb-6 flex w-full min-w-0 flex-col break-words">
            <div className="flex-auto">
              <div className="tab-space tab-content">
                {/* TAB ONE */}
                <div className={'block'} id="link1">
                  {members.map((member, index) => (
                    <div key={index}>
                      <div className="py-2">
                        <Member
                          id={member.id}
                          name={member.name}
                          key={index}
                          email={member.email}
                          role={member.role}
                          pending={member.pending}
                          handleDeleteInvitation={handleDeleteInvitation}
                          handleDeleteUser={handleDeleteUser}
                          primaryColor={primaryColor}
                        />
                        <hr className="my-4 border border-dashed border-gray-100 md:min-w-full" />
                        <div className="flex"></div>
                      </div>
                    </div>
                  ))}
                  <div className="flex">
                    <button
                      className="mx-auto h-12 w-12 rounded-full text-basic-white focus:outline-none"
                      onClick={() => handleNewMember()}
                      style={{ background: primaryColor }}>
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
