export var IInviteRoles;
(function (IInviteRoles) {
    IInviteRoles["ADMIN"] = "admin";
    IInviteRoles["EDITOR"] = "editor";
    IInviteRoles["LECTOR"] = "lector";
})(IInviteRoles || (IInviteRoles = {}));
export var IInviteType;
(function (IInviteType) {
    IInviteType["OBSERVER"] = "observer";
    IInviteType["SIGNER"] = "signer";
})(IInviteType || (IInviteType = {}));
export var IDocumentStatus;
(function (IDocumentStatus) {
    IDocumentStatus["SIGNED"] = "signed";
    IDocumentStatus["PARTIALLY_SIGNED"] = "partially_signed";
    IDocumentStatus["UNSIGNED"] = "unsigned";
})(IDocumentStatus || (IDocumentStatus = {}));
export var IInviteStatus;
(function (IInviteStatus) {
    IInviteStatus["IDLE"] = "idle";
    IInviteStatus["SENT"] = "sent";
    IInviteStatus["OPENED"] = "opened";
    IInviteStatus["COMPLETED"] = "completed";
})(IInviteStatus || (IInviteStatus = {}));
