import * as Yup from 'yup';
import { translations } from 'translations';

export interface ISignFieldFormValuesProps {
  /**
   * Field name of the form. This should be required.
   */
  fieldName: string;
  /**
   * Field Flags `['Required', 'Read Only', 'Multiline']`
   */
  fieldFlags: string[];
}

export const SignFieldFormValidationSchema = Yup.object().shape({
  fieldName: Yup.string()
    .required(translations.ERRORS.REQUIRED_NAME)
    .max(50, translations.ERRORS.MAX_LENGTH_50),
  fieldFlags: Yup.array().of(Yup.string()),
});

export const DEFAULT_VALUES = {
  fieldName: '',
  fieldFlags: [],
};
