/** @jsx jsx */
import React from 'react';
import { MoreButton } from '@design-system';
import { useRouter } from 'hooks/useRouter';
import { jsx } from '@emotion/react';
import { translations } from 'translations';

const links = [
  {
    label: translations.COMMON.EDIT,
    value: 'edit',
  },
  {
    label: translations.COMMON.DELETE,
    value: 'delete',
  },
];

export const Folder = ({
  borderColor,
  folder,
  showMore = false,
  name,
  onFolderMenuClick = (_val: any, _id: any) => {},
}) => {
  const router = useRouter();
  const goToFolder = () => {
    router.history.push({
      pathname: `/folders/${folder.id}`,
      state: {
        ...folder,
      },
    });
  };

  return (
    <div className="">
      <div
        className="flex h-24 w-full cursor-pointer items-center justify-center rounded-lg border border-gray-200 bg-basic-white p-4"
        css={{ '&:hover': { borderColor } }}
        onClick={goToFolder}>
        <img className="h-8 w-10" src="/img/folder.svg" alt="Folder Icon" />
      </div>
      <div className="flex">
        <p
          className="m-auto mt-2 w-11/12 cursor-pointer overflow-ellipsis text-center text-paragraph-sm"
          onClick={goToFolder}>
          {name}
        </p>
        {showMore && (
          <MoreButton
            className="relative top-2 mr-2 ml-auto w-1/12"
            items={links as any}
            onClick={(value) => onFolderMenuClick(value, folder)}
          />
        )}
      </div>
    </div>
  );
};
