import { Dispatch, useCallback } from 'react';
import { IActionEnum } from '../helpers';
import { useClient } from 'hooks/useClient';
import { tokenV3Name } from 'config/constants';
import fp from 'lodash/fp';

/**
 * Async function that will request to the API the account's settings.
 */
export type IUseFetchSettingsFn = ((id: number | string) => Promise<void>)[];

/**
 * Creates a custom hook that fetch and store the domain, account or default
 * settings for the current user.
 * @param {Dispatch<any>} dispatch - reducer dispatch method.
 */
export const useFetchSettings = (dispatch: Dispatch<any>): IUseFetchSettingsFn => {
  const client = useClient({
    url: process.env.REACT_APP_API_V3,
    token: localStorage.getItem(tokenV3Name) as string,
  });

  /**
   * Fetches the global settings.
   */
  const fetchSettings = useCallback(async (id: number | string) => {
    try {
      dispatch({ type: IActionEnum.FETCH_SETTINGS });

      const r1 = await client.getSettings();

      const r2 = await client.getRewardsAvailability(id);

      const isRewardsAvailable = fp.compose(
        (v: string) => JSON.parse(v),
        fp.get('value'),
        fp.find({ key: 'rewards' }),
        fp.get('data'),
      )(r1);

      const isUserRewardsAvailable = fp.get('rewards_availability')(r2);

      dispatch({ type: IActionEnum.FETCH_SETTINGS_SUCCESS, isRewardsAvailable, isUserRewardsAvailable });
    } catch (e) {
      dispatch({
        type: IActionEnum.FETCH_SETTINGS_FAILURE,
        fallback: 'isRewardsAvailable',
      });
    }
  }, [client, dispatch]);

  /**
   * Fetches the user settings.
   */
  const fetchUserSettings = useCallback(async (id: number | string) => {
    try {
      dispatch({ type: IActionEnum.FETCH_SETTINGS });

      const response = await client.getRewardsAvailability(id);

      const value = fp.get('rewards_availability')(response);

      dispatch({ type: IActionEnum.FETCH_SETTINGS_SUCCESS, isUserRewardsAvailable: value, isRewardsAvailable: value });
    } catch (e) {
      dispatch({ type: IActionEnum.FETCH_SETTINGS_FAILURE, fallback: 'isUserRewardsAvailable' });
    }
  }, [client, dispatch]);

  const fn = useCallback(async (id: number | string) => {
    dispatch({ type: IActionEnum.FETCH_SETTINGS_INIT });
    await fetchSettings(id)
    dispatch({ type: IActionEnum.FETCH_SETTINGS_FINISH });
  }, [dispatch, fetchSettings]);

  return [fn, fetchUserSettings];
};
