import React from 'react';
import cn from 'classnames';
import { ChevronDownIcon } from '@design-system';
import ReactSelect, { components } from 'react-select';

const colourStyles = (colorWL: string, height = 44) => ({
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      borderRadius: 25,
      height,
      borderColor: '#E9E9E9',
      borderWidth: 2,
      boxShadow: 'none',
      width: '100%',
      paddingLeft: '0.5rem',
      '&:hover': {
        borderColor: colorWL,
      },
      '&:active': {
        ...styles[':active'],
        borderColor: colorWL,
      },
    };
  },
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: '#fff',
      color: '#000',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':hover': {
        backgroundColor: '#e5e5e5',
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: '#fff',
        borderColor: colorWL,
      },
    };
  },
  input: (styles) => {
    return {
      ...styles,
      margin: '0 0 0 -2px',
    };
  },
  placeholder: (styles) => ({
    ...styles,
    '&:after': {
      position: 'relative',
      top: -3,
      left: 4,
      display: 'inline-block',
      content: '"*"',
      color: '#d0011b',
    },
    color: '#888888',
    margin: '0 0 0 -2px',
  }),
  singleValue: (styles) => ({ ...styles, margin: '0 0 0 -2px' }),
});

const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList {...props}>
      {
        Array.isArray(children)
          ? children.slice(0, 10) // Options
          : children // NoOptionsLabel
      }
    </components.MenuList>
  );
};

export const Select: React.FC<any> = ({
  colorWL,
  options,
  placeholder = 'Seleccionar documento',
  onChange,
  defaultValue,
  required = false,
  value,
  height,
  customOnChange,
  ...field
}) => {
  const handleChange = (e) => {
    if (onChange) onChange(e.value);
    if (customOnChange) customOnChange(e.value);
  };

  return (
    <ReactSelect
      {...field}
      options={options}
      defaultValue={defaultValue}
      onChange={handleChange}
      styles={colourStyles(colorWL, height)}
      placeholder={placeholder}
      value={value}
      required={required}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => (
          <div className="mr-2">
            <ChevronDownIcon color={'#8E8A8A'} width={14} height={8} />
          </div>
        ),
        MenuList,
      }}
    />
  );
};
