import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes } from './routes';
import { ChakraProvider } from '@chakra-ui/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Global, css } from '@emotion/react';
import { WLProvider } from '@providers';

import withClearCache from './ClearCache';
import * as Sentry from '@sentry/react';
import Trackers from './Trackers';
// import "@cincel.digital/react/";
// import i18n (needs to be bundled ;))
import './i18n';
import { Spinner } from './components';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

declare global {
  interface Window {
    Core: any;
    PDFNet: any;
  }
}

window.Core = window.Core || {};
window.PDFNet = window.PDFNet || {};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID;

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  return (
    <>
      <Trackers>
        <ChakraProvider>
          <Global styles={GlobalStyles} />
          <Auth0Provider
            domain={AUTH0_DOMAIN as string}
            clientId={AUTH0_CLIENTID as string}
            redirectUri={window.location.origin}>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<Spinner />}>
                <WLProvider>
                  <Router>{renderRoutes()}</Router>
                </WLProvider>
              </Suspense>
            </QueryClientProvider>
          </Auth0Provider>
        </ChakraProvider>
      </Trackers>
    </>
  );
}

export default Sentry.withProfiler(App);
