import React from "react";
import { ISVGProps } from "types";

export const ShareIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 18"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#share-icon_svg__a)" fill="currentColor">
      <path d="M20.938 7.396a4.428 4.428 0 0 0 1.135-2.948A4.452 4.452 0 0 0 17.628.003a4.398 4.398 0 0 0-3.765 2.11c-.136-.022-.272-.022-.386-.022a4.452 4.452 0 0 0-4.445 4.445c0 1.135.43 2.156 1.135 2.948-1.791.77-3.063 2.564-3.063 4.627v2.676c0 .318.25.566.567.566h11.59a.56.56 0 0 0 .566-.566v-1.519h3.607a.56.56 0 0 0 .566-.566v-2.676c0-2.09-1.271-3.86-3.062-4.63Zm1.927 6.737h-3.04v-.022c0-2.063-1.272-3.857-3.063-4.627.158-.183.319-.387.455-.613.136.022.272.022.386.022a4.37 4.37 0 0 0 2.292-.659A3.888 3.888 0 0 1 22.843 12v2.131h.022v.003ZM8.212 16.196v-2.11a3.866 3.866 0 0 1 2.948-3.764c.68.408 1.451.659 2.292.659a4.37 4.37 0 0 0 2.292-.659 3.888 3.888 0 0 1 2.948 3.765v2.11H8.212Zm1.928-9.685A3.317 3.317 0 0 1 13.451 3.2a3.317 3.317 0 0 1 3.313 3.312 3.33 3.33 0 0 1-3.313 3.313c-1.837.022-3.313-1.475-3.313-3.313Zm10.798-2.066a3.325 3.325 0 0 1-3.199 3.313 4.3 4.3 0 0 0 .183-1.225 4.453 4.453 0 0 0-2.858-4.151 3.341 3.341 0 0 1 2.583-1.247c1.794 0 3.29 1.473 3.29 3.31ZM7.554 6.691a.56.56 0 0 0-.567-.566H4.355V3.493a.56.56 0 0 0-.566-.566.562.562 0 0 0-.566.566v2.654H.566A.563.563 0 0 0 0 6.716c0 .318.25.566.566.566H3.22v2.654c0 .318.25.566.566.566a.563.563 0 0 0 .57-.566V7.26h2.653c.294 0 .545-.25.545-.569Z" />
    </g>
    <defs>
      <clipPath id="share-icon_svg__a">
        <path d="M0 0h24v17.353H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
