import React from "react";
import { ISVGProps } from "types";

export const CreateIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 24 23"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#create-icon_svg__a)"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path d="M17.784 14.52a.775.775 0 0 0 0-1.072L13.311 8.82l-.062-.08a.736.736 0 0 0-.46-.199h-.092a.674.674 0 0 0-.382.165l-.147.13-4.453 4.612a.775.775 0 0 0 0 1.071.718.718 0 0 0 1.014.018l.018-.018L12 11.156v10.567a.754.754 0 0 0 .704.795.755.755 0 0 0 .795-.792v-10.57l3.25 3.355c.274.29.725.3 1.015.029l.02-.02Z" />
      <path d="M18.391 4.83C17.506 2.03 14.997 0 12 0 8.74 0 6.02 2.39 5.39 5.57 2.313 6.226 0 9.04 0 12.413c0 3.856 3.023 6.982 6.75 6.982h1.918a.775.775 0 0 0 0-1.549H6.75c-2.899 0-5.248-2.431-5.248-5.42 0-2.99 2.347-5.42 5.245-5.42 0-3 2.352-5.421 5.25-5.421 2.651 0 4.822 2.042 5.163 4.685 2.981.134 5.338 2.656 5.338 5.753a5.717 5.717 0 0 1-5.248 5.78l-.397.02a.712.712 0 0 0-.775.79c0 .428.346.774.775.774.258.006.518-.007.774-.04C21.207 18.963 24 15.83 24 12.022a7.318 7.318 0 0 0-5.609-7.194Z" />
    </g>
    <defs>
      <clipPath id="create-icon_svg__a">
        <path d="M0 0h24v22.518H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
