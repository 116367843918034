import React from 'react';
import { UserDropdown, Logo, LogoType } from '@design-system';

export const NavBar = ({
  logo,
  handleProfile,
  handleLogout,
  setIsOpen,
  primaryColor,
}) => {
  return (
    <>
      {/* Navbar Desktop */}
      <header className="bg-transparent fixed right-0 z-10 hidden items-center pr-0 lg:flex lg:flex-row lg:flex-nowrap lg:justify-start">
        <div className="mx-auto flex w-full flex-wrap items-center justify-between">
          {/* Form */}
          <div className="ml-auto flex h-16 flex-row flex-wrap items-center rounded-bl-2xl border-2 border-gray-100 bg-basic-white px-2 pl-4">
            <div className="relative flex w-full flex-wrap items-stretch">
              <ul className="flex list-none flex-col items-center">
                <UserDropdown
                  handleProfile={handleProfile}
                  handleLogout={handleLogout}
                  primaryColor={primaryColor}
                />
              </ul>
            </div>
          </div>
          {/* User */}
        </div>
      </header>
      {/* Navbar Mobile */}
      <header className="fixed z-10 flex w-full items-center justify-between border-b-4 bg-black px-6 py-4 lg:hidden">
        <div className="relative w-full">
          <button
            onClick={() => setIsOpen(true)}
            className="text-white focus:outline-none lg:hidden"
          >
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6H20M4 12H20M4 18H11"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            {/* Brand */}
            <Logo wLogo={logo} type={LogoType.white} width={'125px'} />
          </div>
        </div>
      </header>
    </>
  );
};
