import { clomp } from 'clomp';

export const Base = clomp.input`
  border-2
  border-gray-100
  bg-white
  px-4
  py-2
  outline-none
  placeholder-primary
  w-full

  focus:outline-none
`;
