import { css } from '@emotion/css';

/**
 * Custom CSS for a required input. This class will control input's `label`.
 */
const LABEL_REQUIRED_CLASS: string = css`
  &::after {
    color: #d0011b;
    content: '*';
    display: inline-block;
    left: 0.25rem;
    position: relative;
    top: -3px;
  }
`;

/**
 * Label classes for the required input.
 */
export const REQUIRED_LABEL_CSS = `${LABEL_REQUIRED_CLASS} left-[18px] top-[12px]`;
