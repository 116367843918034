import loadable from '@loadable/component';

const LoginPage = loadable(() => {
  return import('@pages/AuthPages/pages/LoginPage');
});

const SignupPage = loadable(() => {
  return import('@pages/AuthPages/pages/SignupPage');
});

const ConfirmAccountPage = loadable(() => {
  return import('@pages/AuthPages/pages/ConfirmAccountPage');
});

const RecoveryPasswordPage = loadable(() => {
  return import('@pages/AuthPages/pages/RecoveryPasswordPage');
});

const ResetPasswordPage = loadable(() => {
  return import('@pages/AuthPages/pages/ResetPasswordPage');
});

const ResetPasswordSuccessPage = loadable(() => {
  return import('@pages/AuthPages/pages/ResetPasswordSuccessPage');
});

const Redirect = loadable(() => {
  return import ('./redirect');
})

export const ROUTES = [
  {
    element: LoginPage,
    path: '/login',
  },
  {
    element: SignupPage,
    path: '/signup',
  },
  {
    element: ConfirmAccountPage,
    path: '/confirmation',
  },
  {
    element: ResetPasswordPage,
    path: '/resetPassword',
  },
  {
    element: RecoveryPasswordPage,
    path: '/recoveryPassword',
  },
  {
    element: ResetPasswordSuccessPage,
    path: '/resetPasswordSuccess',
  },
  {
    element: Redirect,
    path: '/cincel/register/'
  }
];
