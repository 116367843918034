import { Dispatch, useCallback } from 'react';
import { IActionEnum, IAssetNameEnum } from '../helpers';
import { useClient } from 'hooks/useClient';
import { tokenV3Name } from 'config/constants';
import fp from 'lodash/fp';

/**
 * Async function that will request to the API the account's white label info.
 * @param {null | number | string} id - logged user or org owner.
 */
export type IUseFetchWLFn = (id: null | number | string) => Promise<void>

/**
 * Creates a custom hook that fetch and store the domain, account or default
 * assets for the current user.
 * @param {Dispatch<any>} dispatch - reducer dispatch method.
 * @returns {IUseFetchWLFn[]} - an array of fetch methods, the first element is
 * a hard fetch that will reload and make the application wait until the
 * process finish, the second element is a soft fetch that will avoid reloding
 * the entire app.
 */
export const useFetchWL = (dispatch: Dispatch<any>): IUseFetchWLFn[] => {
  const client = useClient({
    url: process.env.REACT_APP_API_V3,
    token: localStorage.getItem(tokenV3Name) as string,
  });

  /**
   * Fetches the user's account assets first, in case of failure it will look
   * for the global assets.
   * @param {null | number | string} id - user id which the assets belong.
   * @param {string} path - asset URL path name.
   * @param {string} name - asset friendly name.
   * @param {boolean} isImage - if `true` will change the type of the response to `blob`.
   */
  const fetchAsset = useCallback(async (
    id: null | number | string,
    path: string,
    name: string,
    isImage = false
  ) => {
    try {
      const config = isImage ? { responseType: 'blob' } : undefined;

      dispatch({ type: IActionEnum.FETCH_ASSET });

      const r = fp.isNil(id) ?
        await client.getGlobalAssets(path, config) :
        await client.getAssets(id, path, config);

      const asset = isImage ?
        new File([r], `${name}.png`, { type: 'image/png' }) :
        r;
      dispatch({ [name]: asset, type: IActionEnum.FETCH_ASSET_SUCCESS });

    } catch (err) {
      if (!fp.isNil(id)) fetchAsset(null, path, name, isImage);
      const fallback = fp.isNil(id) ? { fallback: name } : {};
      dispatch({ ...fallback, type: IActionEnum.FETCH_ASSET_FAILURE });
    }
  }, [client, dispatch]);

  const softFn = useCallback(async (id: null | number | string) => {
    await fetchAsset(id, IAssetNameEnum.COLOR, 'color');
    await fetchAsset(id, IAssetNameEnum.LOGO, 'logo', true);
    await fetchAsset(id, IAssetNameEnum.LIGHT_LOGO, 'lightLogo', true);
  }, [fetchAsset]);

  const fn = useCallback(async (id: null | number | string) => {
    dispatch({ type: IActionEnum.FETCH_WL_INIT });
    await softFn(id)
    dispatch({ type: IActionEnum.FETCH_WL_FINISH });
  }, [dispatch, softFn]);

  return [fn, softFn];
};
