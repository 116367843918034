import { clomp } from 'clomp';
import React from 'react';

import { IPropsOf } from 'types.d';

const Base = clomp.thead`
  sticky
  top-0
`;

export type ITheadProps = IPropsOf<'thead'>;

export const Thead: React.FC<ITheadProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return <Base {...rest}>{children}</Base>;
};
