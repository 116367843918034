/** @jsx jsx */
import React from 'react';
import { Avatar, AvatarSize, Button } from '@design-system';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export const Organization = ({
  primaryColor,
  id = 0,
  name,
  icon,
  canEdit = true,
  translation = false,
  onClick = (_id) => {},
  onEditClick = (_id) => {},
  onActionClick = (_id) => {},
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onClick(id);
  };

  const handleEditClick = () => {
    onEditClick(id);
  };

  return (
    <div
      onClick={handleClick}
      className="flex w-full cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-basic-white p-4"
      css={{ '&:hover': { primaryColor } }}>
      <div className="flex flex-1 items-center">
        <Avatar
          className="border border-gray-300"
          alt="User profile"
          src={icon}
          size={AvatarSize.sm}
        />
        <p className="ml-4 text-paragraph">{translation ? t(name) : name}</p>
      </div>

      {canEdit && (
        <Button
          onClick={handleEditClick}
          className="mx-4"
          color={primaryColor}
          size="sm"
          variant="outline">
          {t(translations.COMMON.EDIT)}
        </Button>
      )}
      <img
        className="h-5 w-2 cursor-pointer text-primary-300"
        src="/img/chevron-right.svg"
        alt="Chevron right"
        onClick={() => onActionClick(id)}
      />
    </div>
  );
};
