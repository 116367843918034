var DEFAULT_PLATFORM = 'CINCEL';
var DEFAULT_VERSION = '1.0';
export function getHeaders() {
    // const userAgent = window.navigator.userAgent;
    // const info = platform.parse(userAgent);
    // return {
    //   device: info.name ?? 'Unknown',
    //   os: info.os?.toString() ?? '',
    //   platform: DEFAULT_PLATFORM,
    //   version: DEFAULT_VERSION,
    // };
    return {};
}
