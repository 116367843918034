import * as Yup from 'yup';
import fp from 'lodash/fp';
import { translations } from 'translations';

const isValidFileSize = fp.curry(
  (size, FILE_SIZE = 516000) => (size as number) <= FILE_SIZE,
);

const isValidFileFormat = fp.curry(
  (value, formats = ['image/png']) => fp.includes(value)(formats),
);

export const ValidationSchema = Yup.object().shape({
  company: Yup.string()
    .max(50, translations.ERRORS.MAX_LENGTH_50)
    .required(translations.ERRORS.REQUIRED),

  categories: Yup.array()
    .max(3, translations.ERRORS.MAX_ITEMS_ALLOWED_3)
    .min(1, translations.ERRORS.AT_LEAST_CHOOSE_ONE_ITEM)
    .of(Yup.string()),

  benefits: Yup.string()
    .max(100, translations.ERRORS.MAX_LENGTH_100)
    .required(translations.ERRORS.REQUIRED),

  url: Yup.string()
    .url(translations.ERRORS.VALID_URL)
    .required(translations.ERRORS.REQUIRED),

  country: Yup.string().required(translations.ERRORS.REQUIRED),

  coupon: Yup.string()
    .nullable(true)
    .max(100, translations.ERRORS.MAX_LENGTH_100)
    .notRequired(),

  priority: Yup.string()
    .matches(/[1-9]+/, translations.ERRORS.ONLY_POSITIVE_NUMBERS)
    .required(translations.ERRORS.REQUIRED),

  company_summary: Yup.string()
    .max(140, translations.ERRORS.MAX_LENGTH_144)
    .required(translations.ERRORS.REQUIRED),

  detail: Yup.string()
    .max(1000, translations.ERRORS.MAX_LENGTH_1000)
    .required(translations.ERRORS.REQUIRED),

  company_logo: Yup.mixed()
    .test('file-size-logo', translations.ERRORS.MAX_FILE_SIZE, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format-logo', translations.ERRORS.ONLY_PNG_FILES, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat(v.type);
    })
    .required(translations.ERRORS.REQUIRED),

  image: Yup.mixed()
    .test('file-size-cover', translations.ERRORS.MAX_FILE_SIZE, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format-cover', translations.ERRORS.ONLY_PNG_FILES, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat(v.type);
    })
    .required(translations.ERRORS.REQUIRED),
});
