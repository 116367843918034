import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import { Control, useController } from 'react-hook-form';

import { Select } from '../BaseInputs';
import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelFontSize,
  Stack
} from '@design-system';

import { ISelectFieldBase } from './typings';
import { useTranslation } from 'react-i18next';

export interface ISelectFieldProps extends ISelectFieldBase {
  /**
   * RHF `control` object provided by invoking useForm. This prop is optional
   * when using FormProvider.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any, any>;
  /**
   * Field helper text that will be displayed below the input.
   */
  helper?: string;
  /**
   * Field label, can be a raw text or an i18n key.
   */
  label?: string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Field name that RHF will use to identify the input on the HTML code.
   */
  name: string;
  /**
   * Custom CSS tailwind styles for the select tag.
   */
  selectClassName?: string;
}

export const SelectField: React.FC<ISelectFieldProps> = (
  props,
): JSX.Element => {
  const {
    autoComplete = 'on',
    className,
    control,
    defaultValue,
    helper,
    isDisabled,
    isLoading,
    isMulti,
    isRequired,
    isRounded,
    label,
    labelClassName,
    menuPlacement,
    name,
    options,
    placeholder,
    primaryColor,
    selectClassName,
    size = 'sm',
  } = props;

  const { field, fieldState } = useController({ control, defaultValue, name });

  const { t } = useTranslation();

  return (
    <Stack className={cn(className)}>
      {!fp.isNil(label) && !isRequired ? (
        <FormLabel
          className={cn(FormLabelFontSize[size], labelClassName)}
          htmlFor={name}
        >
          {label}
        </FormLabel>
      ) : null}

      <Select
        autoComplete={autoComplete}
        className={selectClassName}
        id={name}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isRequired={isRequired}
        isRounded={isRounded}
        menuPlacement={menuPlacement}
        options={options}
        placeholder={placeholder}
        primaryColor={primaryColor}
        size={size}
        {...field}
      />

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {t(fieldState?.error?.message as string)}
      </FormErrorMessage>
    </Stack>
  );
};

SelectField.defaultProps = {
  labelClassName: 'mb-1',
};
