import React, { HTMLProps } from 'react';
import cn from 'classnames';
import { Flex } from '@design-system';

export interface IStackProps extends HTMLProps<HTMLElement> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * If `true`, the flex direction will be `row`.
   */
  isInline?: boolean;
}

export const Stack: React.FC<IStackProps> = (props): JSX.Element => {
  const { children, className, isInline, ...rest } = props;

  return (
    <Flex
      className={cn(className, isInline ? 'flex-row' : 'flex-col')}
      {...rest}
    >
      {children}
    </Flex>
  );
};
