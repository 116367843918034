import React, { HTMLProps } from 'react';
import { useForm } from 'react-hook-form';
import { InputField } from '@design-system/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignerFormValidationSchema } from './helpers';
import { Form } from './components/Form';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { Button } from '@design-system';

export type SignerFormFields = {
  name: string;
  email: string;
};

export interface ISignerFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * On submit action handler.
   */
  onSubmit: (v: SignerFormFields, reset: any) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const SignerForm: React.FC<ISignerFormProps> = (props): JSX.Element => {
  const { className, primaryColor, onSubmit, ...rest } = props;
  const { t } = useTranslation();
  const { formState, control, handleSubmit, reset } = useForm<SignerFormFields>(
    {
      defaultValues: { name: '' },
      mode: 'all',
      resolver: yupResolver(SignerFormValidationSchema),
    },
  );

  return (
    <Form
      className={className}
      onSubmit={handleSubmit((values) => onSubmit(values, reset))}
      {...rest}>
      <InputField
        className="mb-4 w-full"
        control={control}
        name="name"
        placeholder={t(translations.INPUTS.NAME)}
        size="md"
        primaryColor={primaryColor}
        type="text"
        autoComplete="off"
      />
      <InputField
        className="mb-4 w-full"
        control={control}
        name="email"
        placeholder={t(translations.COMMON.EMAIL)}
        size="md"
        primaryColor={primaryColor}
        type="text"
        autoComplete="off"
      />

      <div className="flex w-full items-center justify-center">
        <Button
          color={primaryColor}
          className="max-w mb-2 mr-8 px-8 text-white"
          isDisabled={formState?.isSubmitting || !formState?.isValid}
          type="submit"
          variant="solid"
          isLoading={formState?.isSubmitting}
          size="md">
          {t(translations.COMMON.ADD)}
        </Button>
      </div>
    </Form>
  );
};
