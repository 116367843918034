import { clomp } from 'clomp';

export const Label = clomp.label`
  font-medium
  text-gray-300
  mb-1
`;

Label.Required = clomp.label`
  absolute
  block
  cursor-text
  select-none
  transform

  textfield__label
`;
