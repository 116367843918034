import React from 'react';
import { clomp } from 'clomp';
import cn from 'classnames';

const Base = clomp.h1`
  font-bold
  mb-4
  text-subtitle
`;

export interface IHeadingProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
}

export const Heading: React.FC<IHeadingProps> = (props): JSX.Element => {
  const { children, className } = props;

  return (
    <Base className={cn(className)}>
      {children}
    </Base>
  );
}
