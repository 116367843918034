import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput, TextInputType } from '@design-system';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

interface OrganizationFormFields {
  name: string;
  description: string;
}

export interface EditOrganizationProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
  onChange?: any;
  initialValues?: any;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
});

export const OrganizationForm: React.FC<EditOrganizationProps> = ({
  className,
  onChange,
  initialValues,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<OrganizationFormFields>({
    resolver: yupResolver(schema) as any,
  });

  useEffect(() => {
    const subscription = watch((value) =>
      onChange({
        ...value,
      }),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <form className={className} onKeyDown={(e) => checkKeyDown(e)}>
      <div className="mb-4 flex flex-col">
        <Controller
          name="name"
          defaultValue={initialValues?.name}
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                id={field.name}
                name={field.name}
                defaultValue={field.value}
                onChange={field.onChange}
                type={TextInputType.text}
                isFullWidth
                placeholder={t(translations.INPUTS.NEW_ORGANIZATION_INPUT)}
                required
              />
            );
          }}
        />
        {errors.name && (
          <span className="text-red-300" role="alert">
            {errors.name.message}
          </span>
        )}
      </div>
    </form>
  );
};
