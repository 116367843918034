import { IWLContextProps } from '@contexts';
import { Dispatch, useCallback } from 'react';
import { useSession } from 'store/session';
import { useClient } from 'hooks/useClient';
import { tokenV3Name } from 'config/constants';
import { useResponseMsg } from './useResponseMsg';
import fp from 'lodash/fp';
import {
  IActionEnum,
  IAssetNameEnum as IANEnum,
  getErrorStatusCode,
  isErrorStatusCodeNil
} from '../helpers';

/**
 * Async function that will update the White Label account metadata.
 * @param {T} p - white label payload.
 */
export type IUseUpdateWLFn<T> = (p: T) => Promise<void>;

export const useUpdateWL = <T extends Partial<IWLContextProps>>(
  dispatch: Dispatch<any>
): [IUseUpdateWLFn<T>] => {
  const { user } = useSession();

  const client = useClient({
    url: process.env.REACT_APP_API_V3,
    token: localStorage.getItem(tokenV3Name) as string,
  });

  const [responseMsgFn] = useResponseMsg();

  const fn = useCallback(async (p: T) => {
    try {
      dispatch({ type: IActionEnum.UPDATE_WL });

      const { color, isUserRewardsAvailable, lightLogo, logo } = p;

      await client.updateAssets(user?.id, IANEnum.COLOR, color);

      await client.updateRewardsAvailability(user?.id, isUserRewardsAvailable);

      if (!fp.isNil(logo) && !fp.isString(logo)) {
        await client.updateAssets(user?.id, IANEnum.LOGO, logo, true);
      }

      if (!fp.isNil(lightLogo) && !fp.isString(lightLogo)) {
        await client.updateAssets(user?.id, IANEnum.LIGHT_LOGO, lightLogo, true);
      }

      responseMsgFn({ isError: false })(200);

      dispatch({ ...p, type: IActionEnum.UPDATE_WL_SUCCESS });
    } catch (err) {
      if (!isErrorStatusCodeNil(err)) {
        fp.compose(responseMsgFn({ isError: true }), getErrorStatusCode)(err);
      }
      dispatch({ type: IActionEnum.UPDATE_WL_FAILURE });
    }
  }, [client, dispatch, responseMsgFn, user]);

  return [fn];
};
