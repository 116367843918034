import React from "react";
import { ISVGProps } from "types";

export const TextIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 20 14"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#text-icon_svg__a)" fill="currentColor">
      <path d="M3.206 9.683H7.09l1.076 4.303h2.151L7.09 0H3.228L0 13.986h2.205l1.001-4.303Zm2.152-7.531L6.595 7.53H3.69l1.248-5.38h.42ZM18.924 5.379v1.485a4.304 4.304 0 1 0 0 5.637v1.485H20V5.379h-1.076Zm-3.227 6.455a2.152 2.152 0 1 1 0-4.304 2.152 2.152 0 0 1 0 4.304Z" />
    </g>
    <defs>
      <clipPath id="text-icon_svg__a">
        <path d="M0 0h20v13.986H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
