import { useEffect, useMemo } from 'react';
import { CINCELClient } from '@cincel/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { ERROR_TOAST_DEFAULTS } from '@helpers';
import { AxiosError } from 'axios';

export const tokenName = 'tokenV3Cincel';

export function useClient({ url = '', token = '' }) {
  const { t } = useTranslation();
  const toast = useToast();
  const newToken: string = token || (localStorage.getItem(tokenName) as string);
  const client = useMemo(
    () =>
      new CINCELClient({
        baseURL: url,
        token: newToken as string,
      }),
    [newToken, url],
  );
  const axios = client.getAxiosInstance();

  useEffect(() => {
    axios.interceptors.response.use(
      function (config) {
        return config;
      },
      function (error) {
        const isNetworkError = (error as AxiosError).message.includes(
          'Network Error',
        );

        if (isNetworkError) {
          toast({
            ...ERROR_TOAST_DEFAULTS,
            title: t(translations.ERRORS.NETWORK_ERROR),
          });
        }

        return Promise.reject(error);
      },
    );
  }, [axios]);

  return client;
}
