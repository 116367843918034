import React, { useCallback, useRef } from 'react';
import { Control, useController } from 'react-hook-form';
import { CustomInput } from './components/CustomInput';
import cn from 'classnames';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface IColorFieldProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * RHF `control` object provided by invoking useForm. This prop is optional
   * when using FormProvider.
   */
  control?: Control<any, any>;
  /**
   * Input default value.
   */
  defaultValue?: string;
  /**
   * Input label, can be a raw text or an i18n key.
   */
  label?: string;
  /**
   * Input name that RHF will use to identify the input on the HTML code.
   */
  name: string;
}

export const ColorField: React.FC<IColorFieldProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const {
    className,
    control,
    defaultValue,
    label = t(translations.USER_PAGES.ADMIN_COLOR),
    name,
  } = props;

  const { field } = useController({ defaultValue, control, name });

  const ref = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback(
    fp.compose(field.onChange, fp.toUpper, fp.get(['target', 'value'])),
    [ref?.current],
  );

  const handleOnClick = useCallback(() => {
    fp.invoke('current.click')(ref);
  }, [ref?.current]);

  return (
    <div className={cn(className, 'relative')}>
      <label className="text-subtitle font-bold" htmlFor={name}>
        {label}
      </label>

      <CustomInput
        className="mt-9"
        onClick={handleOnClick}
        value={field?.value}
      />

      <input
        className="invisible absolute -translate-y-4 transform"
        onBlur={field.onBlur}
        onChange={handleOnChange}
        name={field.name}
        ref={ref}
        type="color"
        value={field?.value}
      />
    </div>
  );
};
