import { clomp } from 'clomp';
import React from 'react';

import { IPropsOf } from 'types.d';

const Base = clomp.div`
  border-b-2
  border-gray-50
  overflow-scroll
  rounded-b-xl
`;

export type IContainerProps = IPropsOf<'div'>;

export const Container: React.FC<IContainerProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Base {...rest}>
      {children}
    </Base>
  );
};
