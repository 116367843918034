/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
var HttpClient = /** @class */ (function () {
    function HttpClient(apiConfig) {
        var _a;
        if (apiConfig === void 0) { apiConfig = {}; }
        var _this = this;
        this.baseUrl = 'https://localhost:3000';
        this.securityData = null;
        this.abortControllers = new Map();
        this.customFetch = function () {
            var fetchParams = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                fetchParams[_i] = arguments[_i];
            }
            return fetch.apply(void 0, fetchParams);
        };
        this.baseApiParams = {
            credentials: 'same-origin',
            headers: {},
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        };
        this.setSecurityData = function (data) {
            _this.securityData = data;
        };
        this.contentFormatters = (_a = {},
            _a[ContentType.Json] = function (input) {
                return input !== null && (typeof input === 'object' || typeof input === 'string')
                    ? JSON.stringify(input)
                    : input;
            },
            _a[ContentType.Text] = function (input) {
                return input !== null && typeof input !== 'string'
                    ? JSON.stringify(input)
                    : input;
            },
            _a[ContentType.FormData] = function (input) {
                return Object.keys(input || {}).reduce(function (formData, key) {
                    var property = input[key];
                    formData.append(key, property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                            ? JSON.stringify(property)
                            : "".concat(property));
                    return formData;
                }, new FormData());
            },
            _a[ContentType.UrlEncoded] = function (input) { return _this.toQueryString(input); },
            _a);
        this.createAbortSignal = function (cancelToken) {
            if (_this.abortControllers.has(cancelToken)) {
                var abortController_1 = _this.abortControllers.get(cancelToken);
                if (abortController_1) {
                    return abortController_1.signal;
                }
                return void 0;
            }
            var abortController = new AbortController();
            _this.abortControllers.set(cancelToken, abortController);
            return abortController.signal;
        };
        this.abortRequest = function (cancelToken) {
            var abortController = _this.abortControllers.get(cancelToken);
            if (abortController) {
                abortController.abort();
                _this.abortControllers.delete(cancelToken);
            }
        };
        this.request = function (_a) { return __awaiter(_this, void 0, void 0, function () {
            var secureParams, _b, requestParams, queryString, payloadFormatter, responseFormat;
            var _this = this;
            var body = _a.body, secure = _a.secure, path = _a.path, type = _a.type, query = _a.query, format = _a.format, baseUrl = _a.baseUrl, cancelToken = _a.cancelToken, params = __rest(_a, ["body", "secure", "path", "type", "query", "format", "baseUrl", "cancelToken"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                            this.securityWorker;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.securityWorker(this.securityData)];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        secureParams = (_b) ||
                            {};
                        requestParams = this.mergeRequestParams(params, secureParams);
                        queryString = query && this.toQueryString(query);
                        payloadFormatter = this.contentFormatters[type || ContentType.Json];
                        responseFormat = format || requestParams.format;
                        return [2 /*return*/, this.customFetch("".concat(baseUrl || this.baseUrl || '').concat(path).concat(queryString ? "?".concat(queryString) : ''), __assign(__assign({}, requestParams), { headers: __assign(__assign({}, (requestParams.headers || {})), (type && type !== ContentType.FormData
                                    ? { 'Content-Type': type }
                                    : {})), signal: cancelToken
                                    ? this.createAbortSignal(cancelToken)
                                    : requestParams.signal, body: typeof body === 'undefined' || body === null
                                    ? null
                                    : payloadFormatter(body) })).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                var r, data, _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            r = response;
                                            r.data = null;
                                            r.error = null;
                                            if (!!responseFormat) return [3 /*break*/, 1];
                                            _a = r;
                                            return [3 /*break*/, 3];
                                        case 1: return [4 /*yield*/, response[responseFormat]()
                                                .then(function (data) {
                                                if (r.ok) {
                                                    r.data = data;
                                                }
                                                else {
                                                    r.error = data;
                                                }
                                                return r;
                                            })
                                                .catch(function (e) {
                                                r.error = e;
                                                return r;
                                            })];
                                        case 2:
                                            _a = _b.sent();
                                            _b.label = 3;
                                        case 3:
                                            data = _a;
                                            if (cancelToken) {
                                                this.abortControllers.delete(cancelToken);
                                            }
                                            if (!response.ok)
                                                throw data;
                                            return [2 /*return*/, data];
                                    }
                                });
                            }); })];
                }
            });
        }); };
        Object.assign(this, apiConfig);
    }
    HttpClient.prototype.encodeQueryParam = function (key, value) {
        var encodedKey = encodeURIComponent(key);
        return "".concat(encodedKey, "=").concat(encodeURIComponent(typeof value === 'number' ? value : "".concat(value)));
    };
    HttpClient.prototype.addQueryParam = function (query, key) {
        return this.encodeQueryParam(key, query[key]);
    };
    HttpClient.prototype.addArrayQueryParam = function (query, key) {
        var _this = this;
        var value = query[key];
        return value.map(function (v) { return _this.encodeQueryParam(key, v); }).join('&');
    };
    HttpClient.prototype.toQueryString = function (rawQuery) {
        var _this = this;
        var query = rawQuery || {};
        var keys = Object.keys(query).filter(function (key) { return 'undefined' !== typeof query[key]; });
        return keys
            .map(function (key) {
            return Array.isArray(query[key])
                ? _this.addArrayQueryParam(query, key)
                : _this.addQueryParam(query, key);
        })
            .join('&');
    };
    HttpClient.prototype.addQueryParams = function (rawQuery) {
        var queryString = this.toQueryString(rawQuery);
        return queryString ? "?".concat(queryString) : '';
    };
    HttpClient.prototype.mergeRequestParams = function (params1, params2) {
        return __assign(__assign(__assign(__assign({}, this.baseApiParams), params1), (params2 || {})), { headers: __assign(__assign(__assign({}, (this.baseApiParams.headers || {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    };
    return HttpClient;
}());
export { HttpClient };
/**
 * @title CINCEL
 * @version 3.0.0
 * @termsOfService https://www.cincel.digital/terminos-y-condiciones/
 * @baseUrl https://localhost:3000
 * @contact <soporte@cincel.digital>
 *
 * El software más fácil de firma digital
 */
var Api = /** @class */ (function (_super) {
    __extends(Api, _super);
    function Api() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.marketingCategories = {
            /**
             * No description
             *
             * @tags marketing-categories
             * @name GetMarketingcategories
             * @summary Get all marketing categories
             * @request GET:/marketing-categories
             * @secure
             */
            getMarketingcategories: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/marketing-categories", method: 'GET', secure: true }, params));
            },
        };
        _this.packetsPlan = {
            /**
             * No description
             *
             * @tags packets-plan
             * @name GetPacketsplan
             * @summary Get all packets plan
             * @request GET:/packets-plan
             * @secure
             */
            getPacketsplan: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/packets-plan", method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags packets-plan
             * @name PostPacketsplan
             * @summary Endpoint to create a BenefitsPacketPlan
             * @request POST:/packets-plan
             * @secure
             */
            postPacketsplan: function (body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/packets-plan", method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags packets-plan
             * @name GetPacketsplanPacketplan
             * @summary Endpoint to get a packetPlan
             * @request GET:/packets-plan/{packetPlan}
             * @secure
             */
            getPacketsplanPacketplan: function (packetPlan, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/packets-plan/".concat(packetPlan), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags packets-plan
             * @name PatchPacketsplanPacketplan
             * @summary Endpoint to patch a benefitsPacketPlan
             * @request PATCH:/packets-plan/{packetPlan}
             * @secure
             */
            patchPacketsplanPacketplan: function (packetPlan, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/packets-plan/".concat(packetPlan), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags packets-plan
             * @name DeletePacketsplanPacketplan
             * @summary Endpoint to delete a packetsPlan
             * @request DELETE:/packets-plan/{packetPlan}
             * @secure
             */
            deletePacketsplanPacketplan: function (packetPlan, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/packets-plan/".concat(packetPlan), method: 'DELETE', secure: true }, params));
            },
        };
        _this.rewards = {
            /**
             * No description
             *
             * @tags rewards
             * @name GetRewards
             * @summary Get all rewards
             * @request GET:/rewards
             * @secure
             */
            getRewards: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards", method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags rewards
             * @name PostRewards
             * @summary Endpoint to register an reward
             * @request POST:/rewards
             * @secure
             */
            postRewards: function (body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards", method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags rewards
             * @name GetRewardsReward
             * @summary Endpoint to retrieve Reward information
             * @request GET:/rewards/{reward}
             * @secure
             */
            getRewardsReward: function (reward, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards/".concat(reward), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags rewards
             * @name PatchRewardsReward
             * @summary Endpoint to patch Reward information
             * @request PATCH:/rewards/{reward}
             * @secure
             */
            patchRewardsReward: function (reward, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards/".concat(reward), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags rewards
             * @name DeleteRewardsReward
             * @summary Endpoint to delete an reward
             * @request DELETE:/rewards/{reward}
             * @secure
             */
            deleteRewardsReward: function (reward, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards/".concat(reward), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags rewards
             * @name GetRewardsRewardAssetsAssetpng
             * @summary Endpoint to get assets of an user
             * @request GET:/rewards/{reward}/assets/{asset}.png
             * @secure
             */
            getRewardsRewardAssetsAssetpng: function (reward, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards/".concat(reward, "/assets/").concat(asset, ".png"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags rewards
             * @name PatchRewardsRewardAssetsAsset
             * @summary Endpoint to patch rewards assets
             * @request PATCH:/rewards/{reward}/assets/{asset}
             * @secure
             */
            patchRewardsRewardAssetsAsset: function (reward, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/rewards/".concat(reward, "/assets/").concat(asset), method: 'PATCH', secure: true }, params));
            },
        };
        _this.settings = {
            /**
             * No description
             *
             * @tags settings
             * @name GetSettings
             * @summary Settings
             * @request GET:/settings
             * @secure
             */
            getSettings: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/settings", method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags settings
             * @name PatchSettings
             * @summary Endpoint to post the settings
             * @request PATCH:/settings
             * @secure
             */
            patchSettings: function (body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/settings", method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
        };
        _this.status = {
            /**
             * No description
             *
             * @tags status
             * @name GetStatus
             * @summary Status endpoint
             * @request GET:/status
             * @secure
             */
            getStatus: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/status", method: 'GET', secure: true }, params));
            },
        };
        _this.teams = {
            /**
             * No description
             *
             * @tags teams
             * @name GetTeams
             * @summary Get all teams
             * @request GET:/teams
             * @secure
             */
            getTeams: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams", method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeams
             * @summary Endpoint to create a new team
             * @request POST:/teams
             * @secure
             */
            postTeams: function (body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams", method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsUuid
             * @summary Endpoint to get a team
             * @request GET:/teams/{uuid}
             * @secure
             */
            getTeamsUuid: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(uuid), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsUuid
             * @summary Endpoint to patch a team
             * @request PATCH:/teams/{uuid}
             * @secure
             */
            patchTeamsUuid: function (uuid, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(uuid), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsUuid
             * @summary Endpoint to delete a team
             * @request DELETE:/teams/{uuid}
             * @secure
             */
            deleteTeamsUuid: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(uuid), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFolders
             * @summary Get all folders of a Team
             * @request GET:/teams/{team}/folders
             * @secure
             */
            getTeamsTeamFolders: function (_a, params) {
                var team = _a.team, query = __rest(_a, ["team"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders"), method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamFolders
             * @summary Endpoint to create a new folder for a team
             * @request POST:/teams/{team}/folders
             * @secure
             */
            postTeamsTeamFolders: function (team, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders"), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamContacts
             * @summary Get all contacts of a Team
             * @request GET:/teams/{team}/contacts
             * @secure
             */
            getTeamsTeamContacts: function (_a, params) {
                var team = _a.team, query = __rest(_a, ["team"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/contacts"), method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamContacts
             * @summary Endpoint to create a new contact for a team
             * @request POST:/teams/{team}/contacts
             * @secure
             */
            postTeamsTeamContacts: function (team, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/contacts"), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFormtemplates
             * @summary Get all form templates of a team
             * @request GET:/teams/{team}/form-templates
             * @secure
             */
            getTeamsTeamFormtemplates: function (_a, params) {
                var team = _a.team, query = __rest(_a, ["team"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates"), method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamFormtemplates
             * @summary Endpoint to create a new form template for a team
             * @request POST:/teams/{team}/form-templates
             * @secure
             */
            postTeamsTeamFormtemplates: function (team, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates"), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamUsers
             * @summary Get all users of a team
             * @request GET:/teams/{team}/users
             * @secure
             */
            getTeamsTeamUsers: function (team, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/users"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFormsForm
             * @summary Endpoint to get a form of a team
             * @request GET:/teams/{team}/forms/{form}
             * @secure
             */
            getTeamsTeamFormsForm: function (team, form, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/forms/").concat(form), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamFormsForm
             * @summary Endpoint to patch a form of a Team
             * @request PATCH:/teams/{team}/forms/{form}
             * @secure
             */
            patchTeamsTeamFormsForm: function (team, form, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/forms/").concat(form), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFormtemplatesFormtemplate
             * @summary Endpoint to get a form template of a team
             * @request GET:/teams/{team}/form-templates/{formTemplate}
             * @secure
             */
            getTeamsTeamFormtemplatesFormtemplate: function (team, formTemplate, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates/").concat(formTemplate), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamFormtemplatesFormtemplate
             * @summary Endpoint to patch a form template of a Team
             * @request PATCH:/teams/{team}/form-templates/{formTemplate}
             * @secure
             */
            patchTeamsTeamFormtemplatesFormtemplate: function (team, formTemplate, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates/").concat(formTemplate), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsTeamFormtemplatesFormtemplate
             * @summary Endpoint to delete a form template
             * @request DELETE:/teams/{team}/form-templates/{formTemplate}
             * @secure
             */
            deleteTeamsTeamFormtemplatesFormtemplate: function (team, formTemplate, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates/").concat(formTemplate), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamContactsContact
             * @summary Endpoint to get a contact of a team
             * @request GET:/teams/{team}/contacts/{contact}
             * @secure
             */
            getTeamsTeamContactsContact: function (team, contact, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/contacts/").concat(contact), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamContactsContact
             * @summary Endpoint to patch a Contact of a Team
             * @request PATCH:/teams/{team}/contacts/{contact}
             * @secure
             */
            patchTeamsTeamContactsContact: function (team, contact, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/contacts/").concat(contact), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsTeamContactsContact
             * @summary Endpoint to delete Contact
             * @request DELETE:/teams/{team}/contacts/{contact}
             * @secure
             */
            deleteTeamsTeamContactsContact: function (team, contact, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/contacts/").concat(contact), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamAssetsAssetpng
             * @summary Endpoint to get a team asset
             * @request GET:/teams/{team}/assets/{asset}.png
             * @secure
             */
            getTeamsTeamAssetsAssetpng: function (team, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/assets/").concat(asset, ".png"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFoldersFolder
             * @summary Endpoint to get a Folder of a Team
             * @request GET:/teams/{team}/folders/{folder}
             * @secure
             */
            getTeamsTeamFoldersFolder: function (team, folder, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamFoldersFolder
             * @summary Endpoint to patch a Folder of a Team
             * @request PATCH:/teams/{team}/folders/{folder}
             * @secure
             */
            patchTeamsTeamFoldersFolder: function (team, folder, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsTeamFoldersFolder
             * @summary Endpoint to delete Folder
             * @request DELETE:/teams/{team}/folders/{folder}
             * @secure
             */
            deleteTeamsTeamFoldersFolder: function (team, folder, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFoldersFolderDocuments
             * @summary Get all documents of a folder
             * @request GET:/teams/{team}/folders/{folder}/documents
             * @secure
             */
            getTeamsTeamFoldersFolderDocuments: function (_a, params) {
                var team = _a.team, folder = _a.folder, query = __rest(_a, ["team", "folder"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents"), method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamFoldersFolderDocuments
             * @summary Endpoint to create a new document
             * @request POST:/teams/{team}/folders/{folder}/documents
             * @secure
             */
            postTeamsTeamFoldersFolderDocuments: function (team, folder, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents"), method: 'POST', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFormtemplatesFormtemplateTemplatepdf
             * @summary Endpoint to get a template(pdf) for a form template
             * @request GET:/teams/{team}/form-templates/{formTemplate}/template.pdf
             * @secure
             */
            getTeamsTeamFormtemplatesFormtemplateTemplatepdf: function (team, formTemplate, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates/").concat(formTemplate, "/template.pdf"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamFormtemplatesFormtemplateTemplatepdf
             * @summary Endpoint to post a template(pdf) for a form template
             * @request POST:/teams/{team}/form-templates/{formTemplate}/template.pdf
             * @secure
             */
            postTeamsTeamFormtemplatesFormtemplateTemplatepdf: function (team, formTemplate, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/form-templates/").concat(formTemplate, "/template.pdf"), method: 'POST', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFoldersFolderDocumentsDocument
             * @summary Endpoint to get a document
             * @request GET:/teams/{team}/folders/{folder}/documents/{document}
             * @secure
             */
            getTeamsTeamFoldersFolderDocumentsDocument: function (team, folder, document, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamFoldersFolderDocumentsDocument
             * @summary Endpoint to update a document
             * @request PATCH:/teams/{team}/folders/{folder}/documents/{document}
             * @secure
             */
            patchTeamsTeamFoldersFolderDocumentsDocument: function (team, folder, document, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsTeamFoldersFolderDocumentsDocument
             * @summary Endpoint to delete a document
             * @request DELETE:/teams/{team}/folders/{folder}/documents/{document}
             * @secure
             */
            deleteTeamsTeamFoldersFolderDocumentsDocument: function (team, folder, document, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFoldersFolderDocumentsDocumentpdf
             * @summary Endpoint to get document binary
             * @request GET:/teams/{team}/folders/{folder}/documents/{document}.pdf
             * @secure
             */
            getTeamsTeamFoldersFolderDocumentsDocumentpdf: function (team, folder, document, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, ".pdf"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFoldersFolderDocumentsDocumentInvites
             * @summary Get all invites of a document
             * @request GET:/teams/{team}/folders/{folder}/documents/{document}/invites
             * @secure
             */
            getTeamsTeamFoldersFolderDocumentsDocumentInvites: function (_a, params) {
                var team = _a.team, folder = _a.folder, document = _a.document, query = __rest(_a, ["team", "folder", "document"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites"), method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamFoldersFolderDocumentsDocumentInvites
             * @summary Endpoint to create an invite for a document
             * @request POST:/teams/{team}/folders/{folder}/documents/{document}/invites
             * @secure
             */
            postTeamsTeamFoldersFolderDocumentsDocumentInvites: function (team, folder, document, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites"), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name GetTeamsTeamFoldersFolderDocumentsDocumentInvitesInvite
             * @summary Endpoint to get an invite from a document
             * @request GET:/teams/{team}/folders/{folder}/documents/{document}/invites/{invite}
             * @secure
             */
            getTeamsTeamFoldersFolderDocumentsDocumentInvitesInvite: function (team, folder, document, invite, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamFoldersFolderDocumentsDocumentInvitesInvite
             * @summary Endpoint to update an invite from a document
             * @request PATCH:/teams/{team}/folders/{folder}/documents/{document}/invites/{invite}
             * @secure
             */
            patchTeamsTeamFoldersFolderDocumentsDocumentInvitesInvite: function (team, folder, document, invite, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsTeamFoldersFolderDocumentsDocumentInvitesInvite
             * @summary Endpoint to delete an invite from a document
             * @request DELETE:/teams/{team}/folders/{folder}/documents/{document}/invites/{invite}
             * @secure
             */
            deleteTeamsTeamFoldersFolderDocumentsDocumentInvitesInvite: function (team, folder, document, invite, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamAssetpng
             * @summary Endpoint to patch team logo asset
             * @request PATCH:/teams/{team}/{asset}.png
             * @secure
             */
            patchTeamsTeamAssetpng: function (team, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/").concat(asset, ".png"), method: 'PATCH', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PatchTeamsTeamUsersUser
             * @summary Endpoint to patch team-user association
             * @request PATCH:/teams/{team}/users/{user}
             * @secure
             */
            patchTeamsTeamUsersUser: function (team, user, body, role, isDefaultTeam, deletedAt, createdAt, updatedAt, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/users/").concat(user), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamUsersUser
             * @summary Endpoint to add a user to a team
             * @request POST:/teams/{team}/users/{user}
             * @secure
             */
            postTeamsTeamUsersUser: function (team, user, body, role, isDefaultTeam, deletedAt, createdAt, updatedAt, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/users/").concat(user), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name DeleteTeamsTeamUsersUser
             * @summary Endpoint to delete team-user association
             * @request DELETE:/teams/{team}/users/{user}
             * @secure
             */
            deleteTeamsTeamUsersUser: function (team, user, role, isDefaultTeam, deletedAt, createdAt, updatedAt, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/users/").concat(user), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamContactscsv
             * @summary Endpoint to add contacts in a Team from an CSV File
             * @request POST:/teams/{team}/contacts.csv
             * @secure
             */
            postTeamsTeamContactscsv: function (team, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/contacts.csv"), method: 'POST', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamForms
             * @summary Endpoint to create a new form for a team
             * @request POST:/teams/{team}/forms
             * @secure
             */
            postTeamsTeamForms: function (team, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/forms"), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags teams
             * @name PostTeamsTeamFoldersFolderDocumentsDocumentInvitesInviteSignaturepng
             * @summary Endpoint to post a signature to an invite for a document
             * @request POST:/teams/{team}/folders/{folder}/documents/{document}/invites/{invite}/signature.png
             * @secure
             */
            postTeamsTeamFoldersFolderDocumentsDocumentInvitesInviteSignaturepng: function (team, folder, document, invite, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/teams/".concat(team, "/folders/").concat(folder, "/documents/").concat(document, "/invites/").concat(invite, "/signature.png"), method: 'POST', secure: true }, params));
            },
        };
        _this.users = {
            /**
             * No description
             *
             * @tags users
             * @name GetUsers
             * @summary Get all users
             * @request GET:/users
             * @secure
             */
            getUsers: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users", method: 'GET', query: query, secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PostUsers
             * @summary Endpoint to create a new user
             * @request POST:/users
             * @secure
             */
            postUsers: function (body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users", method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersUser
             * @summary Endpoint to retrieve user information
             * @request GET:/users/{user}
             * @secure
             */
            getUsersUser: function (user, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PatchUsersUser
             * @summary Endpoint to update an user
             * @request PATCH:/users/{user}
             * @secure
             */
            patchUsersUser: function (user, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name DeleteUsersUser
             * @summary Endpoint to delete an user
             * @request DELETE:/users/{user}
             * @secure
             */
            deleteUsersUser: function (user, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user), method: 'DELETE', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersUserLimits
             * @summary Endpoint to get a user limit
             * @request GET:/users/{user}/limits
             * @secure
             */
            getUsersUserLimits: function (user, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/limits"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersEmailRecoverytoken
             * @summary Endpoint to get recovery token for a user.
             * @request GET:/users/{email}/recovery-token
             * @secure
             */
            getUsersEmailRecoverytoken: function (email, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(email, "/recovery-token"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PatchUsersEmailRecoverytoken
             * @summary Endpoint to recovery password trough sended token.
             * @request PATCH:/users/{email}/recovery-token
             * @secure
             */
            patchUsersEmailRecoverytoken: function (_a, body, params) {
                var email = _a.email, query = __rest(_a, ["email"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(email, "/recovery-token"), method: 'PATCH', query: query, body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersEmailVerificationcode
             * @summary Endpoint to get an email verification code
             * @request GET:/users/{email}/verification-code
             * @secure
             */
            getUsersEmailVerificationcode: function (email, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(email, "/verification-code"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersUserTeams
             * @summary Endpoint to get all teams of an user
             * @request GET:/users/{user}/teams
             * @secure
             */
            getUsersUserTeams: function (user, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/teams"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersUserAssetsAsset
             * @summary Endpoint to get assets of an user
             * @request GET:/users/{user}/assets/{asset}
             * @secure
             */
            getUsersUserAssetsAsset: function (user, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/assets/").concat(asset), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PatchUsersUserAssetsAsset
             * @summary Endpoint to post assets of an user
             * @request PATCH:/users/{user}/assets/{asset}
             * @secure
             */
            patchUsersUserAssetsAsset: function (user, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/assets/").concat(asset), method: 'PATCH', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name GetUsersUserLimitsLimit
             * @summary Endpoint to get a user limit
             * @request GET:/users/{user}/limits/{limit}
             * @secure
             */
            getUsersUserLimitsLimit: function (user, limit, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/limits/").concat(limit), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PatchUsersUserLimitsLimit
             * @summary Endpoint to patch a user limit
             * @request PATCH:/users/{user}/limits/{limit}
             * @secure
             */
            patchUsersUserLimitsLimit: function (user, limit, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/limits/").concat(limit), method: 'PATCH', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PostUsersEmailConfirmationcode
             * @summary Endpoint to post an email confirmation code
             * @request POST:/users/{email}/confirmation-code
             * @secure
             */
            postUsersEmailConfirmationcode: function (email, body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(email, "/confirmation-code"), method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags users
             * @name PostUsersUserPacketsPacket
             * @summary Endpoint to add a packet to an user
             * @request POST:/users/{user}/packets/{packet}
             * @secure
             */
            postUsersUserPacketsPacket: function (_a, params) {
                var benefitType = _a.benefitType, type = _a.type, quantity = _a.quantity, quantityUnit = _a.quantityUnit, quantityConsumed = _a.quantityConsumed, status = _a.status, expirable = _a.expirable, expirationDate = _a.expirationDate, benefitsPacketsPlanId = _a.benefitsPacketsPlanId, subscriptionId = _a.subscriptionId, invoiceId = _a.invoiceId, userId = _a.userId, amount = _a.amount, packet = _a.packet, user = _a.user, query = __rest(_a, ["benefitType", "type", "quantity", "quantityUnit", "quantityConsumed", "status", "expirable", "expirationDate", "benefitsPacketsPlanId", "subscriptionId", "invoiceId", "userId", "amount", "packet", "user"]);
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/users/".concat(user, "/packets/").concat(packet), method: 'POST', query: query, secure: true }, params));
            },
        };
        _this.version = {
            /**
             * No description
             *
             * @tags version
             * @name GetVersion
             * @summary Version
             * @request GET:/version
             * @secure
             */
            getVersion: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/version", method: 'GET', secure: true }, params));
            },
        };
        _this.assets = {
            /**
             * No description
             *
             * @tags assets
             * @name GetAssetsAsset
             * @summary Endpoint to get global assets
             * @request GET:/assets/{asset}
             * @secure
             */
            getAssetsAsset: function (asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/assets/".concat(asset), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags assets
             * @name PatchAssetsAsset
             * @summary Endpoint to post global assets
             * @request PATCH:/assets/{asset}
             * @secure
             */
            patchAssetsAsset: function (asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/assets/".concat(asset), method: 'PATCH', secure: true }, params));
            },
        };
        _this.emails = {
            /**
             * No description
             *
             * @tags emails
             * @name GetEmailsEmail
             * @summary Endpoint to retrieve the information of an email saved in our services
             * @request GET:/emails/{email}
             * @secure
             */
            getEmailsEmail: function (email, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/emails/".concat(email), method: 'GET', secure: true }, params));
            },
        };
        _this.timestamps = {
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsHashbitcoin
             * @summary Genera estampa de tiempo bitcoin para el {hash}
             * @request GET:/timestamps/{hash}.bitcoin
             * @secure
             */
            getTimestampsHashbitcoin: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/".concat(hash, ".bitcoin"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsHashasn1
             * @summary Genera constancia NOM151 para el {hash}
             * @request GET:/timestamps/{hash}.asn1
             * @secure
             */
            getTimestampsHashasn1: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/".concat(hash, ".asn1"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsHashxml
             * @summary Árbol de Merkle
             * @request GET:/timestamps/{hash}.xml
             * @secure
             */
            getTimestampsHashxml: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/".concat(hash, ".xml"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsHashpdf
             * @summary PDF
             * @request GET:/timestamps/{hash}.pdf
             * @secure
             */
            getTimestampsHashpdf: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/".concat(hash, ".pdf"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsHash
             * @summary Estampas de tiempo para el {hash}
             * @request GET:/timestamps/{hash}
             * @secure
             */
            getTimestampsHash: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/".concat(hash), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsEthHashxml
             * @summary Árbol de Merkle
             * @request GET:/timestamps/eth/{hash}.xml
             * @secure
             */
            getTimestampsEthHashxml: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/eth/".concat(hash, ".xml"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags timestamps
             * @name GetTimestampsEthHashpdf
             * @summary PDF
             * @request GET:/timestamps/eth/{hash}.pdf
             * @secure
             */
            getTimestampsEthHashpdf: function (hash, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/timestamps/eth/".concat(hash, ".pdf"), method: 'GET', secure: true }, params));
            },
        };
        _this.tokens = {
            /**
             * No description
             *
             * @tags tokens
             * @name GetTokensJwt
             * @summary basic auth || auth0 ===>> jwt
             * @request GET:/tokens/jwt
             * @secure
             */
            getTokensJwt: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/tokens/jwt", method: 'GET', secure: true }, params));
            },
        };
        _this.documents = {
            /**
             * No description
             *
             * @tags documents
             * @name GetDocumentsDocumentSigneddocumentpdf
             * @summary Endpoint to get the signed version of a document
             * @request GET:/documents/{document}/signedDocument.pdf
             * @secure
             */
            getDocumentsDocumentSigneddocumentpdf: function (document, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/documents/".concat(document, "/signedDocument.pdf"), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags documents
             * @name GetDocumentsDocumentAssetsAsset
             * @summary Endpoint to get assets of a document
             * @request GET:/documents/{document}/assets/{asset}
             * @secure
             */
            getDocumentsDocumentAssetsAsset: function (document, asset, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/documents/".concat(document, "/assets/").concat(asset), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags documents
             * @name GetDocumentsDocumentCertsAudittrailpdf
             * @summary Endpoint to get audit trail of a signed document
             * @request GET:/documents/{document}/certs/auditTrail.pdf
             * @secure
             */
            getDocumentsDocumentCertsAudittrailpdf: function (document, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/documents/".concat(document, "/certs/auditTrail.pdf"), method: 'GET', secure: true }, params));
            },
        };
        _this.kyc = {
            /**
             * No description
             *
             * @tags kyc
             * @name GetKycInvitationidEmail
             * @summary Endpoint to obtain KYC verification url
             * @request GET:/kyc/{invitationId}/{email}
             * @secure
             */
            getKycInvitationidEmail: function (invitationId, email, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/kyc/".concat(invitationId, "/").concat(email), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags kyc
             * @name GetKycDataInvitationidScanreference
             * @summary Endpoint to get kyc data of a user
             * @request GET:/kyc/data/{invitationId}/{scanReference}
             * @secure
             */
            getKycDataInvitationidScanreference: function (invitationId, scanReference, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/kyc/data/".concat(invitationId, "/").concat(scanReference), method: 'GET', secure: true }, params));
            },
            /**
             * No description
             *
             * @tags kyc
             * @name GetKycDataInvitationidDocnamejpeg
             * @summary Endpoint to get kyc data of a user
             * @request GET:/kyc/data/{invitationId}/{docName}.jpeg
             * @secure
             */
            getKycDataInvitationidDocnamejpeg: function (invitationId, docName, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/kyc/data/".concat(invitationId, "/").concat(docName, ".jpeg"), method: 'GET', secure: true }, params));
            },
        };
        _this.events = {
            /**
             * No description
             *
             * @tags events
             * @name PostEvents
             * @summary Endpoint to register an event
             * @request POST:/events
             * @secure
             */
            postEvents: function (body, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/events", method: 'POST', body: body, secure: true, type: ContentType.Json }, params));
            },
        };
        return _this;
    }
    return Api;
}(HttpClient));
export { Api };
