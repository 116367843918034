import React from 'react';
import { Flex, WarningIcon } from "@design-system";
import cn from 'classnames';
import fp from 'lodash/fp';

export interface IFormErrorMessageProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
}

export const FormErrorMessage: React.FC<IFormErrorMessageProps> = (
  props
): JSX.Element | null => {
  const { children, className } = props;

  if (fp.isEmpty(children) || fp.isNil(children)) return null;

  return (
    <Flex className={cn('items-center', className)}>
      <WarningIcon color="#D0011B" height={12} width={12} />
      <span className="font-bold ml-2 text-gray-200 text-xs">
        {children}
      </span>
    </Flex>
  );
}
