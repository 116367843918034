import React from "react";
import { ISVGProps } from "types";

export const CalendarIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#calendar-icon_svg__a)">
      <path
        d="M18.073 1.538h-3.27v-.77a.769.769 0 1 0-1.538 0v.77h-6.54v-.77a.769.769 0 0 0-1.538 0v.77h-3.26A1.927 1.927 0 0 0 0 3.465v13.839a1.927 1.927 0 0 0 1.927 1.927h16.146A1.938 1.938 0 0 0 20 17.314V3.464a1.927 1.927 0 0 0-1.927-1.926ZM1.927 3.075h3.26v.77a.769.769 0 0 0 1.538 0v-.77h6.54v.77a.769.769 0 1 0 1.538 0v-.77h3.27a.39.39 0 0 1 .39.39v2.112H1.537V3.465a.39.39 0 0 1 .39-.39Zm16.146 14.608H1.927a.4.4 0 0 1-.38-.38V7.115h16.915v10.19a.39.39 0 0 1-.39.38Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="calendar-icon_svg__a">
        <path d="M0 0h20v19.231H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
