import React from 'react';
import { cx } from '@emotion/css';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { IPropsOf } from 'types';
import { Button, Chip } from '@design-system';
import { Base } from './components/Base';
import { Heading, Paragraph } from './components/Typography';
import { getDynamicCSS } from './helpers';
import { translations } from 'translations';
import { useTranslation } from 'react-i18next';

export interface IRewardCardProps extends IPropsOf<'div'> {
  /**
   * Reward category listing.
   */
  categories?: string[];
  /**
   * Card's cover image.
   */
  cover: string;
  /**
   * Custom white label color.
   */
  primaryColor: string;
  /**
   * Card's summary.
   */
  summary: string;
  /**
   * Card's title.
   */
  title: string;
  /**
   * Classname
   */
  className?: string;
}

export const RewardCard: React.FC<IRewardCardProps> = (props): JSX.Element => {
  const {
    categories = [],
    className,
    cover,
    primaryColor,
    summary,
    title,
    ...rest
  } = props;

  const { t } = useTranslation();

  return (
    <Base className={cx(getDynamicCSS(primaryColor), className)} {...rest}>
      <img className="h-[90px] mb-5 object-contain" height={90} src={cover} />

      <Heading>{title}</Heading>

      <div className="flex-1 h-16 mb-8 overflow-hidden">
        <Paragraph>{summary}</Paragraph>
      </div>

      {!fp.isEmpty(categories) || !fp.isNil(categories) ? (
        <div className="flex flex-nowrap gap-1 mb-3.5 overflow-scroll">
          {categories?.map((item) => (
            <Chip key={nanoid()} color="#444444" size="xxs">
              {item}
            </Chip>
          ))}
        </div>
      ) : null}

      <Button
        className="self-end text-white w-[140px]"
        color={primaryColor}
        size="xs"
        variant="solid"
      >
        {t(translations.COMMON.MORE_INFO)}
      </Button>
    </Base>
  );
};
