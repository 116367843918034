import React from 'react';
import { Button } from '@design-system';
import cn from 'classnames';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { useMobileDetect } from 'hooks/useIsMobile';
import { SimpleTooltip } from '@design-system';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface DropzoneProps {
  className?: string;
  onClick?: () => void;
  label?: string;
  handleFileDrop?: any;
  validFiles?: string;
  recommendedDimmensions?: string | undefined;
  recommendedSize?: string | undefined;
  primaryColor: string;
}

export interface TargetBoxProps {
  onDrop: (item: { files: any[] }) => void;
}

export const Dropzone = ({
  className = '',
  onClick,
  label = 'Arrastra tu archivo en pdf o selecciona alguno desde tu computadora',
  dropLabel = 'Suelta tu archivo aquí',
  dragIcon = true,
  validFiles = '.pdf, .doc, .xls',
  handleFileDrop = (_x) => {},
  recommendedDimmensions = '',
  recommendedSize = '',
  primaryColor,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileDetect();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (handleFileDrop) {
          handleFileDrop(item);
        }
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  );

  const isActive = canDrop && isOver;

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <div
      ref={drop}
      className={cn(
        className,
        'flex w-full items-center justify-center rounded border-2 border-dashed border-gray-100',
        isActive ? 'border-gray-500 bg-gray-300' : '',
        !isMobile() ? 'h-full ' : 'py-4',
      )}>
      <div className="w-52">
        {dragIcon && !isMobile() && (
          <img
            src="/img/drag-n-drop.svg"
            alt="drag and drop"
            style={{
              width: 70,
              height: 60,
              marginBottom: 15,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        )}
        <p className="text-center text-paragraph">
          {isActive ? dropLabel : label}
        </p>
        <SimpleTooltip
          className="mb-2"
          label={
            <>
              <div className="mb-2">
                <p>{t(translations.DROPZONE.VALID_FILES)}</p>
                <strong>{validFiles}</strong>
              </div>
              {recommendedDimmensions && recommendedDimmensions.length > 0 && (
                <div>
                  <p>{t(translations.DROPZONE.RECOMMENDED_SIZE)}</p>
                  <strong>{recommendedDimmensions}</strong>
                </div>
              )}
              {recommendedSize && recommendedSize.length > 0 && (
                <div>
                  <p>{t(translations.DROPZONE.RECOMMENDED_SIZE)}</p>
                  <strong>{recommendedSize}</strong>
                </div>
              )}
            </>
          }
        />
        <div className="flex justify-center">
          <Button
            className="px-12 text-white"
            variant="solid"
            size="sm"
            onClick={handleClick}
            color={primaryColor}>
            {t(translations.COMMON.SEARCH)}
          </Button>
        </div>
      </div>
    </div>
  );
};
