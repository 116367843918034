import { clomp } from 'clomp';

export const ButtonContainer = clomp.div`
  flex
  items-center
  justify-center
  mb-4

  lg:justify-start
  lg:mb-8
`;
