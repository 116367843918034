import React from "react";
import { ISVGProps } from "types";

export const LinkedinIcon: React.FC<ISVGProps> = (props) => (
  <svg
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.314 0H.638A.637.637 0 0 0 0 .638v14.676c0 .353.285.638.638.638h14.676a.637.637 0 0 0 .638-.638V.638A.637.637 0 0 0 15.314 0ZM4.732 13.593H2.365V5.98h2.367v7.613ZM3.549 4.939a1.372 1.372 0 1 1 0-2.744 1.372 1.372 0 0 1 0 2.744Zm10.044 8.654h-2.365V9.89c0-.883-.016-2.018-1.23-2.018-1.23 0-1.42.961-1.42 1.954v3.767H6.215V5.98h2.27v1.04h.031c.315-.597 1.087-1.23 2.24-1.23 2.396 0 2.837 1.578 2.837 3.628v4.175Z"
      fill="#0292D0"
    />
  </svg>
);
