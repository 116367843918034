import { cx } from '@emotion/css';
import { useDebounce } from 'hooks/useDebounce';
import fp from 'lodash/fp';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { Base } from './components/Base';
import { getDynamicCSS, getTextareaCSSProps, TextareaSize } from './helpers';
import { ITextareaAtomicValue, ITextareaBase } from './typings';

export interface ITextareaProps extends ITextareaBase {
  /**
   * Debounce interval for `onChange` function, default is `0`.
   */
  debounceInterval?: number;
  /**
   * If `true`, this field will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, this field will be required by the form.
   */
  isRequired?: boolean;
  /**
   * On change event handler.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (v: any) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Field size, it will adjust the label font size if the textarea has one.
   */
  size: keyof typeof TextareaSize;
}

export const Textarea: React.FC<ITextareaProps> = (props): JSX.Element => {
  const {
    className,
    debounceInterval = 0,
    defaultValue,
    isDisabled = false,
    isRequired = false,
    isRounded = false,
    name,
    onChange,
    primaryColor,
    size = 'sm',
    value: v,
    ...rest
  } = props;

  const [value, setValue] = useState<ITextareaAtomicValue>(v || defaultValue);

  const [debounceValue, setDebounceValue] = useState<ITextareaAtomicValue>();

  const handleTeaxtareaChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>): void => {
      setDebounceValue(e?.target?.value);
      setValue(e?.target?.value);
    },
    [],
  );

  const textareaCSS = useMemo(
    () =>
      cx(
        getTextareaCSSProps(isDisabled, isRequired),
        getDynamicCSS(primaryColor, isDisabled),
        TextareaSize[size],
        className,
      ),
    [className, isDisabled, isRequired, isRounded, primaryColor, size],
  );

  useDebounce(
    () => {
      if (onChange && !fp.isNil(debounceValue)) onChange(debounceValue);
    },
    debounceInterval,
    [debounceValue],
  );

  return (
    <Base
      className={textareaCSS}
      disabled={isDisabled}
      id={name}
      name={name}
      onChange={handleTeaxtareaChange}
      required={isRequired}
      value={value}
      {...rest}
    />
  );
};
