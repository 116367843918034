import fp from 'lodash/fp';

export const isInvitationURL = fp.compose(
  fp.includes('sign_invitation'),
  fp.get(['location', 'pathname'])
);

/**
 * Extract the color, primary and lighten logo from the WL metadata.
 */
export const getWLMetadata = fp.pick(['color', 'logo', 'lightLogo', 'isRewardsAvailable', 'isUserRewardsAvailable']);

/**
 * Extract the color, primary and lighten logo from the WL metadata.
 */
export const getSettingsMetadata = fp.pick(['isRewardsAvailable', 'isUserRewardsAvailable']);

/**
 * Get the status code from an `AxiosError` object.
 */
export const getErrorStatusCode = fp.get(['response', 'status']);

/**
 * Validates is the error status code is `null` or `undefined`.
 */
export const isErrorStatusCodeNil = fp.compose(fp.isNil, getErrorStatusCode);
