import React from "react";
import { ISVGProps } from "types";

export interface IStepIconProps extends ISVGProps {
  /**
   * If `true`, the background will be gray otherwise will be white.
   */
  isDone?: boolean;
  /**
   * If `true`, will render a mobile resolution for this icon.
   */
  isMobile?: boolean;
}

export const StepIcon: React.FC<IStepIconProps> = ({
  isDone = false,
  isMobile = false,
  ...rest
}) => (
  <svg
    fill="none"
    height="1em"
    viewBox={isMobile ? "0 0 80 62" : "0 0 186 59"}
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {isMobile ? (
      <path
        d="M57.603 61H7.946c-4.63 0-7.516-5.023-5.182-9.023l10.473-17.954a6 6 0 0 0 0-6.046L2.763 10.023C.43 6.023 3.315 1 7.946 1h49.657a6 6 0 0 1 5.145 2.913l14.4 24a6 6 0 0 1 0 6.174l-14.4 24A6 6 0 0 1 57.603 61Z"
        fill={isDone ? '#E6E6E6' : '#FFF'}
        stroke="currentColor"
        strokeWidth={2}
      />
    ) : (
      <path
        d="M160.183 58H5.072c-3.444 0-5.278-4.062-3.001-6.645l15.85-17.984a6 6 0 0 0 .115-7.801L3.075 7.556C.91 4.949 2.764 1 6.152 1h154.031a4 4 0 0 1 3.052 1.414l20.763 24.5a4.001 4.001 0 0 1 0 5.172l-20.763 24.5A4 4 0 0 1 160.183 58Z"
        fill={isDone ? '#E6E6E6' : '#FFF'}
        stroke="currentColor"
        strokeWidth={2}
      />
    )}
  </svg>
);
