/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ChevronDownIcon } from '@design-system';
import { SelectFontSize, SelectSize } from './select-helpers';

/**
 * Select's styles when the `isRequired` prop is `true`.
 */
const REQUIRED_CSS = {
  color: '#D0011B',
  content: '"*"',
  display: 'inline-block',
  left: '0.25rem',
  position: 'relative',
  top: '-3px',
};

/**
 * Dropdown indicator custom styles depending on the `size` prop.
 */
const INDICATOR_STYLING = {
  lg: { className: 'mr-5', color: '#8E8A8A', height: 10, width: 16 },
  md: { className: 'mr-4', color: '#8E8A8A', height: 8, width: 14 },
  sm: { className: 'mr-3', color: '#8E8A8A', height: 6, width: 12 },
  xl: { className: 'mr-5', color: '#8E8A8A', height: 10, width: 16 },
  xs: { className: 'mr-3', color: '#8E8A8A', height: 6, width: 12 },
};

/**
 * Replace the default dropdown indicator.
 * @param size - select's size.
 * @returns - custom components that will be used in the dropdown indicator.
 */
export const getSelectComponents = (size: keyof typeof SelectSize) => ({
  DropdownIndicator: () => <ChevronDownIcon {...INDICATOR_STYLING[size]} />,
  IndicatorSeparator: () => null,
});

/**
 * Overrides the default `react-select` styles.
 * @param {string} color - hex., theme color.
 * @param {boolean} isRequired - if `true`, it will have a custom red `*`.
 * @param {boolean} isRounded - if `true`, the field will have rounded borders.
 * @param {keyof typeof SelectSize} size - select's size.
 * @returns - custom `react-select` styles.
 */
export const getCustomCSS = (
  color: string,
  isRequired: boolean,
  isRounded: boolean,
  size: keyof typeof SelectSize,
) => ({
  control: (styles: any) => ({
    ...styles,
    '&:active': {
      ...styles[':active'],
      borderColor: color,
    },
    '&:hover': {
      ...styles[':hover'],
      borderColor: color,
    },
    backgroundColor: '#FFF',
    borderColor: '#E9E9E9',
    borderRadius: isRounded ? 50 : 0,
    borderWidth: 2,
    boxShadow: 'none',
    fontSize: SelectFontSize[size],
    minHeight: SelectSize[size],
    width: '100%',
  }),
  option: (styles: any) => ({
    ...styles,
    ':active': {
      ...styles[':active'],
      backgroundColor: '#FFF',
      borderColor: color,
    },
    ':hover': {
      backgroundColor: '#E5E5E5',
    },
    backgroundColor: '#FFF',
    color: '#000',
    fontSize: SelectFontSize[size],
  }),
  placeholder: (styles: any) => ({
    ...styles,
    '&:after': isRequired ? REQUIRED_CSS : {},
    color: '#888888',
    margin: '0 0 0 8px',
  }),
  singleValue: (styles: any) => ({ ...styles }),
});
