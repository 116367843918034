import React, { HTMLProps, useState } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { Button } from '@design-system';
import { InputField, CheckboxField, Label } from '@design-system/inputs';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DEFAULT_VALUES,
  ICheckboxFieldFormValuesProps,
  CheckboxFieldFormValidationSchema,
  FIELD_FLAGS,
} from './helpers';
import { Form } from './components/Form';

export interface ICheckboxFieldFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Form default values.
   */
  defaultValues: ICheckboxFieldFormValuesProps;
  /**
   * On submit action handler.
   */
  onSubmit: (v: ICheckboxFieldFormValuesProps) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Set is toolbar open
   */
  setIsOpen: (value: boolean) => void;
}

export const CheckboxFieldForm: React.FC<ICheckboxFieldFormProps> = (
  props,
): JSX.Element => {
  const {
    className,
    defaultValues,
    onSubmit,
    primaryColor,
    setIsOpen,
    ...rest
  } = props;
  const [isRequired, setIsRequired] = useState(defaultValues.is_required);
  const [isReadOnly, setIsReadOnly] = useState(defaultValues.is_read_only);
  const [enabled, setEnabled] = useState(defaultValues.default_value);

  const { control, formState, handleSubmit } =
    useForm<ICheckboxFieldFormValuesProps>({
      defaultValues,
      mode: 'all',
      resolver: yupResolver(CheckboxFieldFormValidationSchema),
    });

  const { t } = useTranslation();

  const handleFormSubmit = (data, e) => {
    e.preventDefault();
    onSubmit({
      ...data,
      default_value: enabled,
      is_read_only: isReadOnly,
      is_required: isRequired
    });
  };

  const handleFlagChange = (val, flag) => {
    if (flag === 'is_required') {
      setIsRequired(val);
    }

    if (flag === 'is_read_only') {
      setIsReadOnly(val);
    }
  };

  const checkboxOnChange = (value) => {
    setEnabled(value);
  }

  return (
    <Form
      className={className}
      onSubmit={handleSubmit((data, e) => handleFormSubmit(data, e))}
      {...rest}>
      <InputField
        label={t(translations.INPUTS.TEXT_FIELD_FORM_FIELD_NAME)}
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="name"
        placeholder={t(translations.INPUTS.TEXT_FIELD_FORM_FIELD_NAME)}
        primaryColor={primaryColor}
        size="sm"
        type="text"
      />
      <CheckboxField
        label={t(translations.INPUTS.TEXT_FIELD_FORM_DEFAULT_VALUE)}
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="default_value"
        primaryColor={primaryColor}
        size="sm"
        type="text"
        onChange={checkboxOnChange}
        defaultValue={defaultValues.default_value}
      />

      <div className="w-full">
        <Label className={cn('text-sm')}>
          {t(translations.INPUTS.TEXT_FIELD_FORM_FIELD_FLAGS_TITLE)}
        </Label>
        <div className='mt-2'>
          {FIELD_FLAGS.map((flag) => {

            const isDisabled = () => {
              if (flag.val === 'is_required' && isReadOnly) {
                return true
              }

              if (flag.val === "is_read_only" && isRequired) {
                return true
              }

              return false;
            }

            const defaultValue = () => {
              if (flag.val === 'is_required' && isRequired) {
                return true
              }

              if (flag.val === "is_read_only" && isReadOnly) {
                return true
              }

              return false
            }
            return (
              <CheckboxField
                key={flag.name}
                label={t(flag.name)}
                name={flag.val}
                defaultValue={defaultValue()}
                disabled={isDisabled()}
                onChange={(val) => handleFlagChange(val, flag.val)}
              />
            );
          })}
        </div>

      </div>

      <div className="flex w-full justify-end">
        <Button
          className="w-full md:mr-2 md:w-[75px]"
          color={primaryColor}
          onClick={() => setIsOpen(false)}
          size="sm"
          variant="outline"
          type="button">
          {t(translations.COMMON.CANCEL)}
        </Button>
        <Button
          className="w-full md:w-[75px]"
          color={primaryColor}
          isDisabled={formState?.isSubmitting || !formState?.isValid}
          isLoading={formState?.isSubmitting}
          variant="solid"
          size="sm"
          type="submit">
          <span className="text-white">{t(translations.COMMON.SAVE)}</span>
        </Button>
      </div>
    </Form>
  );
};
