import React from 'react';
import { Image } from '@design-system';
import { avatarClass, imageClass } from './styles';

export enum AvatarSize {
  '2xs' = '2xs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export interface AvatarProps {
  /**
   * Size of the Avatar
   */
  size?: AvatarSize;

  /**
   * Alt text of the avatar image
   */
  alt: string;

  /**
   * URL of the avatar image
   */
  src?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  size = AvatarSize.xs,
  alt,
  src = '/img/adamantium.png',
  className,
}) => {
  return (
    <div className={avatarClass(className, size)}>
      <Image
        className={imageClass(size)}
        src={src}
        alt={alt}
        noImg={<></>}
        style={{
          textIndent: '-9999px',
          opacity: 1,
          width: '100%',
          height: '100%',
          display: 'block',
          margin: '0 auto',
        }}
      />
    </div>
  );
};
