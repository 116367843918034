import React from 'react';
import { cx } from '@emotion/css';
import fp from 'lodash/fp';
import { Control, useController } from 'react-hook-form';

import { ITextareaProps, Textarea } from '../BaseInputs';
import {} from '../../layout';
import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelFontSize,
  Stack
} from '@design-system';
import { REQUIRED_LABEL_CSS } from './helpers';
import { useTranslation } from 'react-i18next';

export interface ITextareaFieldProps extends ITextareaProps {
  /**
   * RHF `control` object provided by invoking useForm. This prop is optional
   * when using FormProvider.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any, any>;
  /**
   * Field helper text that will be displayed below the `textarea`.
   */
  helper?: string;
  /**
   * Custom CSS tailwind styles for the `textarea` tag.
   */
  textareaClassName?: string;
  /**
   * Field label, can be a raw text or an i18n key.
   */
  label?: string;
  /**
   * Custom CSS tailwind styles for the label tag related to this field.
   */
  labelClassName?: string;
  /**
   * Field name that RHF will use to identify the field on the HTML code.
   */
  name: string;
}

export const TextareaField: React.FC<ITextareaFieldProps> = (
  props
): JSX.Element => {
  const {
    autoComplete = 'on',
    className,
    control,
    debounceInterval = 0,
    defaultValue,
    helper,
    textareaClassName,
    isDisabled = false,
    isRequired = false,
    isRounded = true,
    label,
    labelClassName,
    name,
    placeholder,
    primaryColor,
    size = 'sm',
    type = 'text',
  } = props;

  const { field, fieldState } = useController({ control, defaultValue, name });

  const { t } = useTranslation();

  return (
    <Stack className={cx(className)}>
      {!fp.isNil(label) && !isRequired ? (
        <FormLabel
          className={cx(FormLabelFontSize[size], labelClassName)}
          htmlFor={name}
        >
          {label}
        </FormLabel>
      ) : null}

      <div className="h-full relative">
        <Textarea
          autoComplete={autoComplete}
          className={textareaClassName}
          debounceInterval={debounceInterval}
          id={name}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isRounded={isRounded}
          placeholder={placeholder}
          primaryColor={primaryColor}
          size={size}
          type={type}
          {...field}
        />

        {isRequired ? (
          <FormLabel.Required
            className={cx(REQUIRED_LABEL_CSS, FormLabelFontSize[size])}
            htmlFor={name}
          >
            {label}
          </FormLabel.Required>
        ) : null}
      </div>

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {t(fieldState?.error?.message as string)}
      </FormErrorMessage>
    </Stack>
  );
};

TextareaField.defaultProps = {
  labelClassName: 'mb-1',
};
