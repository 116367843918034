import React, { HTMLProps, useState } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { Button } from '@design-system';
import { InputField, CheckboxField, Label } from '@design-system/inputs';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DEFAULT_VALUES,
  ISignFieldFormValuesProps,
  SignFieldFormValidationSchema,
  FIELD_FLAGS,
} from './helpers';
import { Form } from './components/Form';

export interface ISignFieldFormProps
  extends Omit<HTMLProps<HTMLElement>, 'onSubmit'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Form default values.
   */
  defaultValues: ISignFieldFormValuesProps;
  /**
   * On submit action handler.
   */
  onSubmit: (v: ISignFieldFormValuesProps) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Set is toolbar open
   */
  setIsOpen: (value: boolean) => void;
}

export const SignFieldForm: React.FC<ISignFieldFormProps> = (
  props,
): JSX.Element => {
  const {
    className,
    defaultValues,
    onSubmit,
    primaryColor,
    setIsOpen,
    ...rest
  } = props;
  const [fieldFlags, setFieldFlags] = useState<string[]>(
    defaultValues.fieldFlags,
  );
  const { control, formState, handleSubmit } =
    useForm<ISignFieldFormValuesProps>({
      defaultValues,
      mode: 'all',
      resolver: yupResolver(SignFieldFormValidationSchema),
    });

  const { t } = useTranslation();

  const handleFormSubmit = (data, e) => {
    e.preventDefault();
    onSubmit({
      ...data,
      fieldFlags,
    });
  };

  const handleFlagChange = (val, flag) => {
    const currentFlags: string[] = [...new Set(fieldFlags)];
    if (val) {
      currentFlags.push(flag);
      setFieldFlags([...new Set(currentFlags)]);
    } else {
      const foundFlag = currentFlags.indexOf(flag);
      if (foundFlag > -1) {
        currentFlags.splice(foundFlag, 1);
        setFieldFlags([...new Set(currentFlags)]);
      }
    }
  };

  return (
    <Form
      className={className}
      onSubmit={handleSubmit((data, e) => handleFormSubmit(data, e))}
      {...rest}>
      <InputField
        label={t(translations.INPUTS.TEXT_FIELD_FORM_FIELD_NAME)}
        className="mb-4 w-full md:w-[300px]"
        control={control}
        name="fieldName"
        placeholder={t(translations.INPUTS.TEXT_FIELD_FORM_FIELD_NAME)}
        primaryColor={primaryColor}
        size="sm"
        type="text"
      />

      <div className="w-full">
        <Label className={cn('text-sm')}>
          {t(translations.INPUTS.TEXT_FIELD_FORM_FIELD_FLAGS_TITLE)}
        </Label>

        {FIELD_FLAGS.map((flag) => {
          return (
            <CheckboxField
              key={flag}
              label={t(flag)}
              name={flag}
              // ref={register}
              defaultValue={fieldFlags.includes(flag)}
              onChange={(val) => handleFlagChange(val, flag)}
            />
          );
        })}
      </div>

      <div className="flex w-full justify-end">
        <Button
          className="w-full md:mr-2 md:w-[75px]"
          color={primaryColor}
          onClick={() => setIsOpen(false)}
          size="sm"
          variant="outline"
          type="button">
          {t(translations.COMMON.CANCEL)}
        </Button>
        <Button
          className="w-full md:w-[75px]"
          color={primaryColor}
          isDisabled={formState?.isSubmitting || !formState?.isValid}
          isLoading={formState?.isSubmitting}
          variant="solid"
          size="sm"
          type="submit">
          <span className="text-white">{t(translations.COMMON.SAVE)}</span>
        </Button>
      </div>
    </Form>
  );
};
