import React from "react";
import { ISVGProps } from "types";

export const ZoomoutIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#zoomout-icon_svg__a)" fill="currentColor">
      <path d="m19.525 17.134-4.776-4.775a8.013 8.013 0 1 0-2.383 2.385l4.773 4.775a1.646 1.646 0 0 0 2.318 0l.068-.067a1.645 1.645 0 0 0 0-2.318ZM12.33 12.33A6.103 6.103 0 1 1 3.7 3.698a6.103 6.103 0 0 1 8.63 8.632Z" />
      <path d="M10.346 6.923h-4.66a.918.918 0 0 0-.917.917v.409c0 .507.41.917.917.917h4.66c.507 0 .918-.41.918-.917V7.84a.918.918 0 0 0-.918-.917Z" />
    </g>
    <defs>
      <clipPath id="zoomout-icon_svg__a">
        <path d="M0 0h20v19.998H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
