import { clomp } from 'clomp';

export const Base = clomp.div`
  bg-white
  border-2
  border-gray-100
  flex
  flex-col
  gap-4
  items-center
  justify-center
  p-5
  rounded-2xl
  w-full

  lg:flex-row-reverse
  lg:items-start
  md:p-10
`;
