import { clomp } from 'clomp';

export const CustomInput = clomp.input`
  border-2
  border-gray-100
  bg-white
  p-2
  px-4
  outline-none
  placeholder-primary
  w-full

  focus:outline-none
`;
