import { clomp } from 'clomp';

export const CategoryGrid = clomp.div`
  flex
  flex-nowrap
  gap-1
  justify-center
  overflow-scroll

  lg:justify-start
`;
