import React from 'react';
import cn from 'classnames';
import { Container } from './components/Container';
import { Paragraph } from './components/Paragraph';

export interface IEmptyDataProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
}

export const EmptyData: React.FC<IEmptyDataProps> = (props): JSX.Element => {
  const { children, className } = props;

  return (
    <Container className={cn(className)}>
      <Paragraph>
        {children}
      </Paragraph>
    </Container>
  );
}
