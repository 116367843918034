/** @jsx jsx */
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useContext,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { useDebounce } from 'hooks/useDebounce';
import fp from 'lodash/fp';
import { WLContext } from '@contexts';
import { jsx } from '@emotion/react';

export enum TextInputSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum TextInputType {
  text = 'text',
  password = 'password',
  first_name = 'first_name',
  last_name = 'last_name',
  email = 'email',
}

export enum TextInputVariant {
  default = 'default',
  rounded = 'rounded',
}

export interface TextInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /**
   * Input identifier.
   */
  id?: string;
  /**
   * Input default value.
   */
  defaultValue?: string | number;
  /**
   * Debounce interval for `onChange` function, default is `0`.
   */
  debounceInterval?: number;
  /**
   * If `true`, the input field will be in a disabled state.
   */
  disabled?: boolean;
  /**
   * Extends the component to 100% width.
   */
  isFullWidth?: boolean;
  /**
   * If `true, the input field cannot be modified.
   */
  isReadOnly?: boolean;
  /**
   * Form input reference.
   */
  name: string;
  /**
   * Provide a handler that is called whenever <input> is updated.
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * A hint to the user of what can be entered in the control.
   */
  placeholder?: string;
  /**
   * Changes the size of the text input, giving it more or less padding.
   */
  size?: TextInputSize;
  /**
   * HTML type attribute of the text input.
   */
  type?: TextInputType;
  /**
   * Input variant.
   */
  variant?: TextInputVariant;
}

export const TextInput = React.forwardRef<any, TextInputProps>((props, ref) => {
  const {
    className,
    debounceInterval = 0,
    defaultValue = '',
    disabled = false,
    id = 'textInput',
    isFullWidth = false,
    isReadOnly = false,
    name,
    onChange,
    placeholder = '',
    size = TextInputSize.md,
    type = TextInputType.text,
    variant = 'rounded',
    required = false,
  } = props;

  const { color } = useContext(WLContext);

  const textInputClass = cn(
    className,
    'textfield__input ec-text-input border border-gray-100 bg-basic-white p-2 px-4 outline-none focus:outline-none focus:border-2 placeholder-primary',
    {
      [`ec-text-input--${size}`]: true,
      'ec-text-input--block w-full': isFullWidth,
      'rounded-3xl': variant === 'rounded',
    },
    disabled && 'bg-gray-200 opacity-70',
  );

  const labelClass = cn(
    'textfield__label absolute top-[10px] left-[15px] block cursor-text select-none',
    required ? 'textfield__label--required' : '',
  );

  const [value, setValue] = useState(defaultValue);

  const [event, setEvent] = useState<ChangeEvent<HTMLInputElement>>();

  useDebounce(() => {
    if (onChange && !fp.isNil(event)) onChange(event);
  }, debounceInterval, [event])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e?.target?.value);
    setEvent(e)
  };

  // Update value if default value changes
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <fieldset className="textfield relative">
      <input
        ref={ref}
        id={id}
        type={type}
        className={textInputClass}
        name={name}
        value={value}
        placeholder=" "
        onChange={handleInputChange}
        autoComplete="off"
        readOnly={isReadOnly}
        disabled={disabled}
        required={required}
        pattern=".*\S.*"
        css={{
          '&:focus': {
            borderColor: color as string,
          },
        }}
      />
      <label htmlFor={id} className={labelClass}>
        {placeholder}
      </label>
    </fieldset>
  );
});

TextInput.displayName = 'TextInput';
