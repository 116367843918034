/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';
import { clomp } from 'clomp';
import fp from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { IPropsOf } from 'types.d';
import { BrowseFileIcon, Button } from '@design-system';
import { css } from '../helpers';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

const Container = clomp.div`
  border-2
  border-dashed
  border-gray-100
  flex
  flex-1
  h-full
  items-center
  justify-center
  p-4
  rounded-2xl
  w-full

  ${({ isInline }) => (isInline ? 'flex-row-reverse' : 'flex-col')}
`;

export interface IDndContainerProps extends IPropsOf<'div'> {
  /**
   * If `true`, the button and labels will be on a row direction if and only if
   * the input doesn't have a value, otherwise in order to not break the layout
   * in mobile and tablet devices it will take a column direction.
   */
  isInline?: boolean;
  /**
   * On change event that will mutate the RHF state.
   */
  onChange: (...event: any[]) => void;
  /**
   * On click event that will trigger the file API select method.
   */
  onClick: () => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Additional text that will be rendered inside the dnd container.
   */
  quote?: string;
  /**
   * Current input value.
   */
  value?: File;
  /**
   * if `true`, it will show a helper icon inside the dnd container.
   */
  withBrowseIcon?: boolean;
}

export const DndContainer: React.FC<IDndContainerProps> = (
  props,
): JSX.Element => {
  const {
    isInline,
    onChange,
    onClick,
    primaryColor,
    quote,
    value,
    withBrowseIcon,
  } = props;

  const [{ canDrop, isOver }, dragRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      drop({ files }: { files: File[] }) {
        fp.compose(onChange, fp.head)(files);
      },
    }),
    [],
  );

  const { t } = useTranslation();

  const isActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);

  const getLabel = useCallback(() => {
    if (isActive) return 'Suelta tu archivo aquí';
    if (fp.isNil(value)) return t(translations.COMMON.NO_SELECTED_FILES);
    if (fp.isString(value)) return value;
    return value?.name;
  }, [isActive, value]);

  return (
    <Container
      ref={dragRef}
      className={cn({ [css.selected]: isActive })}
      isInline={isInline}
    >
      {withBrowseIcon && !isInline ? (
        <BrowseFileIcon
          className="mb-6"
          color="#C4C4C4"
          height={60}
          width={70}
        />
      ) : null}

      <p className={cn(css.helper, { 'ml-4 !text-left': isInline })}>
        {!fp.isNil(quote) ? (
          <span className={cn({ 'block mb-2': !isInline })}>{quote}</span>
        ) : null}

        <span className={cn('max-w-28', { 'block w-auto': isInline })}>
          {getLabel()}
        </span>
      </p>

      <Button
        className={cn(css.button, { 'mt-6': !isInline })}
        onClick={onClick}
        color={primaryColor}
        size="xs"
        variant="solid"
      >
        {t(translations.COMMON.SEARCH)}
      </Button>
    </Container>
  );
};
