import { WLContext } from '@contexts';
import { cx, css } from '@emotion/css';
import React, { useContext, useState } from 'react';

export interface CheckboxProps {
  className?: string;
  label: string;
  onActive?: () => void;
  onChange?: any;
  defaultValue?: boolean;
  disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className = '',
  onActive,
  onChange,
  defaultValue = true,
  label = '',
  disabled = false,
}) => {
  const [active, setActive] = useState<boolean>(defaultValue);
  const { color } = useContext(WLContext);
  const handleCheckbox = () => {
    if (onActive && !active) onActive();
    if (onChange) onChange(!active);
    setActive((prev) => !prev);
  };

  return (
    <div className={className}>
      <label className="flex cursor-pointer items-center">
        <input
          onClick={handleCheckbox}
          id="customCheckLogin"
          type="checkbox"
          checked={active}
          className={cx(
            'form-checkbox ml-1 h-5 rounded border text-primary-300 transition-all duration-150 ease-linear',
            css`
              accent-color: ${color};
              width: 20px !important;
            `,
          )}
          {...(disabled ? { disabled: 'true' as any } : {})}
        />
        <span className="font-semibold text-blueGray-600 ml-2 text-sm w-full">
          {label}
        </span>
      </label>
    </div>
  );
};
