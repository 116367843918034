import { cx } from '@emotion/css';
import React, { useMemo } from 'react';
import { IPropsOf } from 'types';

import { Base } from './components/Base';
import { ChipSize, getDynamicCSS } from './helpers';

export interface IChipProps extends IPropsOf<'div'> {
  /**
   * Chip border and text color.
   */
  color: string;
  /**
   * Chip size. Can be one of the following options:
   * "xxs" | "xs" | "sm" | "md" | "lg" | "xl".
   */
  size?: keyof typeof ChipSize;
  /**
   * Alternative text color.
   */
  textColor?: string;
}

export const Chip: React.FC<IChipProps> = (props): JSX.Element => {
  const { className, color, children, size = 'md', textColor, ...rest } = props;

  const css = useMemo(
    () => cx(getDynamicCSS(color, textColor), ChipSize[size], className),
    [className, color, size],
  );

  return (
    <Base className={css} {...rest}>
      <p className="whitespace-nowrap">{children}</p>
    </Base>
  );
};
