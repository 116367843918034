import React from 'react';
import {
  Button,
  FileField,
  InputField,
  SelectField,
  TextareaField
} from "@design-system";
import { ISelectAtomicOption } from "@design-system/inputs/BaseInputs";
import { useForm } from "react-hook-form";
import { IPropsOf } from "types";
import { IReward } from '@cincel/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationSchema } from './helpers';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

export interface IRewardsFormProps extends IPropsOf<'form'> {
  /**
   * List of categories.
   */
  categories?: ISelectAtomicOption[];
  /**
   * List of countries.
   */
  countries?: ISelectAtomicOption[];
  /**
   * Initial default values.
   */
  defaultValues?: IReward;
  /**
   * If `true`, the button will render the edit label.
   */
  isEdit?: boolean;
  /**
   * On submit action handler.
   */
  onSubmit: (v: Partial<IReward>) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
}

export const RewardsForm: React.FC<IRewardsFormProps> = (
  props
): JSX.Element => {
  const {
    categories,
    countries,
    defaultValues,
    isEdit = false,
    onSubmit,
    primaryColor,
    ...rest
  } = props;

  const { control, formState, handleSubmit } = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(ValidationSchema),
  });

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      <div className="flex flex-col justify-around md:flex-row">
        <div className="flex-1 mb-6 md:mb-0 md:mr-8">
          <InputField
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            isRequired
            label={t(translations.REWARDS.COMPANY_NAME_LABEL)}
            name="company"
            primaryColor={primaryColor}
            size="sm"
            type="text"
          />

          <FileField
            accept='.png'
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            isInline
            label={t(translations.REWARDS.COMPANY_LOGO_LABEL)}
            labelClassName="mb-2 text-black"
            labelFontSize="md"
            name="company_logo"
            quote="100 x 100 px"
            primaryColor={primaryColor}
          />

          <SelectField
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            isMulti
            name="categories"
            isRequired
            options={categories || []}
            placeholder={t(translations.COMMON.CATEGORIES)}
            primaryColor={primaryColor}
          />

          <InputField
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            isRequired
            label={t(translations.REWARDS.BENEFIT_LABEL)}
            name="benefits"
            primaryColor={primaryColor}
            size="sm"
            type="text"
          />

          <InputField
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            isRequired
            label={t(translations.REWARDS.URL_LABEL)}
            name="url"
            primaryColor={primaryColor}
            size="sm"
            type="text"
          />

          <FileField
            accept='.png'
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            isInline
            label={t(translations.REWARDS.COVER_LABEL)}
            labelClassName="mb-2 text-black"
            labelFontSize="md"
            name="image"
            quote="500 x 500 px"
            primaryColor={primaryColor}
          />

          <SelectField
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            name="country"
            isRequired
            options={countries || []}
            placeholder={t(translations.REWARDS.COUNTRIES_LABEL)}
            primaryColor={primaryColor}
          />

          <InputField
            className="max-w-full md:max-w-[374px] mb-6"
            control={control}
            placeholder={(t(translations.REWARDS.COUPON_LABEL))}
            name="coupon"
            primaryColor={primaryColor}
            size="sm"
            type="text"
          />

          <InputField
            className="max-w-full md:max-w-[374px]"
            control={control}
            isRequired
            label={t(translations.REWARDS.PRIORITY_LABEL)}
            name="priority"
            primaryColor={primaryColor}
            size="sm"
            type="text"
          />
        </div>

        <div className="flex flex-col flex-1">
          <TextareaField
            className="mb-6"
            control={control}
            isRequired
            label={t(translations.REWARDS.COMPANY_SUMMARY_LABEL)}
            name="company_summary"
            primaryColor={primaryColor}
            size="sm"
          />

          <TextareaField
            className="flex-1"
            control={control}
            isRequired
            label={t(translations.REWARDS.DETAILS_LABEL)}
            name="detail"
            primaryColor={primaryColor}
            size="sm"
            textareaClassName='h-full'
          />
        </div>
      </div>

      <Button
        className="float-right md:w-auto mt-6 px-8 w-full"
        color={primaryColor}
        isDisabled={formState?.isSubmitting || !formState?.isValid}
        isLoading={formState?.isSubmitting}
        size="sm"
        type="submit"
      >
        {t(isEdit ? translations.REWARDS.BUTTON_EDIT_REWARD : translations.REWARDS.BUTTON_CREATE_REWARD)}
      </Button>
    </form>
  );
}
