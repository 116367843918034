import { createContext } from 'react';
import { IBaseAsyncFnState } from 'types';

export interface IWLContextProps extends IBaseAsyncFnState {
  /**
   * White Label primary color.
   */
  color: string;
  /**
   * Async function that will request to the API the account's white label info.
   * @param {null | number | string} id - logged user or org owner.
   */
  fetchWL: (id: null | number | string) => Promise<void>;
  /**
   * Fetches the global settings first and updates the value depending on the
   * user's account settings.
   */
  fetchSettings: (id: number | string) => Promise<void>;
  /**
   * Fetching the WL's information consists of three steps: fetch the global
   * assets, fetch the account assets and validate data, if some asset is
   * missing, then fallback to the default value, with that being said we need
   * a "global" loading state for this task. If `true`, then the fetch is
   * in process.
   */
  isFetching: boolean;
  /**
   * If `true`, the rewards section will be rendered on the application.
   */
  isRewardsAvailable: boolean;
  /**
   * If `true`, the rewards will be available to the users in my organization.
   */
  isUserRewardsAvailable: boolean;
  /**
   * White label lighten logo.
   */
  lightLogo: string;
  /**
   * White label primary logo.
   */
  logo: string;
  /**
   * Async function that will request to the API the account settings of the
   * user specified in the `id` param. This function will be useful for fetch
   * data of the organization / document owner.
   */
  fetchUserSettings: (id: number | string) => Promise<void>;
  /**
   * Async function that will request to the API the account's white label info.
   * Similar to `fetchWL` but this method doesn't mutate the `isFetching flag`.
   * @param {null | number | string} id - logged user or org owner.
   */
  softFetchWL: (id: null | number | string) => Promise<void>;
  /**
   * Async function that will update the White Label account metadata.
   * @param {T} p - white label payload.
   */
  // TODO: remove the `any` type when connecting to backend.
  updateWL: (p: any) => Promise<void>;
}

export const WLContext = createContext({} as IWLContextProps);
