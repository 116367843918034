import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput, TextInputType, Select } from '@design-system';
import { useClient } from 'hooks/useClient';
import { useTranslation } from 'react-i18next';
import { translations } from 'translations';

interface EditProfileFields {
  name: string;
  avatar: string;
  email: string;
  language: string;
}

export interface EditProfileProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: any;
  onChange?: any;
  /**
   * Current language
   */
  currentLanguage: string;
  /**
   * Current WL color
   */
  color: string;
  onSuccess?: (data: EditProfileFields) => any;
}

const schema = yup.object().shape({
  name: yup.string(),
  email: yup.string(),
  language: yup.string(),
});

export const EditProfile: React.FC<EditProfileProps> = ({
  className,
  onChange,
  currentLanguage = 'es',
  color,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const languageOptions = [
    {
      value: 'es',
      label: t(translations.COMMON.SPANISH),
    },
    {
      value: 'en',
      label: t(translations.COMMON.ENGLISH),
    },
  ];

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EditProfileFields>({
    resolver: yupResolver(schema) as any,
  });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const onSubmit = async (data: EditProfileFields) => {
    if (onSuccess) await onSuccess(data);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userCincel') || '{}');
    setName(user.name);
    setEmail(user.email);
  }, []);

  useEffect(() => {
    const subscription = watch((value) =>
      onChange({
        ...value,
      }),
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4 flex flex-col">
        <h5>{t(translations.INPUTS.NAME)}</h5>
        <Controller
          name="name"
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                id={field.name}
                name={field.name}
                defaultValue={name}
                onChange={field.onChange}
                type={TextInputType.text}
                isFullWidth
                placeholder={t(translations.INPUTS.NAME)}
                required
              />
            );
          }}
        />
        {errors.name && (
          <span className="text-red-300" role="alert">
            {errors.name.message}
          </span>
        )}
      </div>
      <div className="opa mb-4 flex flex-col">
        <h5>{t(translations.INPUTS.EMAIL)}</h5>
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                id={field.name}
                name={field.name}
                defaultValue={email}
                onChange={field.onChange}
                isFullWidth
                type={TextInputType.text}
                disabled
                placeholder={t(translations.INPUTS.EMAIL)}
              />
            );
          }}
        />
      </div>
      <div className="mb-4 flex flex-col">
        <p>{t(translations.INPUTS.SELECT_LANGUAGE)}</p>
        <Controller
          name="language"
          defaultValue={currentLanguage}
          control={control}
          render={({ name, ref, field }: any) => {
            return (
              <Select
                colorWL={color}
                {...field}
                name={name}
                className="w-full"
                value={languageOptions.find(
                  (lang) => lang.value === field.value,
                )}
                inputRef={ref}
                placeholder={t(translations.INPUTS.SELECT_LANGUAGE)}
                options={languageOptions}
              />
            );
          }}
        />
        {errors.language && (
          <span className="ml-1 mt-1 text-red-300" role="alert">
            {errors.language.message}
          </span>
        )}
      </div>
    </form>
  );
};
