import { clomp } from 'clomp';
import React from 'react';

import { IPropsOf } from 'types.d';

const Base = clomp.th`
  bg-gray-50
  font-medium
  py-3
  px-6
  text-gray-350
  text-left
  text-xs
  tracking-wider

  first-of-type:rounded-l-xl
  last-of-type:rounded-r-xl
`;

export interface IThProps extends IPropsOf<'th'> {
  /**
   * Specifies an abbreviated version of the content in a header cell.
   */
  abbr?: string;
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Specifies the number of columns a header cell should span.
   */
  colspan?: number;
  /**
   * Specifies the number of rows a header cell should span.
   */
  rowspan?: number;
  /**
   * Specifies whether a header cell is a header for a column,
   * row, or group of columns or rows
   */
  scope: 'col' | 'colground' | 'row' | 'rowground';
}

export const Th: React.FC<IThProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return <Base {...rest}>{children}</Base>;
};
