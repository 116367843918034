import React from "react";
import { ISVGProps } from "types";

export const CheckIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 101 101"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      height={97}
      rx={48.5}
      stroke="currentColor"
      strokeWidth={4}
      width={97}
      x={2}
      y={2}
    />
    <path
      d="M27 50.5 43 67l32-33"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5}
    />
  </svg>
);
