import React from 'react';
import { clomp } from 'clomp';
import cn from 'classnames';
import { Pagination, IPaginationProps } from '@design-system';
import { IThProps, Th } from './components/Th';
import { ITheadProps, Thead } from './components/Thead';
import { ITrProps, Tr } from './components/Tr';
import { ITbodyProps, Tbody } from './components/Tbody';
import { ITdProps, Td } from './components/Td';
import { ITdActionsProps, TdActions } from './libs/TdActions';
import { ITdFileProps, TdFile } from './libs/TdFile';
import { Container, IContainerProps } from './components/Container';
import { IPropsOf } from 'types';

export const Base = clomp.table`
  divide-gray-200
  divide-y
  min-w-full
`;

export type ITableProps = IPropsOf<'table'>;

export interface ITableCompoundProps {
  /**
   * Table default container.
   */
  Container: React.FC<IContainerProps>;
  /**
   * Table `tbody` element.
   */
  Tbody: React.FC<ITbodyProps>;
  /**
   * Table `td` element.
   */
  Td: React.FC<ITdProps>;
  /**
   * Extension of the `td` element that will render a download and a delete
   * options for the user.
   */
  TdActions: React.FC<ITdActionsProps>;
  /**
   * Extension of the `td` element with a redirect path prop and
   * a file icon on the left side of the component.
   */
  TdFile: React.FC<ITdFileProps>;
  /**
   * Table `th` element.
   */
  Th: React.FC<IThProps>;
  /**
   * Table `thead` element.
   */
  Thead: React.FC<ITheadProps>;
  /**
   * Table `tr` element.
   */
  Tr: React.FC<ITrProps>;
  /**
   * Pagination element
   */
  Pagination: React.FC<IPaginationProps>;
}

export const Table: React.FC<ITableProps> & ITableCompoundProps = (
  props,
): JSX.Element => {
  const { children, className } = props;

  return (
    <Base className={cn(className)}>
      <>{children}</>
    </Base>
  );
};

Table.Container = Container;

Table.Thead = Thead;

Table.Tbody = Tbody;

Table.Td = Td;

Table.Tr = Tr;

Table.Th = Th;

Table.TdActions = TdActions;

Table.TdFile = TdFile;

Table.Pagination = Pagination;
