import { css } from '@emotion/css';

/**
 * Custom CSS for a required input. This class will control the `input` itself.
 */
const INPUT_REQUIRED_CLASS: string = css`
  &::placeholder {
    color: transparent;
  }

  &:disabled + label,
  &:focus + label,
  &:valid + label {
    display: none;
  }
`;

/**
 * Calculates the input styles based on the `isDisabled`, `isRequired` and
 * `isRounded` properties.
 * @param {boolean} isDisabled - if `true`, it will have disable styles.
 * @param {boolean} isRequired - if `true`, it will have a custom red `*`.
 * @param {boolean} isRounded - if `true`, it will have rounded borders.
 * @returns {{ [i: string]: boolean }} - class names object.
 */
export const getInputCSSProps = (
  isDisabled: boolean,
  isRequired: boolean,
  isRounded: boolean,
): { [i: string]: boolean } => ({
  [INPUT_REQUIRED_CLASS]: isRequired,
  'bg-gray-50 cursor-not-allowed opacity-70': isDisabled,
  'rounded-full': isRounded,
});

/**
 * Calculates the input dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @param {boolean} isDisabled - if `true`, will avoid to change the border
 * color on hover.
 * @returns {string} - primary dynamic styles.
 */
export const getDynamicCSS = (
  color: string,
  isDisabled: boolean,
): string => css`
  transition: all ease 0.2s;

  &:focus {
    border-color: ${color};
  }

  &:hover {
    border-color: ${isDisabled ? 'inherit' : color};
  }
`;
