/**
 * Enum for the label font sizes.
 * @readonly
 * @enum {string}
 */
export enum FormLabelFontSize {
  xs = 'text-xs',
  sm = 'text-sm',
  md = 'text-md',
  lg = 'text-lg',
  xl = 'text-xl',
}
