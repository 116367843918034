import { cx } from '@emotion/css';
import { useDebounce } from 'hooks/useDebounce';
import fp from 'lodash/fp';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { Base } from './components/Base';
import { getDynamicCSS, getInputCSSProps, InputSize } from './helpers';
import { IInputAtomicValue, IInputBase } from './typings';

export interface IInputProps extends IInputBase {
  /**
   * Debounce interval for `onChange` function, default is `0`.
   */
  debounceInterval?: number;
  /**
   * If `true`, this field will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, this field will be required by the form.
   */
  isRequired?: boolean;
  /**
   * If `true`, the input will have rounded borders.
   */
  isRounded?: boolean;
  /**
   * On change event handler.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (v: any) => void;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Input size, it will adjust the label font size if the input has one.
   */
  size: keyof typeof InputSize;
}

export const Input: React.FC<IInputProps> = (props): JSX.Element => {
  const {
    className,
    debounceInterval = 0,
    defaultValue,
    isDisabled = false,
    isRequired = false,
    isRounded = true,
    name,
    onChange,
    primaryColor,
    size = 'sm',
    type = 'text',
    value: v,
    ...rest
  } = props;

  const [value, setValue] = useState<IInputAtomicValue>(v || defaultValue);

  const [debounceValue, setDebounceValue] = useState<IInputAtomicValue>();

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setDebounceValue(e?.target?.value);
      setValue(e?.target?.value);
    },
    [],
  );

  const inputCSS = useMemo(
    () =>
      cx(
        getInputCSSProps(isDisabled, isRequired, isRounded),
        getDynamicCSS(primaryColor, isDisabled),
        InputSize[size],
        className,
      ),
    [className, isDisabled, isRequired, isRounded, primaryColor, size],
  );

  useDebounce(
    () => {
      if (onChange && !fp.isNil(debounceValue)) onChange(debounceValue);
    },
    debounceInterval,
    [debounceValue],
  );

  return (
    <Base
      className={inputCSS}
      disabled={isDisabled}
      id={name}
      name={name}
      onChange={handleInputChange}
      required={isRequired}
      type={type}
      value={value}
      {...rest}
    />
  );
};
