import { clomp } from 'clomp';

export const Container = clomp.div`
  bg-white
  flex
  flex-col
  justify-between
  p-8

  md:pb-10
  md:pt-12

  xl:pt-16
`;
