import React from 'react';
export const Progressbar = ({ color, progress = 10 }) => {
  return (
    <div className="relative max-w-xl h-1 rounded-full overflow-hidden">
      <div className="absolute w-full h-full bg-gray-200"></div>
      <div
        className="absolute h-full"
        style={{ background: color, width: `${progress}%` }}></div>
    </div>
  );
};
