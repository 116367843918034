import { IWLContextProps } from '@contexts';
import { IBaseAsyncFnState } from 'types';

/**
 * Reducer's state. Pick all the `IWLContextProps` props except the `fetchWL`
 * and `updateWL` functions, those will be set directly in the provider.
 */
export type IUseWLReducerState = Pick<
  IWLContextProps,
  Exclude<keyof IWLContextProps, 'fetchWL' | 'fetchSettings' | 'fetchUserSettings' | 'softFetchWL' | 'updateWL'>
>;

/**
 * WL reducer initial state.
 * @type {IUseWLReducerState}
 */
export const INITIAL_STATE: IUseWLReducerState = {
  color: '#6344FF',
  error: false,
  isFetching: false,
  isRewardsAvailable: false,
  isUserRewardsAvailable: false,
  lightLogo: '/img/logo-blanco.svg',
  logo: '/img/logo.svg',
  loading: false,
};

/**
 * Reducer action's values.
 */
export interface IAction extends IUseWLReducerState {
  /**
   * Asset name that will help to get the fallback value in case of failure.
   */
  fallback: string;
  /**
   * Action type identifier.
   */
  type: keyof typeof IActionEnum;
}

/**
 * Reducer Actions.
 */
export enum IActionEnum {
  FETCH_ASSET = 'FETCH_ASSET',
  FETCH_ASSET_FAILURE = 'FETCH_ASSET_FAILURE',
  FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS',

  FETCH_SETTINGS = 'FETCH_SETTINGS',
  FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE',
  FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS',

  FETCH_SETTINGS_INIT = 'FETCH_SETTINGS_INIT',
  FETCH_SETTINGS_FINISH = 'FETCH_SETTINGS_FINISH',

  FETCH_WL_INIT = 'FETCH_WL_INIT',
  FETCH_WL_FINISH = 'FETCH_WL_FINISH',

  UPDATE_WL = 'UPDATE_WL',
  UPDATE_WL_FAILURE = 'UPDATE_WL_FAILURE',
  UPDATE_WL_SUCCESS = 'UPDATE_WL_SUCCESS',
}

/**
 * Valid asset names that the API accept.
 */
export enum IAssetNameEnum {
  COLOR = 'color.primary.txt',
  LOGO = 'logo.dark.png',
  LIGHT_LOGO = 'logo.light.png',
}

/**
 * Function that will mutate the reducer state.
 * @param {IUseWLReducerState} ps - previous state.
 * @param {IAction} a - reducer action handler.
 */
export type IMutationFn = (ps: IUseWLReducerState, a: IAction) => void;

/**
 * Values to mutate when an async operation is triggered.
 * @type {IBaseAsyncFnState}
 */
export const BASE_INIT: IBaseAsyncFnState = {
  error: false,
  loading: true
};

/**
 * Values to mutate when an async is succesfully resolved.
 * @type {IBaseAsyncFnState}
 */
export const BASE_SUCCESS: IBaseAsyncFnState = {
  error: false,
  loading: false
};

/**
 * Values to mutate when an async fails.
 * @type {IBaseAsyncFnState}
 */
export const BASE_FAILURE: IBaseAsyncFnState = {
  error: true,
  loading: false
};
