/**
 * Enum for textarea sizes.
 * @readonly
 * @enum {string}
 */
export enum TextareaSize {
  xs = 'text-xs',
  sm = 'text-sm',
  md = 'text-md',
  lg = 'text-lg',
  xl = 'text-xl',
}
