import React from "react";
import { ISVGProps } from "types";

export const ZoominIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#zoomin-icon_svg__a)" fill="currentColor">
      <path d="m19.524 17.134-4.783-4.775a8.013 8.013 0 1 0-2.383 2.385l4.785 4.775a1.646 1.646 0 0 0 2.319 0l.067-.067a1.645 1.645 0 0 0-.005-2.318Zm-7.185-4.804a6.103 6.103 0 1 1-8.63-8.632 6.103 6.103 0 0 1 8.63 8.632Z" />
      <path d="M10.346 6.923h-1.2v-1.2a.918.918 0 0 0-.918-.918h-.409a.918.918 0 0 0-.917.917v1.201H5.7a.918.918 0 0 0-.932.916v.408a.918.918 0 0 0 .917.918h1.201v1.2a.918.918 0 0 0 .918.918h.408a.918.918 0 0 0 .918-.917V9.165h1.2a.918.918 0 0 0 .918-.918v-.408a.918.918 0 0 0-.903-.916Z" />
    </g>
    <defs>
      <clipPath id="zoomin-icon_svg__a">
        <path d="M0 0h20v19.998H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
