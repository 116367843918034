import { clomp } from 'clomp';

export const Base = clomp.div`
  border-2
  font-medium
  inline-flex
  items-center
  justify-center
  px-4
  rounded-full
`;
