import React from "react";
import { ISVGProps } from "types";

export const SignIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 20 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#sign-icon_svg__a)" fill="currentColor">
      <path d="M16.258 22.452H1.548V1.548h13.934V12a.774.774 0 0 0 1.548 0V.775A.775.775 0 0 0 16.255 0H.775A.773.773 0 0 0 0 .775v22.452c0 .427.346.773.775.773H16.26a.774.774 0 0 0-.002-1.548Z" />
      <path d="M18.989 15.686a.778.778 0 0 0-.797.751c-.017.627-.497 1.145-.855 1.375 0-.01 0-.019-.002-.026-.026-.509-.062-1.21-.6-1.625-.962-.741-2.088.21-3.178 1.128-.35.31-.72.598-1.104.862.024-.384.07-.768.132-1.147.18-1.224.368-2.49-.34-3.276-1.152-1.275-3.567-.36-8.07 3.057a.774.774 0 1 0 .937 1.234c5.064-3.845 5.976-3.262 5.983-3.252.22.242.05 1.392-.04 2.011-.176 1.183-.342 2.3.446 2.842.199.141.439.218.684.218.758 0 1.576-.691 2.373-1.366.382-.338.785-.65 1.212-.93.01.107.017.23.022.32.026.498.06 1.177.653 1.489.45.238 1.03.175 1.63-.178.803-.47 1.631-1.485 1.667-2.695a.774.774 0 0 0-.753-.792c.002 0 0 0 0 0Z" />
    </g>
    <defs>
      <clipPath id="sign-icon_svg__a">
        <path d="M0 0h19.742v24H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
