/**
 * Enum for select sizes.
 * @readonly
 * @enum {string}
 */
export enum SelectSize {
  xs = '32px',
  sm = '40px',
  md = '48px',
  lg = '56px',
  xl = '64px',
}

/**
 * Enum for select font sizes.
 * @readonly
 * @enum {string}
 */
export enum SelectFontSize {
  xs = '0.75rem',
  sm = '0.875rem',
  md = '1rem',
  lg = '1.125rem',
  xl = '1.25rem',
}
