import React from 'react';
import { Td, ITdProps } from "../components/Td";
import cn from 'classnames';
import { Image } from '@design-system';
import { Link as L } from 'react-router-dom';
import { clomp } from 'clomp';

const Link = clomp(L)`
  flex
  font-medium
  items-center
  text-sm
  text-gray-900
`;

export interface ITdFileProps extends ITdProps {
  /**
   * Redirect path.
   */
  path: string;
}

export const TdFile: React.FC<ITdFileProps> = (props): JSX.Element => {
  const { children, className, path, ...rest } = props;

  return (
    <Td className={cn(className)} {...rest}>
      <Link to={path}>
        <div className="flex-shrink-0 w-10 h-10">
          <Image src="/img/pdficon.svg" alt="pdf" />
        </div>
        <p className="ml-4">{children}</p>
      </Link>
    </Td>
  );
}
