import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { WrapperRoute } from './wrapperRoute';
import AuthPages from '@pages/AuthPages';
import NotFound from '@pages/404';

const routeList = ROUTES.map((route) => (
  <WrapperRoute
    exact
    key={route.key}
    path={route.path}
    component={route.component}
    layout={route.layout}
    isPrivate={route.isPrivate}
    policy={route.policy}
  />
));

/**
 *
 * @param routes
 * @returns
 */
export const renderRoutes = () => (
  <React.Suspense fallback={<div>cargando..</div>}>
    <Switch>
      {routeList}
      <AuthPages />
      <Route element={NotFound} />
    </Switch>
  </React.Suspense>
);
