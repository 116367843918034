import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div>
      404 NOT FOUND
    </div>
  );
};

export default NotFound;
