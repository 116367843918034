import { clomp } from 'clomp';

export const Base = clomp.button`
  delay-250
  font-medium
  inline-flex
  items-center
  justify-center
  relative
  rounded-full
  transition-all
`;
