import { translations } from 'translations';
import * as Yup from 'yup';

export const NewFormTemplateValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(translations.ERRORS.FORM_NAME_REQUIRED)
    .max(50, translations.ERRORS.FORM_NAME_MAX_50),
  description: Yup.string()
    .max(255, translations.ERRORS.MAX_LENGTH_255)
    .required(translations.ERRORS.REQUIRED),
  helperText: Yup.string()
    .max(255, translations.ERRORS.MAX_LENGTH_255)
    .required(translations.ERRORS.REQUIRED),
});
