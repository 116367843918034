import { IBaseAuthentication } from '@cincel/client';
import { translations } from 'translations';
import * as Yup from 'yup';

export type IResetPasswordFormFields = Pick<IBaseAuthentication, 'email'>;

export const ResetPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(translations.ERRORS.VALID_EMAIL)
    .required(translations.ERRORS.REQUIRED_EMAIL)
    .min(6, translations.ERRORS.VALID_EMAIL_CHARACTERS),
});

export const DEFAULT_VALUES = {
  email: '',
};
