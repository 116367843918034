import React from "react";
import { ISVGProps } from "types";

export const InfoIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 16 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="currentColor" height={16} rx={8} width={16} />
    <g clipPath="url(#info-icon_svg__a)">
      <path
        d="m9.67 12.015-.132.541c-.398.157-.715.277-.951.359a2.508 2.508 0 0 1-.825.123c-.482 0-.856-.118-1.123-.352a1.139 1.139 0 0 1-.4-.894c0-.14.01-.284.03-.43.02-.147.052-.312.096-.497l.497-1.758a8.25 8.25 0 0 0 .112-.479c.03-.15.045-.288.045-.412 0-.224-.046-.381-.139-.47-.092-.088-.269-.132-.531-.132-.129 0-.26.02-.395.06a5.51 5.51 0 0 0-.348.114l.133-.542c.326-.132.637-.246.934-.34.298-.094.579-.141.845-.141.477 0 .846.115 1.105.346.26.231.388.53.388.9 0 .076-.008.211-.026.403-.018.193-.051.37-.1.53l-.494 1.752c-.04.14-.077.301-.109.482-.033.18-.048.317-.048.41 0 .232.051.39.155.475.105.084.285.126.541.126.12 0 .258-.021.41-.063.151-.042.262-.079.33-.111Zm.126-7.351c0 .305-.115.566-.346.78-.23.215-.508.322-.833.322-.326 0-.605-.107-.838-.322a1.022 1.022 0 0 1-.349-.78c0-.305.117-.566.35-.782.232-.217.51-.325.837-.325.324 0 .602.108.833.325.23.216.346.477.346.782Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="info-icon_svg__a">
        <rect
          fill="#fff"
          height={9.481}
          rx={4.741}
          width={9.481}
          x={2.96}
          y={3.557}
        />
      </clipPath>
    </defs>
  </svg>
);
