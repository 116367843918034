/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useRouter } from 'hooks/useRouter';
import cn from 'classnames';
export const SidebarItem = ({
  borderColor,
  link = '#',
  id,
  label,
  dropdown = false,
  items = [],
  isOpen = false,
  isExternal = false,
  isActive = false,
}) => {
  const router = useRouter();
  const [active, setActive] = useState(isOpen);

  const navigate = (link) => {
    if (isExternal) {
      return window.open(link, '_blank');
    }
    router.history.push(link);
  };

  return (
    <>
      {!dropdown && (
        <a
          onClick={() => navigate(link)}
          className={cn(
            'mt-2 flex cursor-pointer items-center border-l-4 bg-gray-600 bg-opacity-25 px-6 py-2 text-gray-100 duration-200',
            isActive
              ? 'bg-gray-300 bg-opacity-25 text-gray-100'
              : 'border-gray-600 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100',
          )}
          style={isActive ? { borderColor } : {}}
        >
          <div className="flex gap-4">
            <img
              className="h-[25px] w-[25px] text-white"
              src={`/img/menu-${id}.svg`}
              alt={`menu-${id}.svg`}
              width="25"
              height="25"
            />

            <p className="inline-block w-full flex-1 text-basic-white">
              {label}
            </p>
          </div>
        </a>
      )}
      {dropdown && (
        <div>
          <div
            className={cn(
              'mt-2 flex cursor-pointer items-center gap-4 border-l-4 bg-gray-600 bg-opacity-25 px-6 py-2 text-gray-100 duration-200',
              isActive
                ? 'bg-gray-300 bg-opacity-25 text-gray-100'
                : 'border-gray-600 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100',
            )}
            style={isActive ? { borderColor } : {}}
          >
            <img
              className="h-[25px] w-[25px] text-white"
              src={`/img/menu-${id}.svg`}
              alt={`menu-${id}.svg`}
              width="25"
              height="25"
            />
            <p
              className="inline-block w-full flex-1 text-basic-white"
              onClick={() => navigate(link)}
            >
              {label}
            </p>
            {dropdown && (
              <div onClick={() => setActive((prev) => !prev)}>
                <i
                  className={cn(
                    'text-basic-white',
                    active ? 'fas fa-chevron-up' : 'fas fa-chevron-down',
                  )}
                ></i>
              </div>
            )}
          </div>
          {active && (
            <div className="w-full pl-7">
              <ul className="flex list-none flex-col md:min-w-full md:flex-col">
                {items.map((item: any, i) => {
                  let isItemActive = false;
                  const { active } = item;
                  const { query } = router;
                  // const active = item.link.includes(router.pathname);
                  if (
                    typeof active === 'undefined' &&
                    Object.keys(query).length === 1
                  )
                    isItemActive = true;

                  if (typeof active !== 'undefined') {
                    const keys = active.split('.');

                    if (query[keys[0]] === keys[1]) isItemActive = true;
                  }

                  return (
                    <li
                      key={i}
                      className={cn(
                        'nop',
                        'mt-2 flex cursor-pointer items-center border-l-4 bg-gray-600 bg-opacity-25 text-gray-100 duration-200',
                        isItemActive
                          ? 'bg-gray-300 bg-opacity-25 text-gray-100'
                          : 'border-gray-600 text-gray-100 hover:bg-gray-300 hover:bg-opacity-25 hover:text-gray-100',
                      )}
                      style={isItemActive ? { borderColor } : {}}
                    >
                      <a
                        href={item.link}
                        target={item.link.includes('http') ? '_blank' : '_self'}
                        rel="noreferrer"
                        className="inline-flex w-full px-6 py-2"
                      >
                        <span className="ml-6 mt-1 inline-block text-paragraph-sm text-basic-white">
                          {item.label}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};
