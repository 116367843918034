import fp from 'lodash/fp';
import { Dispatch, useReducer } from 'react';
import {
  BASE_FAILURE,
  BASE_INIT,
  BASE_SUCCESS,
  IActionEnum,
  IMutationFn,
  INITIAL_STATE,
  IUseWLReducerState,
  getWLMetadata,
  getSettingsMetadata
} from '../helpers';

export const useWLReducer = (): [IUseWLReducerState, Dispatch<any>] => {
  const [state, dispatch] = useReducer<IMutationFn>((ps, a) => {
    switch (a?.type) {
      case IActionEnum.FETCH_ASSET:
        return { ...ps, ...BASE_INIT };
      case IActionEnum.FETCH_ASSET_FAILURE:
        return { ...ps, ...BASE_FAILURE, ...fp.pick([a.fallback])(INITIAL_STATE) };
      case IActionEnum.FETCH_ASSET_SUCCESS:
        return { ...ps, ...BASE_SUCCESS, ...getWLMetadata(a) };

      case IActionEnum.UPDATE_WL:
        return { ...ps, ...BASE_INIT };
      case IActionEnum.UPDATE_WL_FAILURE:
        return { ...ps, ...BASE_FAILURE };
      case IActionEnum.UPDATE_WL_SUCCESS:
        return { ...ps, ...BASE_SUCCESS, ...getWLMetadata(a) };

      case IActionEnum.FETCH_SETTINGS:
        return { ...ps, ...BASE_INIT };
      case IActionEnum.FETCH_SETTINGS_FAILURE:
        return { ...ps, ...BASE_FAILURE, ...fp.pick([a.fallback])(INITIAL_STATE) };
      case IActionEnum.FETCH_SETTINGS_SUCCESS:
        return { ...ps, ...BASE_SUCCESS, ...getSettingsMetadata(a) };

      case IActionEnum.FETCH_SETTINGS_FINISH:
        return { ...ps, isFetching: false };
      case IActionEnum.FETCH_SETTINGS_INIT:
        return { ...ps, isFetching: true };

      case IActionEnum.FETCH_WL_FINISH:
        return { ...ps, isFetching: false };
      case IActionEnum.FETCH_WL_INIT:
        return { ...ps, isFetching: true };

      default:
        throw new Error(`Unknow action type: ${a?.type}`);
    }
  }, INITIAL_STATE as never);

  return [state, dispatch];
};
