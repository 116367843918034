/**
 * Enum for input size.
 * @readonly
 * @enum {string}
 */
export enum InputSize {
  xs = 'h-[32px] text-xs',
  sm = 'h-[40px] text-sm',
  md = 'h-[48px] text-md',
  lg = 'h-[56px] text-lg',
  xl = 'h-[64px] text-xl',
}

/**
 * Enum for label font size.
 * @readonly
 * @enum {string}
 */
export enum InputFontSize {
  xs = 'text-xs',
  sm = 'text-sm',
  md = 'text-md',
  lg = 'text-lg',
  xl = 'text-xl',
}

/**
 * Calculates the input styles that will be consumed by `classnames` based on
 * the `isDisabled`, `isRequired` and `isRounded` properties.
 * @see {@link https://www.npmjs.com/package/classnames}.
 * @param {boolean} isDisabled - if `true`, the input will be have disable
 * styles.
 * @param {boolean} isRequired - if `true`, the input will have the custom
 * `textfield__input` class.
 * @param {boolean} isRounded - if `true`, the input will have rounded borders.
 * @returns {{ [i: string]: boolean }} - classnames object.
 */
export const getInputPropsCSS = (
  isDisabled: boolean,
  isRequired: boolean,
  isRounded: boolean,
): { [i: string]: boolean } => ({
  'bg-gray-200 cursor-not-allowed opacity-70': isDisabled,
  'textfield__input': isRequired,
  'rounded-full': isRounded,
});


/**
 * Calculates the label styles that will be consumed by `classnames` based on
 * the `isRequired` property.
 * @see {@link https://www.npmjs.com/package/classnames}.
 * @param {boolean} isRequired - if `true`, the label will have the required
 * styles.
 * @returns {{ [i: string]: boolean }} - classnames object.
 */
export const getLabelPropsCSS = (
  isRequired: boolean
): { [i: string]: boolean } => ({
  'textfield__label--required left-[18px] top-1/2 -translate-y-1/2': isRequired,
});

/**
 * Calculates the input dynamic styles based on the `color` parameter.
 * @param {string} color - hex., theme color.
 * @param {boolean} isDisabled - if `true`, will avoid to change the border
 * color on hover.
 * @returns {{ [i: string]: any }} - primary variant styles.
 */
export const getDynamicCSS = (
  color: string,
  isDisabled: boolean
): { [i: string]: any } => ({
  transition: 'all ease .2s',

  '&:focus': {
    borderColor: color,
  },

  '&:hover': {
    borderColor: !isDisabled ? color : 'inherit',
  },
});
