import { useClient } from 'hooks/useClient';
import { CINCELClient } from '@cincel/client';
import { tokenV3Name } from 'config/constants';
import { useQuery } from 'react-query';

const fetchTeamsService = async (clientV3: CINCELClient, user) => {
  return clientV3.getUserTeams(user);
};

export const useTeams = ({ id, enabled }) => {
  const clientV3 = useClient({
    url: process.env.REACT_APP_API_V3,
    token: localStorage.getItem(tokenV3Name) as string,
  });

  const {
    data: userTeams,
    status: userTeamsStatus,
    isLoading: isUserTeamsLoading,
    refetch: refetchUserTeams,
    isError: isUserTeamsError,
    error: userTeamsError,
    ...rest
  } = useQuery(['userTeams', id], () => fetchTeamsService(clientV3, id), {
    enabled,
  });

  return {
    userTeams,
    userTeamsStatus,
    isUserTeamsLoading,
    refetchUserTeams,
    isUserTeamsError,
    userTeamsError,
    ...rest,
  };
};
