import { UseToastOptions } from '@chakra-ui/react';

/**
 * Base toast default params.
 * @type {UseToastOptions}
 */
export const BASE_TOAST: UseToastOptions = {
  isClosable: true,
  position: 'top-right',
}

/**
 * Error toast default params.
 * @type {UseToastOptions}
 */
export const ERROR_TOAST_DEFAULTS: UseToastOptions = {
  status: 'error',
  ...BASE_TOAST,
}

/**
 * Success toast default params.
 * @type {UseToastOptions}
 */
export const SUCCESS_TOAST_DEFAULTS: UseToastOptions = {
  status: 'success',
  ...BASE_TOAST,
}

/**
 * Warning toast default params.
 * @type {UseToastOptions}
 */
export const WARNING_TOAST_DEFAULTS: UseToastOptions = {
  status: 'warning',
  ...BASE_TOAST,
}
