import { translations } from 'translations';

export const SELECT_OPTIONS = (t) => [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: t(translations.COMMON.ALL),
    value: 1000,
  },
];
