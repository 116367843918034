import React from 'react';
import { ISVGProps } from 'types';

export const ChevronDownIcon: React.FC<ISVGProps> = (props) => (
  <svg
    height="1em"
    viewBox="0 0 12 6.5"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    {...props}>
    <path d="M6 6.5 0 0h12z" fill="currentColor" />
  </svg>
);
