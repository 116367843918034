import React from 'react';
import { Tooltip, TooltipProps } from '@chakra-ui/react';
import cn from 'classnames';

import { Icon } from './components/Icon';

export interface ISimpleTooltipProps
  extends Omit<TooltipProps, 'css' | 'children'> {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Tooltip icon path.
   */
  path?: string;
}

export const SimpleTooltip: React.FC<ISimpleTooltipProps> = (
  props,
): JSX.Element => {
  const { className, label, path = '/img/info.svg', ...rest } = props;

  return (
    <div className={cn(className)}>
      <Tooltip label={label} {...rest}>
        <Icon alt="tooltip" src={path} />
      </Tooltip>
    </div>
  );
};

SimpleTooltip.defaultProps = {
  bg: '#E9E9E9',
  color: '#444',
  fontSize: 'sm',
  hasArrow: true,
  placement: 'top',
  px: 4,
  py: 3,
  rounded: '2xl',
};
