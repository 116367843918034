import React from 'react';
import cn from 'classnames';
import { Center, Flex } from '@design-system';
import { css } from '../helpers';
import fp from 'lodash/fp';

export interface ICustomInputProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * On click event that will trigger the color picker pop-up.
   */
  onClick: () => void;
  /**
   * Current input value.
   */
  value: string;
}

export const CustomInput: React.FC<ICustomInputProps> = (props): JSX.Element => {
  const { className, onClick, value } = props;

  return (
    <Flex className={cn(className)}>
      <Center className={cn("cursor-pointer w-[50px]", fp.values(css))} onClick={onClick}>
        <div className={cn("h-[30px] w-[30px]", css.rounded)} style={{ background: value }} />
      </Center>
      <Flex className={cn("flex-1 items-center ml-3 px-5 py-4", fp.values(css))}>
        {value}
      </Flex>
    </Flex>
  )
}
