import { clomp } from 'clomp';

export const Footer = clomp.footer`
  font-light
  pt-8
  text-center
  text-gray-400
  text-sm

  md:pt-10
`;
