/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import fp from 'lodash/fp';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Control, useController } from 'react-hook-form';

import {
  Flex,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelFontSize,
  IStackProps,
  Stack,
} from '@design-system';
import { DndContainer } from './components/DndContainer';
import { ImgContainer } from './components/ImgContainer';
import { createImageURL, getValue, isURL, isValueNil } from './helpers';
import { useTranslation } from 'react-i18next';

export interface IFileFieldProps extends IStackProps {
  /**
   * Comma-separated list of one or more file types.
   */
  accept?: string;
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * RHF `control` object provided by invoking useForm. This prop is optional
   * when using FormProvider.
   */
  control?: Control<any, any>;
  /**
   * Input helper text that will be displayed below the input.
   */
  helper?: string;
  /**
   * If `true`, the preview image will have a dark background.
   */
  isDarkBg?: boolean;
  /**
   * If `true`, the button and labels will be on a row direction if and only if
   * the input doesn't have a value, otherwise in order to not break the layout
   * in mobile and tablet devices it will take a column direction.
   */
  isInline?: boolean;
  /**
   * If `true`, the preview image will have the tw class `rounded-full`.
   */
  isRounded?: boolean;
  /**
   * Input label, can be a raw text or an i18n key.
   */
  label: string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Label font size, can be one of the following keys:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  labelFontSize: keyof typeof FormLabelFontSize;
  /**
   * Input name that RHF will use to identify the input on the HTML code.
   */
  name: string;
  /**
   * White label's primary color.
   */
  primaryColor: string;
  /**
   * Additional text that will be rendered inside the dnd container.
   */
  quote?: string;
  /**
   * if `true`, it will show a helper icon inside the dnd container.
   */
  withBrowseIcon?: boolean;
  /**
   * If `true`, it will show the preview of the selected file.
   */
  withPreview?: boolean;
}

export const FileField: React.FC<IFileFieldProps> = (props): JSX.Element => {
  const {
    accept = '.jpg, .png',
    control,
    helper,
    isDarkBg = false,
    isInline = false,
    isRounded = false,
    label,
    labelClassName,
    labelFontSize = 'sm',
    name,
    primaryColor,
    quote,
    withBrowseIcon = false,
    withPreview = false,
    ...rest
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  const { field, fieldState } = useController({ control, name });

  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const file = fp.compose(fp.head, fp.get('target.files'))(e);
      const reader = new FileReader();
      reader.onloadend = () => field.onChange(file);
      reader.readAsDataURL(file);
    },
    [ref?.current],
  );

  const handleOnClick = useCallback(() => {
    fp.invoke('current.click')(ref);
  }, [ref?.current]);

  return (
    <Stack {...rest}>
      <FormLabel
        className={cn(FormLabelFontSize[labelFontSize], labelClassName)}
        htmlFor={name}>
        {label}
      </FormLabel>

      <Flex className="flex-1 items-start">
        {!isValueNil(field) && withPreview ? (
          <ImgContainer
            className="max-h-40 w-40"
            isDarkBg={isDarkBg}
            isRounded={isRounded}>
            <img
              alt="preview"
              className={cn('w-full object-contain')}
              src={isURL(field) ? getValue(field) : createImageURL(field)}
            />
          </ImgContainer>
        ) : null}

        <DndContainer
          isInline={isInline && isValueNil(field)}
          onChange={field.onChange}
          onClick={handleOnClick}
          primaryColor={primaryColor}
          quote={quote}
          value={field.value}
          withBrowseIcon={withBrowseIcon}
        />
      </Flex>

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {t(fieldState?.error?.message as string)}
      </FormErrorMessage>

      <input
        ref={ref}
        accept={accept}
        className="hidden"
        onChange={handleOnChange}
        type="file"
      />
    </Stack>
  );
};
