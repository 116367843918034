import React from 'react';
import { ISVGProps } from 'types';

export const TrashIcon: React.FC<ISVGProps> = (props) => (
  <svg
    width="15"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#a)" fill="#A5A5A5">
      <path d="M14.77 2.462h-1.832l-.6-.03v.03h-2.49a2.462 2.462 0 1 0-4.925 0h-2.49v-.03l-.601.03H0v1.23h1.261l.586 11.717a.613.613 0 0 0 .615.584h9.846a.614.614 0 0 0 .615-.584l.585-11.717h1.262v-1.23ZM7.385 1.23a1.232 1.232 0 0 1 1.23 1.232h-2.46a1.232 1.232 0 0 1 1.23-1.232Zm4.337 13.54H3.048L2.493 3.692h9.785l-.556 11.078Z" />
      <path d="M6.153 6.152h-1.23v6.153h1.23V6.152ZM9.845 6.152h-1.23v6.153h1.23V6.152Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14.768v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
