import React from "react";
import { ISVGProps } from "types";

export const ExpandIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 1.25V0H.625A.625.625 0 0 0 0 .625V5h1.25V2.13l5.806 5.806.881-.88L2.131 1.25H5ZM19.375 0H15v1.25h2.869l-5.806 5.806.88.881 5.807-5.806V5H20V.625A.625.625 0 0 0 19.375 0ZM18.75 17.869l-5.806-5.806-.882.88 5.807 5.807h-2.87V20h4.376a.625.625 0 0 0 .625-.625V15h-1.25v2.869ZM7.056 12.057 1.25 17.869V15H0v4.375A.625.625 0 0 0 .625 20H5v-1.25H2.13l5.806-5.806-.88-.887Z"
      fill="currentColor"
    />
  </svg>
);
