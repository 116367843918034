import React, { useState } from 'react';
import { Switch as ChakraSwitch } from '@chakra-ui/react';

export const Switch = ({ isEnabled = false, onChange, className = "" }) => {
  const [enabled, setEnabled] = useState(isEnabled);

  const handleChange = (value) => {
    if (onChange) onChange(value);
    setEnabled(value);
  };

  return (
    <ChakraSwitch className={className} size='md' onChange={handleChange} />
  );
};
