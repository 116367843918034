import { clomp } from 'clomp';
import { Heading as H1 } from '@design-system';

export const Heading = clomp(H1)`
  mb-2
  text-lg

  lg:mb-4
  lg:text-4xl
`;

export const Paragraph = clomp.p`
  line-clamp-3
  mb-4
  overflow-hidden
  text-sm

  lg:text-md
`;
