import React from 'react';
import { ISVGProps } from 'types.d';

export const BrowseFileIcon: React.FC<ISVGProps> = (props) => (
  <svg
    fill="none"
    height="1em"
    viewBox="0 0 70 60"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.685 49.231h-2.454v3.425h2.454V49.23ZM36.231 57.307h-1.227v2.446h3.681V56.08h-2.454v1.227ZM30.536 57.307h-4.477v2.454h4.477v-2.454ZM12.635 57.307H8.158v2.454h4.477v-2.454ZM21.582 57.307h-4.477v2.454h4.477v-2.454ZM2.454 56.08H0v3.673h3.681v-2.446H2.454V56.08ZM2.454 29.226H0v4.477h2.454v-4.477ZM2.454 38.17H0v4.478h2.454v-4.477ZM2.454 47.125H0v4.477h2.454v-4.477ZM0 24.748h2.454v-1.227h1.227v-2.454H0v3.681ZM11.972 21.067H7.826v2.454h4.146v-2.454Z"
      fill="currentColor"
    />
    <path
      d="m65.125 43.063 4.046-3.889c.688-.654.97-1.641.746-2.561v-.009a2.708 2.708 0 0 0-1.832-1.931l-13.464-4.03V10.811c0-.323-.133-.638-.365-.862L44.688.365A1.204 1.204 0 0 0 43.826 0H18.564c-.68 0-1.228.547-1.228 1.227v19.84H16.11v2.454h1.227v21.067h18.895v1.227h2.454v-1.227h7.139l2.902 8.913a2.69 2.69 0 0 0 2.545 1.84c.688 0 1.343-.265 1.849-.745l4.129-3.963 3.623 3.623a2.682 2.682 0 0 0 3.789 0l3.921-3.922a2.66 2.66 0 0 0 .788-1.89c0-.713-.29-1.41-.788-1.899l-3.457-3.482ZM44.88 4.02l5.72 5.73h-5.72V4.02ZM19.79 42.134V2.454h22.642v8.523c0 .68.547 1.227 1.227 1.227h8.523v17.718L44.746 27.7a2.651 2.651 0 0 0-2.04.215c-.63.34-1.086.904-1.293 1.592a2.608 2.608 0 0 0 .025 1.592l3.598 11.044H19.791v-.009Zm42.971 9.9-5.472-5.472-5.92 5.687-2.495-7.66-.796-2.455-3.756-11.54 7.852 2.346 2.454.738 12.262 3.664-5.903 5.671 5.389 5.398-3.615 3.623Z"
      fill="currentColor"
    />
    <path
      d="M47.474 16.184c0 .506-.406.92-.92.92H24.93a.924.924 0 0 1-.92-.92c0-.506.414-.912.92-.92h21.623c.514 0 .92.414.92.92ZM47.474 20.644c0 .506-.406.92-.92.92H24.93a.918.918 0 0 1-.92-.92c0-.506.406-.92.92-.92h21.631a.906.906 0 0 1 .912.92c0-.008 0-.008 0 0ZM47.474 25.096c0 .506-.406.92-.92.92H24.93a.924.924 0 0 1-.92-.92c0-.506.414-.912.92-.92h21.623c.514 0 .92.414.92.92Z"
      fill="currentColor"
    />
  </svg>
);
